import React, { createContext, FC, useEffect, useState } from 'react'
import { User, AuthContextProvider } from '../../types'
import { useLocation, useHistory } from 'react-router-dom'
import { Routes } from '../../constants'
import { loginUserBySessionCookie } from '../../api'

export const AuthContext = createContext<AuthContextProvider>({} as AuthContextProvider)

type Props = {
  children: React.ReactNode
}

export const INITIAL_AUTH_STATE = {
  id: '',
  firmId: '',
  email: '',
  displayName: undefined,
  profileImage: undefined,
}

export const AuthProvider: FC<Props> = (props) => {
  const { children } = props
  const location = useLocation()
  const history = useHistory()
  const publicRoutes: string[] = [Routes.Login, Routes.SignUp, Routes.ForgotPassword]
  const [user, setUser] = useState<User>(INITIAL_AUTH_STATE)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const removeUser = () => {
    setUser(INITIAL_AUTH_STATE)
  }

  useEffect(() => {
    const refreshUserSession = async () => {
      try {
        const user = await loginUserBySessionCookie()
        if (user) {
          await setUser({
            id: user.id,
            firmId: user.firm_id,
            displayName: user.display_name ?? undefined,
            profileImage: user.profile_image ?? undefined,
            email: user.email,
          })
          await setIsAuthenticated(user.id !== '')
        }
      } catch (e) {
        console.error(e.message)
      } finally {
        setIsLoading(false)
      }
    }

    if ((!user.id || !user.firmId) && !publicRoutes.includes(location.pathname)) {
      refreshUserSession()
    } else if (isLoading) {
      // set loading to false only if we don't need to refresh user info (on login page basically)
      setIsLoading(false)
    }
    // should only fire the first time briefcase loads
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        removeUser,
        isAuthenticated,
        setIsAuthenticated,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
