import React from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Button, IconButton, Paper } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { useAlert } from '../../../../utils'
import { removeSuggestedLineId } from '../../../../api'

const styles = () =>
  createStyles({
    root: {
      width: 350,
      marginRight: 8,
      padding: '0px 16px',
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 16,
      marginBottom: 10,
    },

    title: {
      display: 'flex',
      fontSize: 16,
    },

    // hacky way to get text centered perfectly and close button
    // all the way to the right, feel free to improve later
    invisibleFlexItem: {
      // width of close button
      width: 24,
    },

    closeButton: {
      width: 24,
    },

    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 10,
    },

    titleTagText: {
      fontStyle: 'italic',
      padding: '4px 8px',
      background: '#4F4F4F',
      color: 'white',
      borderRadius: 10,
    },

    button: {
      height: 32,
      width: 150,
      color: 'white',
    },

    addTagButton: {
      background: '#2C3F67',
    },

    badTagButton: {
      background: '#50718F',
    },
  })

type Props = WithStyles<typeof styles> & {
  setShouldShowSuggestionDialog: React.Dispatch<React.SetStateAction<boolean>>
  suggestionTag: string | undefined
  addTagForCurrentRow: (tagLabel: string) => void
  lineId: string
  documentId: string | undefined
}

const SuggestionDialog = ({
  classes,
  setShouldShowSuggestionDialog,
  suggestionTag,
  addTagForCurrentRow,
  lineId,
  documentId,
}: Props) => {
  const { addAlert } = useAlert()!

  const handleSuggestionRemoval = () => {
    if (documentId && suggestionTag) {
      removeSuggestedLineId(suggestionTag, documentId, lineId, addAlert)
    }
  }

  const handleAddTagClick = () => {
    if (suggestionTag) {
      handleSuggestionRemoval()
      addTagForCurrentRow(suggestionTag)
    }
  }

  const handleBadSuggestionClick = () => {
    handleSuggestionRemoval()
  }

  return (
    <Paper className={classes.root} elevation={2}>
      <div className={classes.header}>
        <div className={classes.invisibleFlexItem} />
        <div className={classes.title}>
          <span>
            <b>Tag Suggestion: </b>
            <b className={classes.titleTagText}>{suggestionTag}</b>
          </span>
        </div>

        <IconButton
          className={classes.closeButton}
          onClick={() => setShouldShowSuggestionDialog(false)}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          className={clsx(classes.button, classes.addTagButton)}
          onClick={handleAddTagClick}
        >
          Add Tag
        </Button>
        <Button
          variant="contained"
          className={clsx(classes.button, classes.badTagButton)}
          onClick={handleBadSuggestionClick}
        >
          Bad Suggestion
        </Button>
      </div>
    </Paper>
  )
}

export default withStyles(styles)(SuggestionDialog)
