import { CaseData, CreateNewCaseForm, Filters, GetParams, HttpResponse } from '../types'
import { fetcher, get, post, put } from './httpHelpers'
import useSWR from 'swr'
import { httpDelete } from './httpHelpers'
import { SuccessResponse, Tag, TagData } from '../types'

// this is gonna be annoying asf to implement so saving for later
// const getKeyForCases = (
//   offset: number,
//   previousPageData: HttpResponse<CaseData[]> | null,
//   params: GetParams & { chronologically?: boolean },
//   query?: string
// ) => {
//   let path = `cases/list/user/${userId}`
//
//   if (query) {
//     path += `/${query}`
//   }
//   if (!previousPageData?.length) return null // reached the end
//   return appendParams(path, params).toString()
// }
//
// export const useCases = (
//   userId: string,
//   params: GetParams & { chronologically?: boolean },
//   query?: string
// ) => {
//   const { data, error } = useSWRInfinite<HttpResponse<CaseData[]>, Error>(
//     (offset: number, previousPageData) => getKeyForCases(offset, previousPageData, params, qu),
//     fetcher
//   )
//
//   return { cases: data?.parsedBody?.['cases'], isLoading: !data && !error, error }
// }

export const getCasesByUserId = async (
  userId: string,
  params: GetParams & { chronologically?: boolean }, // court_name?: string;
  query?: string
) => {
  let path = `cases/list/user/${userId}`

  if (query) {
    path += `/${query}`
  }

  return get<CaseData[]>(path, params).then((response) => response.parsedBody!['cases'])
}

export const getFilteredCasesByUserId = async (
  userId: string,
  params: GetParams & { chronologically?: boolean },
  filters?: Filters
) => {
  let path = `cases/list/user/${userId}`

  if (filters) {
    filters.query ? (path += `/query/${filters.query}`) : (path += `/query/%BLANK%`)
    filters.docket_number_start
      ? (path += `/docket_number_start/${filters.docket_number_start}`)
      : (path += `/docket_number_start/%BLANK%`)
    filters.docket_number_end
      ? (path += `/docket_number_end/${filters.docket_number_end}`)
      : (path += `/docket_number_end/%BLANK%`)
    filters.court_name
      ? (path += `/court_name/${filters.court_name}`)
      : (path += `/court_name/%BLANK%`)

    if (filters.labels) {
      path += `/labels/`
      for (var label of filters.labels) {
        path += `${label}^^` // the ^^ separates the labels
      }
    } else {
      path += `/labels/%BLANK%`
    }

    filters.date_filed_start
      ? (path += `/date_filed_start/${filters.date_filed_start}`)
      : (path += `/date_filed_start/%BLANK%`)
    filters.date_filed_end
      ? (path += `/date_filed_end/${filters.date_filed_end}`)
      : (path += `/date_filed_end/%BLANK%`)
    filters.last_opened_start
      ? (path += `/last_opened_start/${filters.last_opened_start}`)
      : (path += `/last_opened_start/%BLANK%`)
    filters.last_opened_end
      ? (path += `/last_opened_end/${filters.last_opened_end}`)
      : (path += `/last_opened_end/%BLANK%`)
  }

  return get<CaseData[]>(path, params).then((response) => response.parsedBody!['cases'])
}

export const updateCaseWithCurrentDate = async (caseId: string) => {
  const updates = {
    updates: {
      // Returns a date formatted in YYYY-MM-DD HH:MM:SS
      // https://stackoverflow.com/a/19079030
      last_opened: new Date().toJSON().slice(0, 24),
    },
  }

  return put<CaseData>(`cases/case/${caseId}`, updates).then(
    (response) => response.parsedBody!['case']
  )
}

export const postNewCase = async (
  form: Omit<CreateNewCaseForm, 'dialogue_start_page'>,
  firmId: number
) =>
  post<CaseData>('cases/case/create', { ...form, firm_id: firmId }).then(
    (response) => response.parsedBody!['case']
  )

export const useCase = (caseId: string) => {
  const { data, error } = useSWR<HttpResponse<CaseData>, Error>(`cases/case/${caseId}`, fetcher)

  return { case: data?.parsedBody?.['case'], isLoading: !error && !data, error }
}

// export const deleteCaseByCaseId = (caseId: CaseData['id']) =>
//   httpDelete<SuccessResponse>(`cases/case/${caseId}`).then((response) => response)
