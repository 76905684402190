//import React from 'react'
import React, { useState } from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { Button, Paper, IconButton } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'

import Comment from './Comment'
import { FirebaseThread } from '../../../../types'
import { CommentThreadTestIds } from '../../../../testing/test-ids/view-comments'
import { useCaseId, useScrollTo } from '../../../../utils'
import { deleteCommentThread } from '../../../../services/firebase/dal'
import RoundedInputField from '../../../../components/RoundedInputField'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1.5),
      margin: '0 20px 15px 20px',
    },

    buttonRoot: {
      padding: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: '#53B89E',
    },

    // Buttons at the top
    buttonArea: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(1),
    },

    goToButton: {
      backgroundColor: '#53B89E',
      marginRight: theme.spacing(1),
    },

    resolveButton: {
      backgroundColor: '#50718F',
    },

    // Messages and replies
    commentList: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },

    commentBlock: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      backgroundColor: '#F2F2F2',
      padding: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },

    reply: {
      display: 'flex',
      flex: 1,
    },

    inputContainer: {
      display: 'flex',
      flex: 1,
      marginTop: theme.spacing(1),
    },

    textField: {
      display: 'flex',
      flex: 1,
      marginRight: theme.spacing(1),
    },

    submitButton: {
      display: 'flex',
      height: 30,
      width: 30,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.grey[500],
      '&:hover': {
        backgroundColor: theme.palette.grey[400],
        color: '#FFF',
      },
    },
  })

type Props = WithStyles<typeof styles> & {
  commentThread: FirebaseThread
}

const CommentThread: React.FC<Props> = ({ classes, commentThread }: Props) => {
  const caseId = useCaseId()
  const [message, setMessage] = useState('')
  const { scrollToRow } = useScrollTo()

  const handleGoToCommentClick = () => {
    scrollToRow(commentThread.lineId)
  }

  const handleResolveComment = () => {
    deleteCommentThread(commentThread.commentDbKey, caseId)
  }

  const handleReplyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setMessage(event.currentTarget.value)
  }

  return (
    <Paper className={classes.root} elevation={3} data-testid={CommentThreadTestIds.root}>
      <div className={classes.buttonArea}>
        <Button
          className={classes.goToButton}
          classes={{
            root: classes.buttonRoot,
          }}
          onClick={handleGoToCommentClick}
          variant="contained"
          color="primary"
          disableElevation
          data-testid={CommentThreadTestIds.goToCommentButton}
        >
          Go to comment
        </Button>
        <Button
          className={classes.resolveButton}
          classes={{
            root: classes.buttonRoot,
          }}
          onClick={handleResolveComment}
          variant="contained"
          color="primary"
          disableElevation
        >
          Resolve
        </Button>
      </div>
      <div className={classes.commentList}>
        {commentThread && (
          <Comment
            name={commentThread.email}
            date={commentThread.dateTimeCreated!}
            message={commentThread.message}
          />
        )}
        <div style={{ display: 'flex', justifyContent: 'right', marginTop: '16px' }}></div>
        <div className={classes.inputContainer}>
          <RoundedInputField
            handleInputChange={handleReplyChange}
            placeholder="Type reply here"
            inputValue={message}
          />
          <IconButton className={classes.submitButton} type="submit">
            <SendIcon />
          </IconButton>
        </div>
      </div>
    </Paper>
  )
}

export default withStyles(styles)(CommentThread)
