import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const WhiteTextTypography = withStyles({
  root: {
    color: '#FFFFFF',
  },
})(Typography)

export default WhiteTextTypography
