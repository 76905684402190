import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'

import { AppBar } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import {
  ExhibitData,
  IdToCommentDict,
  WorkspaceTab,
  SuggestionsByLineId,
  MinimapData,
} from '../../types'
import { SITE_TITLE_POSTFIX, TabPanelType } from '../../constants'

import Document from './document/Document'
import ToolBar from './toolbar/ToolBar'
import Header from './Header'
import Sidebar from './sidebar-components/Sidebar'
import TabPanel from './TabPanel'
import ExhibitProfile from './exhibits/ExhibitProfile'
import CharacterProfile from './characters/CharacterProfile'

import {
  listenForDeletedCommentThreads,
  listenForNewCommentThreads,
} from '../../services/firebase/dal'
import { updateCaseWithCurrentDate, useDocument } from '../../api'
import { firebase } from '../../services/firebase'
import { useAlert } from '../../utils'
import { WorkspaceTestIds } from '../../testing/test-ids/workspace'
import { ScrollToProvider } from '../../utils/context-providers/ScrollToRowProvider'
import { VirtuosoHandle } from 'react-virtuoso/dist/components'
import TabPanelContent from './TabPanelContent'
import { CaseIdProvider } from '../../utils/context-providers'

const documentInitialTabValue = 0

// TODO current ~average of row heights, some work could probably be done here to make this more
// accurate, but is good enough for now
const ROW_HEIGHT = 35

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },

    displayArea: {
      display: 'flex',
      background: '#dfe7ed',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
    },
  })

type Props = WithStyles<typeof styles>

const Workspace = ({ classes }: Props) => {
  const { addAlert } = useAlert()!

  // state shared between document and sidebar or toolbar
  const [examinationHeaderData, setExaminationHeaderData] = useState<MinimapData>({})
  const [caseId, setCaseId] = useState('')
  const [commentThreadsByLineId, setCommentThreadsByLineId] = useState<IdToCommentDict>({})
  const [caseTitle, setCaseTitle] = useState('')
  const [docketNumber, setDocketNumber] = useState('')
  const [shouldShowPdfs, setShouldShowPdfs] = useState(false)
  const [sidebarExhibits, setSidebarExhibits] = useState<ExhibitData[]>([])
  const [openTabsValue, setOpenTabsValue] = useState(documentInitialTabValue)
  const [openTabs, setOpenTabs] = useState<WorkspaceTab[]>([
    { id: 'document', name: 'document', type: TabPanelType.Document },
  ])
  const { documentMetadata, lines, pages, isLoadingDocument, error } = useDocument(caseId)

  // document state
  const documentAreaRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<VirtuosoHandle>(null)

  const scrollToRow = async (lineId: string) => {
    if (pages && lines) {
      if (openTabsValue !== 0) await handleTabChange(0)
      const pageId = lines[lineId].page_id
      const page = pages[pageId]
      const pageIndex = documentMetadata?.page_ids.indexOf(pageId)
      const rowOffset = ROW_HEIGHT * page.line_ids.indexOf(lineId)
      if (pageIndex !== undefined) {
        // scroll to correct page (NOTE: this aligns start of page in the center of screen)
        listRef.current?.scrollToIndex({ index: pageIndex, align: 'start' })

        // scroll to correct row within page
        listRef.current?.scrollBy({ top: rowOffset })
      }
    }
  }

  const scrollToPage = async (pageNumber: number) => {
    const pageIds = documentMetadata?.page_ids
    if (pageIds && pages && lines) {
      // const isPageNumber = (pageId: string) => pages[pageId].page_number === pageNumber
      const pageIndex = pageIds.findIndex(
        (pageId: string) => pages[pageId].page_number === pageNumber
      )
      listRef.current?.scrollToIndex({ index: pageIndex, align: 'center' })
    }
  }

  const handleTabChange = async (newTabValue: number) => {
    await setOpenTabsValue(newTabValue)
  }

  const viewTab = (newTab: WorkspaceTab) => {
    // check if its open already, if it is select it, else add to state, then select it
    const tabIndex = openTabs.findIndex((tab) => tab.id === newTab.id)

    if (tabIndex !== -1) {
      setOpenTabsValue(tabIndex)
    } else {
      setOpenTabs((prevTabs) => [...prevTabs, newTab])
      setOpenTabsValue(openTabs.length)
    }
  }

  useEffect(() => {
    let newCommentsNodeRef: firebase.database.Reference
    let deletedCommentsNodeRef: firebase.database.Reference

    if (caseId) {
      newCommentsNodeRef = listenForNewCommentThreads(caseId, setCommentThreadsByLineId)
      deletedCommentsNodeRef = listenForDeletedCommentThreads(caseId, setCommentThreadsByLineId)

      updateCaseWithCurrentDate(caseId)
        .then((caseData) => {
          setCaseTitle(caseData.title)
          setDocketNumber(caseData.docket_number)
        })
        .catch((err) => {
          addAlert(err.message)
        })
    }

    return () => {
      newCommentsNodeRef?.off()
      deletedCommentsNodeRef?.off()
    }
  }, [caseId])

  useEffect(() => {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
    const caseId = urlParams.get('id')

    if (caseId) {
      setCaseId(caseId)
    } else {
      console.error('document not found')
    }
  }, [])

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Workspace {SITE_TITLE_POSTFIX}</title>
      </Helmet>
      <ScrollToProvider scrollToRow={scrollToRow} scrollToPage={scrollToPage}>
        <CaseIdProvider caseId={caseId}>
          <AppBar position="sticky">
            <Header />
            <ToolBar
              caseTitle={caseTitle}
              docketNumber={docketNumber}
              shouldShowPdfs={shouldShowPdfs}
              setShouldShowPdfs={setShouldShowPdfs}
              handleTabChange={handleTabChange}
              openTabsValue={openTabsValue}
              openTabs={openTabs}
            />
          </AppBar>
          <div className={classes.displayArea} ref={documentAreaRef}>
            {openTabs.map((tab, index) => (
              <TabPanel
                value={openTabsValue}
                index={index}
                isDocumentPanel={tab.type === TabPanelType.Document}
                data-testid={WorkspaceTestIds.TabPanel}
                key={tab.id}
              >
                <TabPanelContent
                  tab={tab}
                  document={
                    <Document
                      setExaminationHeaderData={setExaminationHeaderData}
                      commentThreadsByLineId={commentThreadsByLineId}
                      setSidebarExhibits={setSidebarExhibits}
                      documentAreaRef={documentAreaRef}
                      shouldShowPdfs={shouldShowPdfs}
                      listRef={listRef}
                    />
                  }
                  exhibitProfile={<ExhibitProfile id={tab.id} />}
                  characterProfile={<CharacterProfile id={tab.id} />}
                />
              </TabPanel>
            ))}
            <Sidebar
              examinationHeaderData={examinationHeaderData}
              commentThreadsByLineId={commentThreadsByLineId}
              sidebarExhibits={sidebarExhibits}
              setSidebarExhibits={setSidebarExhibits}
              viewTab={viewTab}
            />
          </div>
        </CaseIdProvider>
      </ScrollToProvider>
    </div>
  )
}

export default withStyles(styles)(Workspace)
