export enum AddNewExhibitTestIds {
  root = 'add-new-exhibit',
  nameForm = 'name-form',
  notesForm = 'notes-form',
  fileSelectButton = 'file-select-button',
  fileInput = 'file-input',
  submitButton = 'submit',
  closeButton = 'close',
}

export enum NotesTestIds {
  Root,
  NotesForm,
}

export enum AddExhibitMentionsTestIds {
  Root,
  SearchInput,
  ExhibitButton,
}

export enum DocumentTestIds {
  Root = 'document-root',
}

export enum ExhibitProfileTestIds {
  Root = 'exhibit-profile-root',
}

export enum CharacterProfileTestIds {
  Root = 'character-profile-root',
}
