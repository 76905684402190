import React from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import AddNewExhibit from './AddNewExhibit'
import { ExhibitData, RowRefsByLineId } from '../../../types'
import AddExhibitMention from './AddExhibitMention'

const styles = () =>
  createStyles({
    root: {
      width: 350,
      marginRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
    },
  })

type Props = WithStyles<typeof styles> & {
  lineId: string
  caseId: string
  setShouldShowAddExhibitDialog: React.Dispatch<React.SetStateAction<boolean>>
  setSidebarExhibits: React.Dispatch<React.SetStateAction<ExhibitData[]>>
}

const ExhibitDialog = ({
  classes,
  lineId,
  caseId,
  setShouldShowAddExhibitDialog,
  setSidebarExhibits,
}: Props) => {
  return (
    <Paper className={classes.root} elevation={2}>
      <AddNewExhibit
        lineId={lineId}
        caseId={caseId}
        setShouldShowAddExhibitDialog={setShouldShowAddExhibitDialog}
        setSidebarExhibits={setSidebarExhibits}
      />
      <AddExhibitMention
        lineId={lineId}
        caseId={caseId}
        setShouldShowAddExhibitDialog={setShouldShowAddExhibitDialog}
      />
    </Paper>
  )
}

export default withStyles(styles)(ExhibitDialog)
