import React, { createRef, memo, useEffect, useMemo, useState } from 'react'
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles'
import { Document as PdfProvider, pdfjs } from 'react-pdf'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  DocumentMetadata,
  ExhibitData,
  IdToCommentDict,
  LineData,
  LinesMap,
  MinimapData,
  PagesMap,
  SuggestionsByLineId,
} from '../../../types'
import Page from './Page'
import { TaggingProvider } from '../../../utils/context-providers'
import { getFirstFileNameByCaseId, retrieveRawPdf, useDocument } from '../../../api/'
import { useAlert, useCaseId } from '../../../utils'
import { Virtuoso } from 'react-virtuoso'
import { VirtuosoHandle } from 'react-virtuoso/dist/components'
import { CircularProgress } from '@material-ui/core'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

export const PAGE_MARGIN_BOTTOM = 60
const OVERSCAN_IN_PIXELS = 5000

// this function is so ugly and does three things (build/concatenate the page lines together and build
// the header data for the mini-map, save aggregated lines as a LinesMap) so that we don't have to go through the huge document
// JSON blob from Elastic twice; shaves non-trivial amt of time off page load
export const extractDocumentData = (
  pageIds: string[] | undefined,
  pages: PagesMap | undefined,
  lines: LinesMap | undefined
) => {
  let examinationHeaderData: MinimapData = {}
  const pageLines: LineData[][] = []

  if (pages && pageIds?.length && lines?.[pages[pageIds[0]]?.line_ids[0]]) {
    pageIds.forEach((pageId, index) => {
      const page = pages[pageId]
      const lineIds = page?.line_ids
      if (lineIds?.length) {
        const examinationHeaderDataForPage = lineIds
          .filter((lineId) => lines[lineId].is_examination_start)
          .reduce((acc, lineId) => {
            const line = lines[lineId]
            return {
              ...acc,
              [lineId]: {
                title: line.raw,
                examinationId: line.examination_id!,
                ref: createRef<HTMLDivElement>(),
                startPageNumber: page.page_number,
                // TODO: Add end page number when backend/nlp adds all page numbers (rn it's just the first page in the examination)
              },
            }
          }, {} as MinimapData)
        examinationHeaderData = { ...examinationHeaderData, ...examinationHeaderDataForPage }
        pageLines[index] = lineIds.map((lineId) => lines[lineId])
      }
    })
  }

  return { pageLines, examinationHeaderData }
}

/*
  currently we just get the first file associated with case, once we refactor postgres
  to support associating more than one file w a case we will need to start differentiating different
  raw pdf files somehow so we can get the correct file for the current document
*/
const fetchPdf = async (
  caseId: string,
  setPdf: React.Dispatch<React.SetStateAction<Uint8Array | null>>,
  addAlert: (alert: string) => void
) => {
  try {
    const filename = await getFirstFileNameByCaseId(caseId)
    if (filename) {
      const rawPdf = await retrieveRawPdf(caseId, filename)
      setPdf(rawPdf)
    }
  } catch (e) {
    addAlert('Raw PDF file not found')
    console.error(e.message)
  }
}

const styles = () =>
  createStyles({
    root: {
      height: '100%',
    },

    docRow: {
      background: 'white',
      boxShadow: '2px 10px 4px 0px #9aa2b3',
    },

    commentButtonsContainer: {
      display: 'flex',
      position: 'absolute',
      marginLeft: -330,
      width: 300,
    },

    virtuosoList: {
      // selects the first div inside the list as Virtuoso doesn't export or label
      // with class name. the padding is used to create separation btwn the document
      // and toolbar
      '& > div:first-child': {
        paddingTop: 40,
      },
    },
  })

type Props = WithStyles<typeof styles> & {
  setExaminationHeaderData: React.Dispatch<React.SetStateAction<MinimapData>>
  documentAreaRef: React.RefObject<HTMLDivElement>
  commentThreadsByLineId: IdToCommentDict
  shouldShowPdfs: boolean
  setSidebarExhibits: React.Dispatch<React.SetStateAction<ExhibitData[]>>
  listRef: React.RefObject<VirtuosoHandle>
}

const Document = memo(
  ({
    classes,
    setExaminationHeaderData,
    documentAreaRef,
    commentThreadsByLineId,
    shouldShowPdfs,
    setSidebarExhibits,
    listRef,
  }: Props) => {
    const { addAlert } = useAlert()!
    const caseId = useCaseId()
    const { pages, lines, documentMetadata, isLoadingDocument, error } = useDocument(caseId)
    const [pdf, setPdf] = useState<Uint8Array | null>(null)
    const { pageLines, examinationHeaderData } = useMemo(
      () => extractDocumentData(documentMetadata?.page_ids, pages, lines),
      [documentMetadata?.page_ids, lines, pages, extractDocumentData]
    )

    useEffect(() => {
      setExaminationHeaderData(examinationHeaderData)
    }, [examinationHeaderData])

    useEffect(() => {
      if (caseId) fetchPdf(caseId, setPdf, addAlert)
    }, [caseId, addAlert])

    return (
      <div className={classes.root} id="document">
        {isLoadingDocument ? (
          <CircularProgress />
        ) : error ? (
          <p>{error.message}</p>
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              // Autosizer *cannot* be nested within PdfProvider
              <TaggingProvider caseId={caseId}>
                <PdfProvider file={pdf}>
                  <Virtuoso
                    className={classes.virtuosoList}
                    style={{ height, width }}
                    overscan={OVERSCAN_IN_PIXELS}
                    totalCount={documentMetadata?.page_ids.length ?? 0}
                    ref={listRef}
                    itemContent={(index: number) => {
                      const pageId = documentMetadata?.page_ids[index] ?? ''
                      return (
                        <Page
                          pageData={pages?.[pageId]}
                          pageLines={pageLines[index]}
                          documentAreaRef={documentAreaRef}
                          caseId={caseId}
                          commentThreadsByLineId={commentThreadsByLineId}
                          shouldShowPdfs={shouldShowPdfs}
                          setSidebarExhibits={setSidebarExhibits}
                          pageIndex={index}
                        />
                      )
                    }}
                  />
                </PdfProvider>
              </TaggingProvider>
            )}
          </AutoSizer>
        )}
      </div>
    )
  }
)

export default withStyles(styles)(Document)
