export const getTimeStampString = (timeStamp: Date) => {
  const year = timeStamp.getFullYear()
  const month = timeStamp.getMonth()
  const date = timeStamp.getDate()
  const hours = timeStamp.getHours()
  const minutes = timeStamp.getMinutes()
  const hoursString = hours < 10 ? `0${hours}` : `${hours}`
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`
  return `${month}/${date}/${year} at ${hoursString}:${minutesString}`
}

export const getUniqueKey = (() => {
  let currentKey = 0
  return () => {
    currentKey += 1
    return `${currentKey}`
  }
})()

export const timeSince = (date: Date) => {
  // Unary + will coerce to number.
  const seconds = Math.floor((+new Date() - +date) / 1000)
  let intervalType: string

  let interval = Math.floor(seconds / 31536000)
  if (interval >= 1) {
    intervalType = 'y'
  } else {
    interval = Math.floor(seconds / 2592000)
    if (interval >= 1) {
      intervalType = 'months'
    } else {
      interval = Math.floor(seconds / 86400)
      if (interval >= 1) {
        intervalType = 'd'
      } else {
        interval = Math.floor(seconds / 3600)
        if (interval >= 1) {
          intervalType = 'hr'
        } else {
          interval = Math.floor(seconds / 60)
          if (interval >= 1) {
            intervalType = 'm'
          } else {
            interval = seconds
            intervalType = 's'
          }
        }
      }
    }
  }

  if (intervalType === 's' && interval <= 59) {
    return 'just now'
  } else {
    return `${
      intervalType === 'months' ? `${interval} ${intervalType}` : interval + intervalType
    } ago`
  }
}

/*
 * Basic date handling stuff for now, should be made more user centric later (ex: using 'just now', '5m ago'
 * instead of full date if comment made in last day
 */
export const getFormattedDate = (parsedDate: Date) => {
  const hours = parsedDate.getHours()
  const oldMinutes = parsedDate.getMinutes()
  // prepends 0 since minutes 0-9 are shown as ':0' instead of ':00'
  const newMinutes = oldMinutes < 10 ? `0${oldMinutes}` : `${oldMinutes}`
  const time = `${hours % 12}:${newMinutes} ${hours > 12 ? 'PM' : 'AM'}`
  return `${parsedDate.getMonth()}/${parsedDate.getDay()}/${parsedDate.getFullYear()} | ${time}`
}

// code from old project may be useful for Briefcase at later point in time
// if had more time would  be better to make this more flexible for tweets to be able to return
// something like '18m ago'
export const getTimeLastSeen = (lastContact: Date) => {
  const now = new Date()
  const numberOfMsOneDay = 86400000
  const msDifference = now.getTime() - lastContact.getTime()
  if (msDifference < numberOfMsOneDay) return 'today'
  return msDifference < numberOfMsOneDay
    ? 'today'
    : `${Math.floor(msDifference / numberOfMsOneDay)} days ago`
}

export const isObjectEmpty = (obj: Record<any, any>) => obj && Object.keys(obj).length === 0

export const getRandomTagColor = () => {
  const colors = [
    '#f07565', // 0 salmon
    '#eda058', // 1 orange
    '#f7e05e', // 2 yellow
    '#cef75c', // 3 lime
    '#4fbd58', // 4 forest
    '#40e3cd', // 5 turquoise
    '#238fb0', // 6 sky
    '#5378c2', // 7 navy
    '#5555d4', // 8 indigo
    '#aa7cd9', // 9 purple
    '#cc68ac', // 10 magenta
  ]
  const randomColorIndex = Math.floor(Math.random() * (colors.length + 1))
  return colors[randomColorIndex]
}

export const areFilesValid = (fileList: File[], acceptedFileTypes: string[]): boolean =>
  fileList.length !== 0 && fileList.every((file) => acceptedFileTypes.includes(file.type))

const removeContentTypePrefix = (source: string, prefix: string) => source.replace(prefix, '')

export const stripContentTypePrefix = (
  acceptedExhibitFileTypes: string[],
  contentTypePrefix: string
) =>
  acceptedExhibitFileTypes
    .map((fileType) => removeContentTypePrefix(fileType, contentTypePrefix))
    .join(', ')

export function debounce(callback: ((...args: any[]) => void) | undefined, wait: number) {
  let timeout: ReturnType<typeof setTimeout>

  return (...args: any[]) => {
    clearTimeout(timeout)

    timeout = setTimeout(() => callback?.(...args), wait)
  }
}

// function overloads
export function removeArrayItem<T>(array: T[], elementIndex: number): T[]
export function removeArrayItem<T>(array: T[], element: T): T[]
export function removeArrayItem<T>(array: T[], elementIndexOrElement: T | number): T[] {
  const index =
    typeof elementIndexOrElement === 'number'
      ? elementIndexOrElement
      : array.indexOf(elementIndexOrElement)
  if (index > -1) {
    return [...array.slice(0, index), ...array.slice(index + 1)]
  }
  return array
}
