import React, { createContext, FC, useCallback, useEffect, useRef, useState } from 'react'
import { Snackbar } from '@material-ui/core'
import { AlertContextProvider } from '../../types'
import { AlertBarTestId } from '../../testing/test-ids'

export const AlertContext = createContext<AlertContextProvider>({} as AlertContextProvider)

const AUTO_DISMISS_TIME = 5000

type Props = {
  children: React.ReactNode
}

export const AlertProvider: FC<Props> = ({ children }) => {
  // Queue of alerts, they display one after another, each following the
  // dismissal of the one before it
  const [alerts, setAlerts] = useState<string[]>([])
  const activeDismissalTimeouts = useRef<ReturnType<typeof setTimeout>[]>([])

  useEffect(() => {
    // Clear any remaining timeouts when the component unmounts
    return () => activeDismissalTimeouts.current.forEach((timeout) => clearTimeout(timeout))
  }, [])

  const dismissCurrentAlert = useCallback(() => {
    setAlerts(alerts.slice(0, alerts.length - 1))
    activeDismissalTimeouts.current = activeDismissalTimeouts.current.slice(
      0,
      activeDismissalTimeouts.current.length - 1
    )
  }, [activeDismissalTimeouts])

  const addAlert = useCallback(
    (alert: string) => {
      setAlerts([alert, ...alerts])
      const clearAlertTimeout = setTimeout(dismissCurrentAlert, AUTO_DISMISS_TIME)
      activeDismissalTimeouts.current.push(clearAlertTimeout)
    },
    [dismissCurrentAlert, activeDismissalTimeouts, AUTO_DISMISS_TIME]
  )

  return (
    <AlertContext.Provider value={{ addAlert, dismissCurrentAlert }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message={alerts[alerts.length - 1]}
        /* this is not a perfect solution in terms of animation/prettiness */
        open={alerts.length > 0}
        data-testid={AlertBarTestId}
      />
    </AlertContext.Provider>
  )
}
