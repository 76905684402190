import React, { useEffect, useState } from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'

import AllCases from './AllCases'
import RecentCases from './RecentCases'

import { useAuthState } from '../../../utils'

import { Filters } from '../../../types'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      width: '80vw',
      maxWidth: '100%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      flexDirection: 'column',
    },
  })

type Props = WithStyles<typeof styles> & {
  filters: Filters
}

const CaseCardList: React.FC<Props> = (props: Props) => {
  const { classes, filters } = props
  const { user } = useAuthState()

  return (
    <div className={classes.root}>
      <RecentCases userId={user.id} />
      <AllCases userId={user.id} filters={filters} />
    </div>
  )
}

export default withStyles(styles)(CaseCardList)
