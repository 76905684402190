import React, { RefObject } from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Page } from 'react-pdf'

const styles = () =>
  createStyles({
    root: {
      marginLeft: 4,
    },
  })

type Props = WithStyles<typeof styles> & {
  pageRef: RefObject<HTMLDivElement>
  pageNumber: number
}

const PdfPage = ({ classes, pageNumber }: Props) => (
  <Page pageNumber={pageNumber} className={classes.root} />
)

export default withStyles(styles)(PdfPage)
