import React, { useState } from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Avatar, IconButton, Input, Paper, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SendIcon from '@material-ui/icons/Send'

import { createNewCommentThread } from '../../../../services/firebase/dal'
import { useAuthState } from '../../../../utils'
import RoundedInputField from '../../../../components/RoundedInputField'
import { addReplyComment } from '../../../../services/firebase/dal'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 350,
      minHeight: 70,
      padding: theme.spacing(1),
      marginRight: 8,
    },

    userInfo: {
      display: 'flex',
      alignItems: 'center',
    },

    userAvatar: {
      marginRight: theme.spacing(1),
      width: theme.spacing(4),
      height: theme.spacing(4),
    },

    inputContainer: {
      display: 'flex',
      flex: 1,
      marginTop: theme.spacing(1),
    },

    submitButton: {
      marginLeft: theme.spacing(1),
    },

    closeButton: {
      marginLeft: 'auto',
    },
  })

type Props = WithStyles<typeof styles> & {
  setShouldShowCommentDialog: React.Dispatch<React.SetStateAction<boolean>>
  caseId: string
  lineId: string
  rootCommentUID: string
}

const CommentDialog = ({ classes, setShouldShowCommentDialog, caseId, lineId }: Props) => {
  const [message, setMessage] = useState('')
  const { user } = useAuthState()

  const handleCloseClick = () => {
    setShouldShowCommentDialog(false)
  }

  const handleReplyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setMessage(event.currentTarget.value)
  }

  const handleCommentSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (message !== '') {
      createNewCommentThread(user.id, message, caseId, lineId, user.email)
      setShouldShowCommentDialog(false)
    }
  }

  return (
    <Paper elevation={2} className={classes.root}>
      <div className={classes.userInfo}>
        <Avatar
          className={classes.userAvatar}
          alt="user profile pic"
          // set src prop from postgres
        />
        <Typography>{user.email}</Typography>
        <IconButton className={classes.closeButton} onClick={handleCloseClick}>
          <CloseIcon />
        </IconButton>
      </div>
      <form onSubmit={handleCommentSubmit}>
        <div className={classes.inputContainer}>
          <RoundedInputField
            handleInputChange={handleReplyChange}
            placeholder="Type reply here"
            inputValue={message}
          />
          <IconButton className={classes.submitButton} type="submit">
            <SendIcon />
          </IconButton>
        </div>
      </form>
    </Paper>
  )
}

export default withStyles(styles)(CommentDialog)
