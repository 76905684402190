import React from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import Heading from './Heading'
import { LineData, PageData } from '../../../types'
import Description from './Description'
import SpeakerDialogue from './SpeakerDialogue'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
    },
  })

type Props = WithStyles<typeof styles> & {
  quote: { text: string; speaker: string }
  line: LineData
}

const DocumentDisplay = ({ quote, line }: Props) => {
  return (
    <>
      {line.is_examination_start ? (
        <Heading text={line.raw} />
      ) : line.is_quote_start ? (
        <SpeakerDialogue speakerName={quote.speaker} speakerText={quote.text} />
      ) : (
        <Description text={line.raw} />
      )}
    </>
  )
}

export default withStyles(styles)(DocumentDisplay)
