import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Button, TextField, Typography } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import { useAlert } from '../../utils'
import { SignUpTestIds } from '../../testing/test-ids'

import {
  validateEmail,
  MIN_PASSWORD_LENGTH,
  shortPasswordMessage,
  invalidEmailMessage,
} from './index'
import { UserSignUpForm } from '../../types'
import { signUpUser } from '../../api'
import { Routes } from '../../constants'

import horizontalLogo from '../../assets/images/briefcase-logo-horizontal-02.png'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      maxWidth: 320,
    },
    title: {
      color: 'black',
      alignItems: 'left',
      alignSelf: 'left',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      paddingBottom: theme.spacing(4),
    },
    field: {
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    submitButton: {
      color: theme.palette.common.white,
      backgroundColor: '#50718F', // gray
      whiteSpace: 'nowrap',
      width: '100%',
    },
    redirectButton: {
      width: '50%',
      fontSize: 11,
    },
    logo: {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginTop: theme.spacing(25),
      marginBottom: theme.spacing(10),
      cursor: 'pointer',
    },
  })

export const passwordMismatchMessage = 'Please make sure your passwords match'

type Props = WithStyles<typeof styles> & {}

const SignUp: React.FC<Props> = ({ classes }) => {
  const history = useHistory()
  const [form, setForm] = useState<UserSignUpForm>({
    email: '',
    password: '',
    matchPassword: '',
    firmId: '',
  })
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(false)
  const [isPasswordLengthSufficient, setIsPasswordLengthSufficient] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)

  const { addAlert, dismissCurrentAlert } = useAlert()!

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setIsEmailValid(validateEmail(event.target.value))
    setForm({
      ...form,
      email: event.target.value,
    })
  }

  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setForm({
      ...form,
      password: event.target.value,
    })
    setIsPasswordLengthSufficient(event.target.value.length >= MIN_PASSWORD_LENGTH)
    setDoPasswordsMatch(event.target.value === form.matchPassword)
  }

  const handleMatchPasswordChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setForm({
      ...form,
      matchPassword: event.target.value,
    })
    setDoPasswordsMatch(event.target.value === form.password)
  }

  const handleFirmIdChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setForm({
      ...form,
      firmId: event.target.value,
    })
  }

  const postSignUp = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    signUpUser({
      email: form.email,
      password: form.password,
      firm_id: form.firmId,
    })
      .then((parsedBody) => {
        history.push(Routes.Login)
        addAlert(`Please check your email for confirmation link.`)
      })
      .catch((err) => {
        addAlert(err.message)
      })
  }

  return (
    <div className={classes.root} data-testid={SignUpTestIds.root}>
      <img
        className={classes.logo}
        src={horizontalLogo}
        alt="logo"
        onClick={() => history.push(Routes.Home)}
      />
      <Typography className={classes.title}>Sign Up</Typography>
      <form onSubmit={postSignUp} data-testid={SignUpTestIds.form}>
        <TextField
          className={classes.field}
          data-testid={SignUpTestIds.email}
          label="Email"
          name="email"
          variant="outlined"
          value={form.email}
          onChange={handleEmailChange}
          error={!isEmailValid && form.email.length > 0}
          helperText={!isEmailValid && form.email.length > 0 ? invalidEmailMessage : null}
          required
        />
        <TextField
          className={classes.field}
          data-testid={SignUpTestIds.password}
          label="Password"
          name="password"
          variant="outlined"
          type="password"
          value={form.password}
          onChange={handlePasswordChange}
          error={!isPasswordLengthSufficient && form.password.length > 0}
          helperText={
            !isPasswordLengthSufficient && form.password.length > 0 ? shortPasswordMessage : null
          }
          required
        />
        <TextField
          className={classes.field}
          data-testid={SignUpTestIds.passwordMatch}
          label="Re-Enter Password"
          variant="outlined"
          type="password"
          onChange={handleMatchPasswordChange}
          required
          error={!doPasswordsMatch && form.password.length > 0}
          helperText={
            !doPasswordsMatch && form.password.length > 0 ? passwordMismatchMessage : null
          }
        />
        <TextField
          className={classes.field}
          data-testid={SignUpTestIds.teamId}
          label="Team I.D."
          name="firmId"
          variant="outlined"
          type="number"
          value={form.firmId}
          onChange={handleFirmIdChange}
          required
        />
        <Button
          className={classes.submitButton}
          data-testid={SignUpTestIds.submit}
          variant="contained"
          type="submit"
          disabled={!isEmailValid || !doPasswordsMatch || form.firmId.length === 0}
        >
          Submit
        </Button>
        <Button className={classes.redirectButton} component={Link} to={Routes.Login}>
          Already Registered?
        </Button>
        <Button className={classes.redirectButton} component={Link} to={Routes.ForgotPassword}>
          Forgot Password?
        </Button>
      </form>
    </div>
  )
}

export default withStyles(styles)(SignUp)
