import React, { FC, memo } from 'react'

import clsx from 'clsx'

import { Avatar, Button } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import UserProfileAvatar from '../../components/UserProfileAvatar'

import { Routes } from '../../constants'

import briefcaseLogoWhite from '../../assets/images/briefcase-logo-horizontal-white.png'
const styles = (theme: Theme) =>
  createStyles({
    // All Grid Items
    gridItem: {
      backgroundColor: theme.palette.common.white,
    },

    // Each Grid Item below
    sliver: {
      height: '10px',
      backgroundColor: '#0B2547', // darker blue
    },

    headerLeft: {
      backgroundColor: '#263F67', // dark blue
      display: 'flex',
    },

    // Header Contents
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 155,
      marginLeft: theme.spacing(1),
    },

    headerButtonsContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    userAvatar: {
      width: 30,
      height: 30,
      marginRight: theme.spacing(0.5),
    },

    logo: {
      width: '100%',
      height: 'auto',
    },
  })

type Props = WithStyles<typeof styles>

const Header: FC<Props> = memo((props) => {
  const { classes } = props
  return (
    <React.Fragment>
      <div className={clsx(classes.gridItem, classes.sliver)}>&nbsp;</div>
      <div className={clsx(classes.gridItem, classes.headerLeft)}>
        <div className={classes.logoContainer}>
          <Button href={Routes.Dashboard}>
            <img className={classes.logo} src={briefcaseLogoWhite} alt="logo" />
          </Button>
        </div>
        <div className={classes.headerButtonsContainer}>
          <UserProfileAvatar />
        </div>
      </div>
    </React.Fragment>
  )
})

export default withStyles(styles)(Header)
