export enum WorkspaceTestIds {
  Root,
  TabPanel,
}

export enum MentionsTestIds {
  Root = 'mentions-root',
  MentionButton = 'mention-button',
}

export enum ViewTagSuggestionsTestIds {
  Root = 'view-tag-suggestions',
  SuggestionCard = 'suggestion-card',
}

export enum TagSuggestionCardTestIds {
  Root = 'tag-suggestion-card',
  AddTagButton = 'add-tag-button',
}

export enum TagLineInfoTestIds {
  Root = 'tag-line-info',
  AddTagButton = 'add-tag-button',
  ViewLineButton = 'view-line',
}
