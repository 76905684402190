import React from 'react'

import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

const styles = () =>
  createStyles({
    root: {
      padding: '2px 16px',
    },

    speakerName: {
      fontWeight: 700,
    },

    speakerGridItem: {
      marginRight: 16,
    },
  })

type Props = WithStyles<typeof styles> & {
  speakerName: string
  speakerText: string
}

const SpeakerDialogue = ({ classes, speakerName, speakerText }: Props) => {
  return (
    <Grid container wrap="nowrap" className={classes.root}>
      <Grid item xs={2} />
      <Grid className={classes.speakerGridItem} item xs="auto">
        <Typography className={classes.speakerName}>{speakerName}</Typography>
      </Grid>
      <Grid item xs="auto">
        <Typography>{speakerText}</Typography>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(SpeakerDialogue)
