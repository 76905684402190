import { Dispatch, SetStateAction } from 'react'
import { getRef, removeNode, update } from './helpers'

const JOB_IDS_PARENT = 'jobIds'

// rn we can store job ids by case id or document id, once we're document id centric for file uploads,
// we can just store by doc id

export function updateJobIds(jobIdsByCaseOrDocumentId: { [caseOrDocumentId: string]: string }) {
  const jobIdsUpdateWithPrepend = Object.fromEntries(
    Object.entries(jobIdsByCaseOrDocumentId).map(([caseOrDocumentId, jobId]) => [
      `${JOB_IDS_PARENT}/${caseOrDocumentId}`,
      jobId,
    ])
  )
  update(jobIdsUpdateWithPrepend)
}

export function checkForJobId(
  caseOrDocumentId: string,
  setJobId: Dispatch<SetStateAction<string>>
) {
  getRef(`${JOB_IDS_PARENT}/${caseOrDocumentId}`).once('value', (jobIdSnapshot) => {
    const jobId = jobIdSnapshot.val()
    if (jobId) setJobId(jobId)
  })
}

export function removeJobId(caseOrDocumentId: string) {
  removeNode(`${JOB_IDS_PARENT}/${caseOrDocumentId}`)
}
