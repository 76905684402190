import { Dispatch, SetStateAction } from 'react'
import { getRef, update } from './helpers'
import { Note } from '../../../types'

export function updateNotes(id: string, newNotes: string) {
  const updates: Note = {
    content: newNotes,
  }

  update({
    [`notes/${id}`]: updates,
  })
}

export function loadNotesOnce(id: string, setNotes: Dispatch<SetStateAction<Note | null>>) {
  getRef(`notes/${id}`).once('value', (notesSnapshot) => {
    const notes = notesSnapshot.val()
    if (notes) setNotes(notes)
  })
}

export function listenForNotes(id: string, setNotes: Dispatch<SetStateAction<Note | null>>) {
  const ref = getRef(`notes${id}`)
  ref.on('child_changed', (updatedSnapshot) => {
    const notes: Note = updatedSnapshot.val()
    if (notes) setNotes(notes)
  })

  return ref
}
