import React from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Avatar, IconButton } from '@material-ui/core'
import { Routes } from '../constants'

const styles = () =>
  createStyles({
    userAvatar: {
      width: 30,
      height: 30,
    },
  })

type Props = WithStyles<typeof styles>

const UserProfileAvatar = ({ classes }: Props) => (
  <IconButton component={Link} to={Routes.Profile}>
    <Avatar className={classes.userAvatar} src="/broken-image.jpg" />
  </IconButton>
)

export default withStyles(styles)(UserProfileAvatar)
