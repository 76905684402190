import { GetParams, SuccessResponse, Tag, TagData } from '../types'
import { get, httpDelete, post } from './httpHelpers'

export const getTagsByCaseId = (caseId: string, params: GetParams) =>
  get<TagData[]>(`cases/tags/list/case/${caseId}`, params).then(
    (response) => response.parsedBody!['tags']
  )

export const postTagLabel = (tagLabel: string, hexColor: string, caseId: string) =>
  post<TagData>('cases/tag/create', {
    tag_color: hexColor,
    tag_name: tagLabel,
    case_id: caseId,
  }).then((response) => response.parsedBody!['tag'])

export const deleteTagByTagId = (tagId: Tag['id']) =>
  httpDelete<SuccessResponse>(`cases/tag/${tagId}`).then((response) => response)
