import React, { FC } from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import TitleHeader from '../TitleHeader'
import SearchAndFilterBy from '../SearchAndFilterBy'
import ExhibitProfileCard from './ExhibitProfileCard'
import { ExhibitData, WorkspaceTab } from '../../../../types'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 310,
      minWidth: 310,
    },

    heading: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%,',
    },

    headingTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#2c3f67',
      minHeight: 80,
      height: 80,
      width: '100%',
    },

    exhibitCardsContainer: {
      marginTop: 30,
    },
  })

type Props = WithStyles<typeof styles> & {
  sidebarExhibits: ExhibitData[]
  viewTab: (tab: WorkspaceTab) => void
}

const ExhibitProfiles: FC<Props> = ({ classes, sidebarExhibits, viewTab }) => (
  <div className={classes.root}>
    <div className={classes.heading}>
      <TitleHeader title="Exhibit Profiles" />
      <SearchAndFilterBy />
    </div>
    <div className={classes.exhibitCardsContainer}>
      {sidebarExhibits?.map((exhibit) => (
        <ExhibitProfileCard
          name={exhibit.title}
          exhibitId={exhibit.id}
          viewTab={viewTab}
          key={exhibit.id}
        />
      ))}
    </div>
  </div>
)

export default withStyles(styles)(ExhibitProfiles)
