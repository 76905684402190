import React, { useEffect, useState } from 'react'

import { Divider, TextField, Typography, CircularProgress, IconButton } from '@material-ui/core'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

import { CaseData, Filters } from '../../../types'
import { getCasesByUserId, getFilteredCasesByUserId } from '../../../api'
import { useAlert, useDebounce, useIsMounted } from '../../../utils'
import { AllCasesTestIds } from '../../../testing/test-ids'

import CaseButton from './CaseButton'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(8),
      height: '100vh',
      width: '100vw',
      maxWidth: '100%',
    },

    headerContainer: {
      display: 'flex',
    },

    titleHeaderText: {
      marginTop: 'auto',
      fontWeight: 600,
      fontSize: '1rem',
      alignSelf: 'left',
      width: '40%',
    },

    otherHeaderText: {
      marginTop: 'auto',
      fontWeight: 600,
      fontSize: '1rem',
      alignSelf: 'left',
      width: '20%',
    },

    contentTitleDivider: {
      width: '96%',
    },

    casesContainer: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      rowGap: '1px',
      width: '100%',

      justifyContent: 'center',
    },

    expandContainer: {
      minWidth: 270,
      maxWidth: 270,
      minHeight: 80,
      maxHeight: 80,
      alignItems: 'center',
      display: 'flex',
    },

    progressContainer: {
      width: '100%',
      minWidth: 270,
      maxWidth: 900,
      minHeight: 80,
      maxHeight: 80,
      textAlign: 'center',
    },

    addIcon: {
      minWidth: 60,
      minHeight: 60,
      margin: 'auto',
    },

    searchBar: {
      minWidth: '500px',
      maxWidth: '800px',
      marginTop: '10px',
      marginBottom: '10px',
    },
  })

type Props = WithStyles<typeof styles> & {
  userId: string
  filters: Filters
}

export const NUM_CASES = 50

const AllCases: React.FC<Props> = (props: Props) => {
  const { classes, userId, filters } = props
  const [cases, setCases] = useState<CaseData[]>([])
  const [offset, setOffset] = useState(0)
  const [isSearching, setIsSearching] = useState(true)
  const isMounted = useIsMounted()

  const { addAlert } = useAlert()!

  const fetchCases = async (userId: string, offset: number, query?: string) => {
    setIsSearching(true)

    await getCasesByUserId(
      userId,
      {
        offset: offset,
        limit: NUM_CASES,
      },
      query
    )
      .then((newCases) => {
        isMounted && setCases(cases.concat(newCases))
      })
      .catch((err) => {
        isMounted && setCases([])
        addAlert(err.message)
      })

    setIsSearching(false)
  }

  const fetchFilteredCases = async (userId: string, offset: number, filters?: Filters) => {
    setIsSearching(true)

    setCases([])

    await getFilteredCasesByUserId(
      userId,
      {
        offset: offset,
        limit: NUM_CASES,
      },
      filters
    )
      .then((newCases) => {
        console.info('Inside fetchFilteredCases, after .then returns data, newCases:', newCases)
        console.info('How many newCases are there?', newCases.length)
        console.info('filters: ', filters)
        // isMounted && setCases(cases.concat(newCases))
        // NOTE: taking out the concat messes with the functionality of the plus button, for now it won't work with filters
        isMounted && setCases(newCases)
      })
      .catch((err) => {
        isMounted && setCases([])
        addAlert(err.message)
      })

    setIsSearching(false)
  }

  useEffect(() => {
    if (!userId) return

    fetchCases(userId, offset, filters.query != '' ? filters.query : undefined)
  }, [userId, offset])

  useEffect(() => {
    if (!userId) return

    fetchFilteredCases(userId, offset, filters != null ? filters : undefined)
  }, [userId, filters])

  function handleClick() {
    setOffset(offset + NUM_CASES)
  }

  return (
    <div className={classes.root} data-testid={AllCasesTestIds.root}>
      <div className={classes.headerContainer}>
        <Typography className={classes.titleHeaderText}>Title</Typography>
        <Typography className={classes.otherHeaderText}>Court Name</Typography>
        <Typography className={classes.otherHeaderText}>Docket Number</Typography>
        <Typography className={classes.otherHeaderText}>Last Modified</Typography>
      </div>
      <Divider className={classes.contentTitleDivider} orientation="horizontal" />
      <div className={classes.casesContainer} data-testid={AllCasesTestIds.casesContainer}>
        {/* Case buttons */}
        {cases.map((c) => (
          <CaseButton
            transcriptId={c.id}
            key={c.id}
            lastOpened={c.last_opened!}
            docketNumber={c.docket_number}
            courtName={c.court_name}
          >
            {c.title}
          </CaseButton>
        ))}
        {isSearching ? (
          <div className={classes.progressContainer}>
            <CircularProgress
              className={classes.addIcon}
              data-testid={AllCasesTestIds.loadingIcon}
            />
          </div>
        ) : cases.length ? (
          <div className={classes.expandContainer}>
            <IconButton onClick={handleClick} data-testid={AllCasesTestIds.expandButton}>
              <AddIcon className={classes.addIcon} />
            </IconButton>
          </div>
        ) : (
          <div className={classes.progressContainer}>
            <Typography>No Cases Found</Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(AllCases)
