import React, { useRef } from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import {
  ExhibitData,
  IdToCommentDict,
  LineData,
  PageData,
  SuggestionsByLineId,
} from '../../../types'
import { useInViewport } from '../../../utils'
import Row from './Row'
import RowContent from './RowContent'
import PdfPage from './PdfPage'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 60,
    },

    rowsContainer: {
      width: 900,
    },

    pdfButton: {
      marginLeft: 16,
      marginRight: theme.spacing(2),
    },

    pageNumber: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      background: 'white',
      width: '95%',
      padding: '16px 40px 0px 0px',
      boxShadow: '2px 10px 4px 0px #9aa2b3',
      color: 'rgba(0, 0, 0, 0.5)',
    },
  })

type Props = WithStyles<typeof styles> & {
  pageLines: LineData[]
  pageData: PageData | undefined
  documentAreaRef: React.RefObject<HTMLDivElement>
  caseId: string
  commentThreadsByLineId: IdToCommentDict
  shouldShowPdfs: boolean
  setSidebarExhibits: React.Dispatch<React.SetStateAction<ExhibitData[]>>
  pageIndex: number
}

const Page = ({
  classes,
  pageData,
  pageLines,
  documentAreaRef,
  caseId,
  commentThreadsByLineId,
  shouldShowPdfs,
  setSidebarExhibits,
  pageIndex,
}: Props) => {
  const pageRef = useRef<HTMLDivElement>(null)

  return (
    <div className={classes.root} ref={pageRef}>
      <div className={classes.rowsContainer}>
        <div className={classes.pageNumber}>
          <Typography>{pageData?.page_number}</Typography>
        </div>
        {pageLines?.map((line) => {
          const lineId = line.line_id
          const quote = {
            speaker: line.raw.slice(0, line.speech_start),
            text: line.raw.slice(line.speech_start),
          }

          const rowContent = <RowContent quote={quote} line={line} />

          return (
            <Row
              documentAreaRef={documentAreaRef}
              caseId={caseId}
              lineId={line.line_id}
              quoteId={line.quote_id}
              commentThread={commentThreadsByLineId[line.line_id]}
              rawLine={line.raw}
              setSidebarExhibits={setSidebarExhibits}
              rowContent={rowContent}
              documentId={pageData?.doc_id}
              key={lineId}
            />
          )
        })}
      </div>
      {shouldShowPdfs && pageData && <PdfPage pageRef={pageRef} pageNumber={pageIndex + 1} />}
    </div>
  )
}

export default withStyles(styles)(Page)
