import React from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Avatar, Button, Card, CardContent, Divider, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { WorkspaceTab } from '../../../../types'
import { TabPanelType } from '../../../../constants'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 20px 15px 20px',
    },

    buttonBase: {
      height: 20,
      color: 'white',
      marginBottom: 5,
    },

    viewExhibitButton: {
      background: '#53b893',
      '&:hover': {
        background: '#83ddbb',
      },
      marginLeft: 'auto',
    },

    exhibitInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
    },

    exhibitNameContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },

    avatarIcon: {
      marginRight: 10,
    },

    exhibitName: {
      fontWeight: 900,
    },

    cardContent: {
      display: 'flex',
      flexDirection: 'column',
    },

    highlightButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 8px 8px 8px ',
    },

    highlightButton: {
      background: '#50718F',
      '&:hover': {
        background: '#7f99b5',
      },
    },
  })

type Props = WithStyles<typeof styles> & {
  name: string
  exhibitId: string
  viewTab: (tab: WorkspaceTab) => void
}

const ExhibitProfileCard = ({ classes, exhibitId, name, viewTab }: Props) => {
  return (
    <div className={classes.root}>
      <Card raised>
        <CardContent classes={{ root: classes.cardContent }}>
          <Button
            variant="contained"
            size="small"
            className={clsx(classes.buttonBase, classes.viewExhibitButton)}
            onClick={() => viewTab({ id: exhibitId, name, type: TabPanelType.ExhibitProfile })}
          >
            View Exhibit
          </Button>
          <div className={classes.exhibitInfoContainer}>
            <Avatar className={classes.avatarIcon} />
            <div className={classes.exhibitNameContainer}>
              <Typography className={classes.exhibitName}>{name}</Typography>
            </div>
          </div>
          <Divider />
        </CardContent>
        <div className={classes.highlightButtonContainer}>
          <Button
            variant="contained"
            size="small"
            className={clsx(classes.buttonBase, classes.highlightButton)}
            disabled
          >
            Highlight Exhibit Mentions
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default withStyles(styles)(ExhibitProfileCard)
