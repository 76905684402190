import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Button, TextField, Typography } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import { Routes } from '../../constants'
import { invalidEmailMessage, validateEmail } from './index'
import { ForgotPasswordForm } from '../../types'
import { useAlert } from '../../utils'
import { forgotPassword } from '../../api'
import { ForgotPasswordTestIds } from '../../testing/test-ids'

import horizontalLogo from '../../assets/images/briefcase-logo-horizontal-02.png'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      maxWidth: 320,
    },
    title: {
      color: theme.palette.common.black,
      alignItems: 'left',
      alignSelf: 'left',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      paddingBottom: theme.spacing(1),
    },
    subtext: {
      color: theme.palette.common.black,
      alignItems: 'left',
      alignSelf: 'left',
      fontSize: '1rem',
      paddingBottom: theme.spacing(4),
    },
    field: {
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    submitButton: {
      color: theme.palette.common.white,
      backgroundColor: '#50718F', // gray
      whiteSpace: 'nowrap',
      width: '100%',
    },
    redirectButton: {
      width: '50%',
      fontSize: 11,
    },

    // TODO: make some kind of importable style for consistent styles in onboarding
    logo: {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginTop: theme.spacing(25),
      marginBottom: theme.spacing(10),
      cursor: 'pointer',
    },
  })

export const successMessage = 'Please check your Email for a recovery link.'

type Props = WithStyles<typeof styles>

const ForgotPassword: React.FC<Props> = ({ classes }) => {
  const [form, setForm] = useState<ForgotPasswordForm>({ email: '' })
  const [isEmailValid, setIsEmailValid] = useState(false)
  const { addAlert, dismissCurrentAlert } = useAlert()!
  const history = useHistory()

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setIsEmailValid(validateEmail(event.target.value))
    setForm({ ...form, email: event.target.value })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    forgotPassword(form)
      .then((parsedBody) => {
        addAlert(successMessage)
        history.push(Routes.Login)
      })
      .catch((err) => {
        addAlert(err.message)
      })
  }

  return (
    <div className={classes.root} data-testid={ForgotPasswordTestIds.root}>
      <img
        className={classes.logo}
        src={horizontalLogo}
        alt="logo"
        onClick={() => history.push(Routes.Home)}
      />
      <Typography className={classes.title}>Forgot your Password?</Typography>
      <Typography className={classes.subtext}>Enter your email below</Typography>
      <form onSubmit={handleSubmit} data-testid={ForgotPasswordTestIds.form}>
        <TextField
          className={classes.field}
          label="Email"
          data-testid={ForgotPasswordTestIds.email}
          variant="outlined"
          value={form.email}
          onChange={handleEmailChange}
          error={form.email.length > 0 && !isEmailValid}
          helperText={!isEmailValid && form.email.length > 0 ? invalidEmailMessage : null}
          required
        />
        <Button
          className={classes.submitButton}
          data-testid={ForgotPasswordTestIds.submit}
          variant="contained"
          type="submit"
          disabled={!isEmailValid}
        >
          Submit
        </Button>
        <div>
          <Button className={classes.redirectButton} component={Link} to={Routes.Login}>
            Login to your Account
          </Button>
          <Button className={classes.redirectButton} component={Link} to={Routes.SignUp}>
            Make an Account
          </Button>
        </div>
      </form>
    </div>
  )
}

export default withStyles(styles)(ForgotPassword)
