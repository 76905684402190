import { firebase } from '../index'
import { getRef, push, removeNode, update } from './helpers'
import { Dispatch, SetStateAction } from 'react'
import { TaggedQuotes, TaggedQuoteTagInfo } from '../../../types'

export async function addTagForRow(
  lineId: string,
  caseId: string,
  quoteId: string,
  tagLabel: string,
  rawText: string
) {
  const newTagRef = push('tags/')

  let success = false

  // we use update here because it is atomic
  await update(
    {
      [`tags/${newTagRef.key!}`]: {
        dateTimeCreated: firebase.database.ServerValue.TIMESTAMP,
        rawText,
        tagLabel,
        quoteId,
      },
      [`transcript/${caseId}/tags/${newTagRef.key!}`]: { tagLabel, lineId },
    },
    (error) => {
      if (error) {
        removeNode(`comments/${newTagRef.key}`)
      } else {
        success = true
      }
    }
  )
  return success
}

export function listenForNewTagByCaseId(
  caseId: string,
  setTaggedRows: Dispatch<SetStateAction<TaggedQuotes>>
) {
  const transcriptTagsRef = getRef(`transcript/${caseId}/tags`)

  transcriptTagsRef.on('child_added', (tag) => {
    const newTag: TaggedQuoteTagInfo = { ...tag.val(), tagDbKey: tag.key }
    setTaggedRows((prevTags) => ({ ...prevTags, [newTag.lineId]: newTag }))
  })

  return transcriptTagsRef
}
