import React from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import Notes from '../Notes'
import ExhibitMedia from './ExhibitMedia'
import Mentions from '../Mentions'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      width: 1500,
      background: 'white',
      padding: 20,
      margin: '24px 0px 24px 0px',
    },

    leftSide: {
      width: '40%',
      marginRight: 40,
    },
  })

type Props = WithStyles<typeof styles> & {
  id: string
}

const ExhibitProfile = ({ classes, id }: Props) => (
  <Paper elevation={3} className={classes.root}>
    <div className={classes.leftSide}>
      <Notes id={id} />
      <ExhibitMedia id={id} />
    </div>
    <Mentions id={id} />
  </Paper>
)

export default withStyles(styles)(ExhibitProfile)
