export enum AllCasesTestIds {
  root = 'all-cases',
  casesContainer = 'all-cases-container',
  expandButton = 'expand-button',
  searchField = 'search-field',
  loadingIcon = 'loading-icon',
}

export enum CaseButtonTestIds {
  root = 'case-button',
}

export enum CaseCardTestIds {
  root = 'case-card',
}

export enum RecentCasesTestIds {
  root = 'recent-cases',
  casesContainer = 'recent-cases-container',
  rightArrow = 'right-arrow',
}
