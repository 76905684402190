import React, { useEffect, useState } from 'react'

import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Box, Button, CircularProgress } from '@material-ui/core'

import { getRandomTagColor, useAlert } from '../../../utils'
import { getExhibitsByCaseId, postExhibitMention } from '../../../api'
import RoundedInputField from '../../../components/RoundedInputField'
import { ExhibitData } from '../../../types'
import { AddExhibitMentionsTestIds } from '../../../testing/test-ids/document'
import { useDebounce } from '../../../utils'

const fetchExhibits = async (caseId: string) => {
  try {
    return await getExhibitsByCaseId(caseId, { offset: 0, limit: 100 })
  } catch (e) {
    console.error(e.message)
    return Promise.resolve([] as ExhibitData[])
  }
}

const addNewMention = async (
  exhibitId: string,
  lineId: string,
  addAlert: (message: string) => void
) => {
  try {
    const success = await postExhibitMention(exhibitId, lineId)
    if (success) {
      addAlert('Mention successfully added')
    }
    return success
  } catch (e) {
    console.error(e.message)
    return false
  }
}

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 30,
      paddingRight: 30,
    },

    title: {
      marginBottom: 8,
    },

    searchInput: {
      marginBottom: 8,
      width: '100%',
    },

    tagButtonContainer: {
      whiteSpace: 'nowrap',
      marginRight: 8,
    },

    tagButton: {
      // occasionally getRandomTagColor doesn't work, idk why so this is default
      background: 'lightblue',
      color: 'black',
      padding: '3px 10px 3px 10px',
      borderRadius: 17,
      margin: '2px 2px 2px 0px',
    },

    exhibitTagsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      maxHeight: 160,
      overflowY: 'auto',
    },
  })

type Props = WithStyles<typeof styles> & {
  lineId: string
  caseId: string
  setShouldShowAddExhibitDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const AddExhibitMention = ({ classes, lineId, caseId, setShouldShowAddExhibitDialog }: Props) => {
  const { addAlert } = useAlert()!
  const [originalExhibits, setOriginalExhibits] = useState<ExhibitData[]>([])
  const [displayedExhibits, setDisplayedExhibits] = useState<ExhibitData[]>([])
  const [isApiPending, setIsApiPending] = useState(true)
  const [exhibitColors, setExhibitColors] = useState<{ [exhibitId: string]: string }>({})
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedQuery = useDebounce<string>(searchQuery, 500)

  const handleNewSearchQuery = (query: string) => {
    if (query) {
      const regex = new RegExp(query.trim(), 'i')
      const filteredExhibits = originalExhibits.filter(({ title }) => title.match(regex))
      setDisplayedExhibits(filteredExhibits)
    } else {
      setDisplayedExhibits(originalExhibits)
    }
  }

  const handleMentionClick = async (exhibitId: string) => {
    const success = await addNewMention(exhibitId, lineId, addAlert)
    if (success) setShouldShowAddExhibitDialog(false)
  }

  useEffect(() => {
    if (caseId) {
      fetchExhibits(caseId).then((exhibits) => {
        setOriginalExhibits(exhibits)
        setDisplayedExhibits(exhibits)
        setIsApiPending(false)
      })
    }
  }, [caseId])

  useEffect(() => {
    const colors = originalExhibits.reduce<{ [exhibitId: string]: string }>(
      (acc, exhibit) => ({ ...acc, [exhibit.id]: getRandomTagColor() }),
      {}
    )
    setExhibitColors(colors)
  }, [originalExhibits])

  useEffect(() => {
    handleNewSearchQuery(debouncedQuery)
  }, [debouncedQuery])

  return (
    <div className={classes.root} data-testid={AddExhibitMentionsTestIds.Root}>
      <Box fontWeight="fontWeightBold" fontSize={16} className={classes.title}>
        Mark an Exhibit Mention
      </Box>
      <RoundedInputField
        className={classes.searchInput}
        inputValue={searchQuery}
        handleInputChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search for Exhibit"
        data-testid={AddExhibitMentionsTestIds.SearchInput}
      />

      {isApiPending ? (
        <CircularProgress size={45} />
      ) : (
        <div className={classes.exhibitTagsContainer}>
          {displayedExhibits.map((exhibit) => (
            <span
              className={classes.tagButtonContainer}
              key={exhibit.id}
              data-testid={AddExhibitMentionsTestIds.ExhibitButton}
            >
              <Button
                className={classes.tagButton}
                onClick={() => handleMentionClick(exhibit.id)}
                style={{ background: exhibitColors[exhibit.id] }}
              >
                {exhibit.title}
              </Button>
            </span>
          ))}
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(AddExhibitMention)
