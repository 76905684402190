import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { Button, Divider, Typography } from '@material-ui/core'

import { useAlert, useAuthState } from '../../../utils'
import { Routes } from '../../../constants'

import SidebarAvatar from './common/SidebarAvatar'
import SystemUpdates from './common/SystemUpdates'
import { logOutOfSession } from '../../../api'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginTop: theme.spacing(6),
    },

    sidebarAvatarContainer: {
      padding: theme.spacing(3),
    },

    sidebarContent: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },

    // Sidebar Subheader
    subheader: {
      marginTop: theme.spacing(2),
      fontFamily: 'Heebo',
    },

    subheaderUserText: {
      fontWeight: 900,
      fontFamily: 'Heebo',
    },

    subheaderSubText: {
      fontWeight: 400,
      fontFamily: 'Heebo',
    },

    // Sidebar Button Area
    buttonArea: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      paddingTop: theme.spacing(3),
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
    },

    button: {
      display: 'flex',
      flex: 1,
      color: theme.palette.common.white,
      backgroundColor: '#50718F', // gray
      marginBottom: theme.spacing(2),
      whiteSpace: 'nowrap',
    },

    // Sidebar Updates Area
    divider: {
      marginTop: theme.spacing(4),
    },

    updatesTextArea: {
      paddingTop: theme.spacing(3),
    },
  })

type Props = WithStyles<typeof styles> & {
  path: string
}

const ProfileSidebar: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const { removeUser, setIsAuthenticated } = useAuthState()
  const { addAlert } = useAlert()!
  const history = useHistory()

  const onLogOutClick = () => {
    const logOut = () => {
      logOutOfSession()
        .then(() => {
          removeUser()
          setIsAuthenticated(false)
          history.push(Routes.Login)
        })
        .catch((err) => {
          console.error(err)
          addAlert('Failed to log out, please wait then try again.')
        })
    }

    logOut()
  }

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.sidebarAvatarContainer}>
          <SidebarAvatar />
        </div>
      </div>
      <div className={classes.sidebarContent}>
        <div className={classes.subheader}>
          <Typography className={classes.subheaderUserText} variant="h6">
            Name
          </Typography>
          <Typography className={classes.subheaderSubText} variant="h6">
            Company Name
          </Typography>
        </div>
        <div className={classes.buttonArea}>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              variant="contained"
              component={Link}
              disabled
              to={Routes.ChangePassword}
            >
              Change Password
            </Button>
            <Button className={classes.button} variant="contained" onClick={onLogOutClick}>
              Log Out
            </Button>
          </div>
        </div>
        <Divider className={classes.divider} orientation="horizontal" />
        <div className={classes.updatesTextArea}>
          <SystemUpdates />
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(ProfileSidebar)
