export enum OnboardingTestIds {
  root = 'onboarding',
  version = 'version',
}

export enum LoginTestIds {
  root = 'login',
  form = 'form',
  email = 'email',
  password = 'password',
  submit = 'submit',
}

export enum SignUpTestIds {
  root = 'signup',
  form = 'form',
  email = 'email',
  password = 'password',
  passwordMatch = 'password-match',
  teamId = 'team-id',
  submit = 'submit',
}

export enum ForgotPasswordTestIds {
  root = 'forgot-password',
  form = 'form',
  email = 'email',
  submit = 'submit',
}
