import React, { useEffect, useState } from 'react'

import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Card, CardActionArea, Typography } from '@material-ui/core'

import { getExhibitMentions, useDocument } from '../../api/'
import { useCaseId, useIsMounted, useScrollTo } from '../../utils'
import MentionContent from './document/MentionContent'
import { MentionsTestIds } from '../../testing/test-ids/workspace'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
    },

    title: {
      marginBottom: 8,
    },

    mentionsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },

    mention: {
      textAlign: 'left',
      width: '100%',
      marginBottom: 24,
    },

    mentionActionArea: {
      padding: 12,
    },
  })

const fetchExhibitMentions = async (
  exhibitId: string,
  setMentions: React.Dispatch<React.SetStateAction<string[]>>,
  isMounted: React.MutableRefObject<boolean>
) => {
  const mentions = await getExhibitMentions(exhibitId).catch((e) => console.error(e.message))

  if (isMounted.current && mentions) {
    setMentions(mentions)
  }
}

type Props = WithStyles<typeof styles> & {
  id: string
}

const Mentions = ({ classes, id }: Props) => {
  const isMounted = useIsMounted()
  const { scrollToRow } = useScrollTo()
  const caseId = useCaseId()
  const { pages, lines } = useDocument(caseId)
  const [mentions, setMentions] = useState<string[]>([])

  useEffect(() => {
    if (id) fetchExhibitMentions(id, setMentions, isMounted)
  }, [id])

  return (
    <div className={classes.root} data-testid={MentionsTestIds.Root}>
      <Typography variant="h4" className={classes.title}>
        Mentions
      </Typography>
      <div className={classes.mentionsContainer}>
        {pages &&
          lines &&
          mentions.map((lineId) => {
            const line = lines[lineId]
            const pageId = line.page_id
            const page = pages[pageId]
            const quote = {
              speaker: line.raw.slice(0, line.speech_start),
              text: line.raw.slice(line.speech_start),
            }

            return (
              <Card
                elevation={3}
                className={classes.mention}
                key={lineId}
                data-testid={MentionsTestIds.MentionButton}
              >
                <CardActionArea
                  className={classes.mentionActionArea}
                  onClick={() => scrollToRow(lineId)}
                >
                  <MentionContent quote={quote} line={line} page={page} />
                </CardActionArea>
              </Card>
            )
          })}
      </div>
    </div>
  )
}

export default withStyles(styles)(Mentions)
