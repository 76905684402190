import React, { useEffect, useState } from 'react'
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import { Input } from '@material-ui/core'
import { SearchAndFilterByTestIds } from '../../../testing/test-ids/view-comments'
import { useDebounce } from '../../../utils/hooks'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-evenly ',
      alignItems: 'center',
      background: '#0B2547',
      height: 50,
      padding: 10,
      width: '100%',
    },

    searchInput: {
      background: '#50718F',
      color: 'white',
      height: 30,
      borderRadius: 17,
    },

    inputPadding: {
      padding: '6px 12px',
    },

    filterButton: {
      background: '#53B893',
      '&:hover': {
        background: '#83ddbb',
      },
      whiteSpace: 'nowrap',
      color: 'white',
      margin: theme.spacing(1),
    },
  })

type Props = WithStyles<typeof styles> & {
  handleNewSearchQuery?: (query: string) => void
}

const SearchAndFilterBy = ({ classes, handleNewSearchQuery }: Props) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce<string>(query, 500)

  useEffect(() => {
    if (handleNewSearchQuery) {
      handleNewSearchQuery(debouncedQuery)
    }
  }, [debouncedQuery])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setQuery(event.target.value)

  return (
    <div className={classes.root} data-testid={SearchAndFilterByTestIds.root}>
      <Input
        classes={{ root: classes.searchInput, input: classes.inputPadding }}
        disableUnderline
        type="text"
        placeholder="Search"
        onChange={handleInputChange}
        value={query}
        inputProps={{ 'data-testid': SearchAndFilterByTestIds.searchQueryInput }}
      />
    </div>
  )
}

export default withStyles(styles)(SearchAndFilterBy)
