import React from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Avatar, Button, Card, CardContent, Divider, Typography } from '@material-ui/core'
import clsx from 'clsx'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 20px 15px 20px',
    },

    buttonBase: {
      height: 20,
      color: 'white',
      marginBottom: 5,
    },

    viewExhibitButton: {
      background: '#53b893',
      '&:hover': {
        background: '#83ddbb',
      },
      marginLeft: 'auto',
    },

    witnessInfo: {
      display: 'flex',
      flexDirection: 'row',
    },

    witnessNameContainer: {
      display: 'flex',
      flexDirection: 'column',
    },

    avatarIcon: {
      marginRight: 10,
    },

    witnessName: {
      fontWeight: 900,
    },

    cardContent: {
      display: 'flex',
      flexDirection: 'column',
    },

    highlightButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 8px 8px 8px ',
    },

    highlightButton: {
      background: '#50718F',
      '&:hover': {
        background: '#7f99b5',
      },
    },
  })

type Props = WithStyles<typeof styles> & {
  name: string
  title: string
}

const CharacterProfileCard = (props: Props) => {
  const { classes, name, title } = props
  return (
    <div className={classes.root}>
      <Card raised>
        <CardContent classes={{ root: classes.cardContent }}>
          <Button
            variant="contained"
            size="small"
            className={clsx(classes.buttonBase, classes.viewExhibitButton)}
          >
            View Character
          </Button>
          <div className={classes.witnessInfo}>
            <Avatar className={classes.avatarIcon} />
            <div className={classes.witnessNameContainer}>
              <Typography className={classes.witnessName}>{name}</Typography>
              <Typography>{title}</Typography>
            </div>
          </div>
          <Divider />
        </CardContent>
        <div className={classes.highlightButtonContainer}>
          <Button
            variant="contained"
            size="small"
            className={clsx(classes.buttonBase, classes.highlightButton)}
          >
            Highlight Character Mentions
          </Button>
          <Button
            variant="contained"
            size="small"
            className={clsx(classes.buttonBase, classes.highlightButton)}
          >
            Highlight Character Statements
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default withStyles(styles)(CharacterProfileCard)
