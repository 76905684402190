import React, { useEffect, useState } from 'react'

import { Divider, Typography } from '@material-ui/core'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'

import CaseCard from './CaseCard'

import { getCasesByUserId } from '../../../api'
import { CaseData } from '../../../types'
import { useAlert } from '../../../utils'
import { RecentCasesTestIds } from '../../../testing/test-ids'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '95vw',
      maxWidth: '100%',
      //marginRight: theme.spacing(1),
    },

    headerContainer: {
      display: 'flex',
    },

    headerText: {
      fontWeight: 500,
      fontSize: '1rem',
      alignSelf: 'left',
    },

    contentTitleDivider: {
      marginTop: theme.spacing(1),
    },

    casesContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flex: 1,
      marginTop: theme.spacing(4),
      alignItems: 'center',
      //overflow: 'scroll',
    },

    cases: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      //overflow: 'none',
    },

    arrowIcon: {
      minWidth: 'auto',
      minHeight: 60,
    },

    title: {
      display: 'flex',
    },

    headerText1: {
      fontWeight: 700,
      fontSize: '1.3rem',
      alignSelf: 'left',
    },
  })

type Props = WithStyles<typeof styles> & {
  userId: string
}

export const NUM_CASES = 4

const fetchCasesChronologically = async (
  userId: string,
  offset: number,
  setCases: React.Dispatch<React.SetStateAction<CaseData[]>>,
  setOffset: React.Dispatch<React.SetStateAction<number>>,
  setShouldShowCases: React.Dispatch<React.SetStateAction<boolean>>,
  addAlert: Function
) => {
  getCasesByUserId(userId, {
    offset: offset,
    limit: NUM_CASES,
    chronologically: true,
  })
    .then((newCases) => {
      // Reached the end of cases
      if (offset && !newCases.length) {
        setOffset(0)
      } else {
        setCases(newCases)
        setShouldShowCases(true)
      }
    })
    .catch((err) => {
      addAlert(err.message)
    })
}

const RecentCases: React.FC<Props> = (props: Props) => {
  const { classes, userId } = props
  const [cases, setCases] = useState<CaseData[]>([])
  const [offset, setOffset] = useState(0)
  const [shouldShowCases, setShouldShowCases] = useState(true)

  const { addAlert } = useAlert()!

  useEffect(() => {
    if (userId)
      fetchCasesChronologically(userId, offset, setCases, setOffset, setShouldShowCases, addAlert)
  }, [userId, offset])

  function handleRightArrowClick() {
    setShouldShowCases(false)
    setOffset(offset + NUM_CASES)
  }

  function handleLeftArrowClick() {
    setShouldShowCases(false)
    setOffset(offset - NUM_CASES)
  }

  return (
    <div className={classes.root} data-testid={RecentCasesTestIds.root}>
      <div className={classes.title}>
        <Typography className={classes.headerText1}>My Cases</Typography>
      </div>
      <Divider className={classes.contentTitleDivider} orientation="horizontal" />
      <div className={classes.headerContainer}>
        <Typography className={classes.headerText}>Recents</Typography>
      </div>
      <div className={classes.casesContainer}>
        {offset > 0 && (
          <KeyboardArrowLeftIcon className={classes.arrowIcon} onClick={handleLeftArrowClick} />
        )}

        {cases.map((c, index) => (
          <CaseCard
            caseName={c.title}
            transcriptId={c.id}
            lastOpened={c.last_opened!}
            shouldShowCases={shouldShowCases}
            index={index}
            key={c.id}
          />
        ))}

        {cases.length > 0 ? (
          <KeyboardArrowRightIcon
            className={classes.arrowIcon}
            onClick={handleRightArrowClick}
            data-testid={RecentCasesTestIds.rightArrow}
          />
        ) : null}
      </div>
    </div>
  )
}

export default withStyles(styles)(RecentCases)
