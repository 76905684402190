import React from 'react'

type Props = {
  children?: React.ReactNode
  index: number
  value: number
  isDocumentPanel: boolean
}

const TabPanel = ({ children, index, value, isDocumentPanel }: Props) => (
  // don't want the document to unmount and have to do expensive rerender when
  // we click away/click back so only hide document with `hidden` attr.
  // for exhibit and character profiles we do want to unmount to refresh data
  // when clicking to profile
  <div hidden={value !== index} style={{ width: isDocumentPanel ? '100%' : '' }}>
    {isDocumentPanel ? children : value == index && children}
  </div>
)

export default TabPanel
