import { firebase } from '../index'
import { Dispatch, SetStateAction } from 'react'
import { getRef, push, removeNode, update } from './helpers'
import { FirebaseThread, IdToCommentDict } from '../../../types'

export function createNewCommentThread(
  authorUID: string,
  message: string,
  caseId: string,
  lineId: string,
  email: string
) {
  const newThreadRef = push('comments/')

  // we use update here because it is atomic
  update(
    {
      [`comments/${newThreadRef.key!}`]: {
        authorUID,
        email,
        dateTimeCreated: firebase.database.ServerValue.TIMESTAMP,
        message,
        lineId,
        replies: [],
      },
      [`transcript/${caseId}/comments/${newThreadRef.key!}`]: true,
    },
    (error) => {
      if (error) {
        removeNode(`comments/${newThreadRef.key}`)
      }
    }
  )
}

export function addReplyComment(
  message: string,
  authorId: string,
  email: string,
  rootCommentUID: string
) {
  console.log('addReplyComment', message, authorId, rootCommentUID)
  push(`comments/${rootCommentUID}/replies`, {
    authorUID: authorId,
    email,
    message,
    dateTimeCreated: firebase.database.ServerValue.TIMESTAMP,
  })
}

export function listenForNewCommentThreads(
  caseId: string,
  setCommentThreadsByLineId: Dispatch<SetStateAction<IdToCommentDict>>
) {
  const ref = getRef(`transcript/${caseId}/comments`)
  ref.on('child_added', (newThreadSnapshot) => {
    const commentDbKey = newThreadSnapshot.key!
    getRef(`comments/${commentDbKey}`).once('value', (threadSnapshot) => {
      setCommentThreadsByLineId((previousThreads) => {
        const newThread: FirebaseThread = { ...threadSnapshot.val(), commentDbKey }
        return { ...previousThreads, [newThread.lineId]: newThread }
      })
    })
  })

  return ref
}

export function listenForDeletedCommentThreads(
  caseId: string,
  setCommentThreadsByLineId: Dispatch<SetStateAction<IdToCommentDict>>
) {
  const ref = getRef(`transcript/${caseId}/comments`)
  ref.on('child_removed', (deletedThreadSnapshot) => {
    const threadKey = deletedThreadSnapshot.key!
    getRef(`comments/${threadKey}`).once('value', (removedThreadSnapshot) => {
      setCommentThreadsByLineId((prevThreads) => {
        const { [removedThreadSnapshot.val().lineId]: t, ...newThreads } = prevThreads
        return newThreads
      })
      // this should really be in deleteCommentThread but we need to get the quoteUID before we delete
      // the comment data
      removeNode(`comments/${threadKey}`)
    })
  })

  return ref
}

export function deleteCommentThread(threadId: string | null, caseId: string) {
  removeNode(`transcript/${caseId}/comments/${threadId}`)
}

// export function deleteReplyComment(threadId: string | null, replyId: string) {
//   removeNode(`comments/${threadId}/replies/${replyId}`)
// }
export function deleteReplyComment(commentId: string | null, replyId: string) {
  update(
    {
      [`comments/${commentId}/replies/${replyId}`]: null,
    },
    (error) => {
      if (error) {
        console.error('Error deleting reply:', error)
      }
    }
  )
}
