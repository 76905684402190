import { useContext } from 'react'
import { AlertContext, AuthContext, CaseIdContext, TaggingContext } from './context-providers/'
import { ScrollToContext } from './context-providers/ScrollToRowProvider'

export const useAlert = () => useContext(AlertContext)

export const useAuthState = () => useContext(AuthContext)

export const useTagging = () => useContext(TaggingContext)

export const useScrollTo = () => useContext(ScrollToContext)

export const useCaseId = () => useContext(CaseIdContext)
