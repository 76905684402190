import React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'

import SidebarAvatar from './SidebarAvatar'
import SidebarSubheader from './SidebarSubheader'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },

    sidebarContent: {
      padding: theme.spacing(3),
    },

    logoAvatar: {
      width: 165,
      height: 165,
    },

    // Sidebar Text Area
    welcomeTextArea: {
      marginTop: theme.spacing(2),
      fontFamily: 'Heebo',
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },

    welcomeUserText: {
      fontWeight: 900,
      fontFamily: 'Heebo',
    },

    welcomeSubText: {
      fontWeight: 400,
      fontFamily: 'Heebo',
    },
  })

type Props = WithStyles<typeof styles> & {}

const SidebarHeader: React.FC<Props> = (props: Props) => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <div className={classes.sidebarContent}>
        <SidebarAvatar />
      </div>
      <SidebarSubheader />
    </div>
  )
}

export default withStyles(styles)(SidebarHeader)
