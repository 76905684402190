import React, { FC } from 'react'

import { Avatar, Paper, Typography, withStyles, WithStyles } from '@material-ui/core'
import briefcaseTrans from '../../../../assets/images/briefcase-trans.png'
import { createStyles, Theme } from '@material-ui/core/styles'

import { timeSince } from '../../../../utils'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      backgroundColor: '#F2F2F2',
      padding: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },

    commentHeader: {
      display: 'flex',
      flex: 1,
      marginBottom: theme.spacing(1),
    },

    replyAvatar: {
      marginRight: theme.spacing(2),
      width: 40,
      height: 40,
    },

    logoAvatar: {
      marginRight: theme.spacing(2),
      width: 50,
      height: 50,
    },

    commentSenderName: {
      fontFamily: 'Heebo',
      fontSize: 14,
      fontWeight: 'bold',
    },

    commentTimestamp: {
      fontFamily: 'Heebo',
      fontSize: 12,
    },

    commentText: {
      fontFamily: 'Heebo',
    },

    commentHeaderText: {},
  })

type Props = WithStyles<typeof styles> & {
  name: string
  date: number // unix epoch
  message: string
}

const Comment: FC<Props> = (props) => {
  const { classes, name, date, message } = props

  return (
    <div className={classes.root}>
      <div className={classes.commentHeader}>
        <Avatar
          className={classes.logoAvatar}
          component={Paper}
          elevation={1}
          alt="Briefcase Logo"
          src={briefcaseTrans}
        />
        <div className={classes.commentHeaderText}>
          <Typography className={classes.commentSenderName}>{name}</Typography>
          <Typography className={classes.commentTimestamp}>
            {timeSince(new Date(date))} ago
          </Typography>
        </div>
      </div>
      <Typography className={classes.commentText}>{message}</Typography>
    </div>
  )
}

export default withStyles(styles)(Comment)
