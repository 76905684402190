import { useHistory } from 'react-router-dom'

import clsx from 'clsx'

import { Button } from '@material-ui/core'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'

import { Routes } from '../../constants'

import horizontalLogo from '../../assets/images/briefcase-logo-horizontal-02.png'
import { maxHeaderSize } from 'http'
import { version, useRef, useEffect } from 'react'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: '#D5E6E3',
      width: '100vw',
      maxWidth: '100%',
      maxHeight: 160,
      position: 'sticky',
      // position: 'fixed',
      top: 0,
      zIndex: 999,
    },

    // Header
    headerRow: {
      height: 'auto',
      width: '100%',
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      alignItems: 'center',
      display: 'inline-flex',
      justifyContent: 'space-around',
      minWidth: 0,
    },

    // Header Logo
    logoImageContainer: {
      // flex: 1,
      minWidth: 150,
      // minHeight: 348,
      // backgroundColor: '#E0E0E0',
      // margin: theme.spacing(1),
      maxWidth: 300, // actual max width
      alignContent: 'center',
      justifyContent: 'space-around',
    },

    logoImage: {
      maxWidth: 300, // actual max width
      maxHeight: 60,
      width: '100%', // responsive width
      height: 'auto', // responsive height
    },

    // Header Menu
    menuBar: {
      display: 'flex',
      flex: 1,
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      justifyContent: 'flex-end',
      // minWidth: '500px',
    },
    // Buttons

    signInButton: {
      // background: 'linear-gradient(90deg, #369A98 0%, #487292 100%)',
      background: 'black',
      // display: 'flex',
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      // justifyContent: 'flex-end',
      alignSelf: 'flex-end',
    },

    menuButton: {
      fontWeight: 'bold',
      marginRight: theme.spacing(5),
      maxHeight: 50,
      height: 'auto',
    },

    menuButtonLabel: {
      textTransform: 'capitalize',
      fontSize: 16,
    },
  })

type Props = WithStyles<typeof styles>
const Index = (props: Props) => {
  const { classes } = props
  const history = useHistory()

  return (
    <div className={classes.root}>
      <div className={classes.headerRow}>
        <div className={classes.logoImageContainer}>
          <img
            className={classes.logoImage}
            src={horizontalLogo}
            alt="splash image"
            onClick={() => history.push(Routes.Home)}
          />
        </div>
        <div className={classes.menuBar}>
          {/* About Us Button */}
          <Button
            className={classes.menuButton}
            classes={{
              label: classes.menuButtonLabel,
            }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={() => {
              history.push(Routes.AboutUs)
            }}
          >
            About us
          </Button>
          <Button
            className={classes.menuButton}
            classes={{
              label: classes.menuButtonLabel,
            }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={() => {
              history.push(Routes.Features)
            }}
          >
            Features
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.signInButton}
            classes={{
              label: classes.menuButtonLabel,
            }}
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              history.push(Routes.Login)
            }}
          >
            Sign In
          </Button>
          {/* </div> */}
        </div>
      </div>
    </div>
  )
}
export default withStyles(styles)(Index)
