import React from 'react'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },

    hiddenElement: {
      width: 42,
    },
  })

type Props = WithStyles<typeof styles> & {
  text: string
}

const Heading = ({ classes, text }: Props) => {
  return (
    <>
      <Typography className={classes.root} align="center" variant="h4">
        {text}
      </Typography>
      {/* hacky trick for centering within `Row` */}
      <div className={classes.hiddenElement} />
    </>
  )
}

export default withStyles(styles)(Heading)
