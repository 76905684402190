import React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'
import { useAuthState } from '../../../../utils'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      fontFamily: 'Heebo',
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },

    welcomeUserText: {
      fontWeight: 900,
      fontFamily: 'Heebo',
    },

    welcomeSubText: {
      fontWeight: 400,
      fontFamily: 'Heebo',
    },
  })

type Props = WithStyles<typeof styles>

const SidebarSubheader: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const { user } = useAuthState()

  return (
    <div className={classes.root}>
      <Typography className={classes.welcomeUserText} variant="h6">
        Welcome {user.displayName}!{/* TODO: Make sure to UNDO */}
        {/* Welcome RANDOM NAME !!! MAKE SURE TO CHANGE */}
      </Typography>
      <Typography className={classes.welcomeSubText} variant="h6">
        Let&apos;s get to work.
      </Typography>
    </div>
  )
}

export default withStyles(styles)(SidebarSubheader)
