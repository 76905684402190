import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { Tag, TaggedQuotes, TaggingContextProvider } from '../../types'
import { listenForNewTagByCaseId } from '../../services/firebase/dal'
import { getTagsByCaseId } from '../../api'
import { firebase } from '../../services/firebase'

export const DEFAULT_TAGS: Tag[] = [
  { label: 'Fifth Amendment Used', hexColor: '#4fbd58', id: 'Fifth Amendment Used' },
  { label: 'Expert Witness', hexColor: '#e50f0f', id: 'Expert Witness' },
  { label: 'Identify Defendant', hexColor: '#f7b731', id: 'Identify Defendant' },
  { label: 'Police Witness', hexColor: '#1933e0', id: 'Police Witness' },
  { label: 'Exhibit Mention', hexColor: '#cdc205', id: 'Exhibit Mention' },
  { label: 'Photo Array Mention', hexColor: '#6e33af', id: 'Photo Array Mention' },
]

export const TaggingContext = createContext<TaggingContextProvider>({} as TaggingContextProvider)

type Props = {
  children: ReactNode
  caseId: string
}

export const TaggingProvider = ({ children, caseId }: Props) => {
  const [taggedQuotes, setTaggedQuotes] = useState<TaggedQuotes>({})
  const [tags, setTags] = useState<Tag[]>(DEFAULT_TAGS)

  useEffect(() => {
    let newTagsNodeRef: firebase.database.Reference
    if (caseId) {
      newTagsNodeRef = listenForNewTagByCaseId(caseId, setTaggedQuotes)
    }

    return () => {
      newTagsNodeRef?.off()
    }
  }, [caseId])

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const params = {
          offset: 0,
          limit: 15,
        }
        const tagsResponse = await getTagsByCaseId(caseId, params)

        // ugh frontend in camelCase with backend in snake_case hmmmmm
        const newTags: Tag[] =
          tagsResponse?.map((tag) => ({
            id: tag.id,
            hexColor: tag.tag_color,
            label: tag.tag_name,
          })) ?? []
        setTags([...DEFAULT_TAGS, ...newTags])
      } catch (e) {
        console.error(e.message)
      }
    }

    if (caseId) {
      fetchTags()
    }
  }, [caseId])

  return (
    <TaggingContext.Provider
      value={{
        taggedQuotes,
        tags,
        setTags,
      }}
    >
      {children}
    </TaggingContext.Provider>
  )
}
