import React, { FC } from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import WhiteTextTypography from '../WhiteTextTypography'
import TitleHeader from '../TitleHeader'
import ExaminationHeaderCard from './ExaminationHeaderCard'
import { MinimapData } from '../../../../types'
import { useDocument } from '../../../../api'
import { useCaseId } from '../../../../utils'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 310,
      minWidth: 310,
    },

    heading: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    headingTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#2c3f67',
      minHeight: 80,
      height: 80,
      width: '100%',
    },

    subheading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#0B2547',
      height: 50,
      padding: 10,
    },

    examinationCardContainer: {
      paddingTop: 10,
    },
  })

type Props = WithStyles<typeof styles> & {
  examinationHeaderData: MinimapData
}

const MiniMap: FC<Props> = ({ classes, examinationHeaderData }) => {
  const caseId = useCaseId()
  const { examinations } = useDocument(caseId)

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <TitleHeader title="Case Mini Map" />
        <div className={classes.subheading}>
          <WhiteTextTypography variant="body2" align="center">
            Click on a section header to jump to the place in the document.
          </WhiteTextTypography>
        </div>
      </div>
      <div className={classes.examinationCardContainer}>
        {Object.values(examinationHeaderData).map((examinationHeader, index, headerDataList) => (
          <ExaminationHeaderCard
            headerTitle={examinationHeader.title}
            examination={examinations?.[examinationHeader.examinationId]}
            // summary={examinations?.summary}
            startPageNumber={examinationHeader.startPageNumber}
            endPageNumber={examinationHeader.endPageNumber}
            headerRef={examinationHeader.ref}
            isFirstCard={index === 0}
            isLastCard={index === headerDataList.length - 1}
            key={examinationHeader.examinationId}
          />
        ))}
      </div>
    </div>
  )
}

export default withStyles(styles)(MiniMap)
