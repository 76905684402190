import React, { useState } from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Avatar, Button, IconButton, Paper, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SendIcon from '@material-ui/icons/Send'

// import { FirebaseThread } from '../../../../types'
import { deleteCommentThread } from '../../../../services/firebase/dal'
import { deleteReplyComment } from '../../../../services/firebase/dal'
import { timeSince, useAuthState } from '../../../../utils'
import RoundedInputField from '../../../../components/RoundedInputField'
import { addReplyComment } from '../../../../services/firebase/dal'
import { FirebaseThread, FirebaseComment } from '../../../../types'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 350,
      minHeight: 70,
      padding: theme.spacing(1),
      '&:hover': {
        zIndex: 40,
      },
      marginRight: 8,
    },

    userInfo: {
      display: 'flex',
      alignItems: 'center',
    },

    userAvatar: {
      marginRight: theme.spacing(1),
      width: theme.spacing(4),
      height: theme.spacing(4),
    },

    message: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },

    closeButton: {
      marginLeft: 'auto',
    },

    userName: {
      marginRight: 8,
    },

    messageTypographyRoot: {
      wordWrap: 'break-word',
    },

    inputContainer: {
      display: 'flex',
      flex: 1,
      marginTop: theme.spacing(1),
    },

    submitButton: {
      marginLeft: theme.spacing(1),
    },
  })

type Props = WithStyles<typeof styles> & {
  commentThread: FirebaseThread
  caseId: string
}

const CommentThread = ({ classes, commentThread, caseId }: Props) => {
  const [message, setMessage] = useState('')
  const [replies, setReplies] = useState(Object.values(commentThread.replies || {}))
  const { user } = useAuthState()
  const [showReplies, setShowReplies] = useState(false)

  const handleReplyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setMessage(event.currentTarget.value)
  }

  const handleReplyComment = () => {
    if (message !== '') {
      setReplies([
        ...replies,
        {
          message,
          email: user.email,
          authorUID: Number(user.id),
          dateTimeCreated: new Date().getTime(),
        },
      ])
      setMessage('')
      setShowReplies(true)

      if (commentThread.commentDbKey) {
        addReplyComment(message, user.id, user.email, commentThread.commentDbKey)
        if (commentThread.replies) {
          let replysWithIDs: { [key: string]: FirebaseComment } = {}
          if (commentThread.replies) {
            Object.entries(commentThread.replies).forEach(([replyId, replyObj]) => {
              replysWithIDs[replyId] = replyObj
            })
          }
        }
      }
    }
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.userInfo}>
        <Avatar
          className={classes.userAvatar}
          alt="user profile pic"
          // set src prop from postgres
        />
        <Typography variant={'subtitle1'} className={classes.userName}>
          {commentThread.email}
        </Typography>
        <Typography variant="body2">
          {commentThread.dateTimeCreated ? timeSince(new Date(commentThread.dateTimeCreated)) : ''}
        </Typography>
        <IconButton
          className={classes.closeButton}
          onClick={() => {
            deleteCommentThread(commentThread.commentDbKey, caseId)
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Typography className={classes.message} classes={{ root: classes.messageTypographyRoot }}>
        {commentThread.message}
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          transform: 'scale(0.75)',
          width: '100%',
        }}
      >
        {replies && Object.values(replies).length > 0 && (
          <Button variant="outlined" color="primary" onClick={() => setShowReplies(!showReplies)}>
            {showReplies ? 'Hide replies' : `Show ${replies.length} replies`}
          </Button>
        )}
      </div>
      <div style={{ paddingTop: '4px' }} />
      <div style={{ borderBottom: '1px solid #E5E5E5', width: '100%' }} />
      <div style={{ paddingTop: '4px' }} />

      {showReplies &&
        replies &&
        Object.values(replies).map((reply, index) => (
          <>
            <div className={classes.userInfo}>
              <Avatar className={classes.userAvatar} alt="user profile pic" />
              <Typography variant={'subtitle1'} className={classes.userName}>
                {reply.email}
              </Typography>
              <Typography variant="body2">
                {reply.dateTimeCreated ? timeSince(new Date(reply.dateTimeCreated)) : ''}
              </Typography>
              <IconButton
                className={classes.closeButton}
                onClick={() => {
                  const idToDelete = Object.keys(commentThread.replies || {})[index]
                  setReplies(replies.filter((reply, i) => i !== index))
                  deleteReplyComment(commentThread.commentDbKey, idToDelete)
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Typography key={index} className={classes.message}>
              {reply.message}
              <div style={{ paddingBottom: '8px' }} />
            </Typography>
          </>
        ))}
      <div style={{ display: 'flex', marginTop: '8px' }}>
        <div className={classes.inputContainer}>
          <RoundedInputField
            handleInputChange={handleReplyChange}
            placeholder="Type reply here"
            inputValue={message}
            // when enter is pressed, call onClick of submit button
            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                event.preventDefault()
                handleReplyComment()
              }
            }}
          />
        </div>
        <IconButton
          className={classes.submitButton}
          type="submit"
          style={{ marginLeft: '8px' }}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault()
            handleReplyComment()
          }}
        >
          <SendIcon />
        </IconButton>
      </div>
    </Paper>
  )
}

export default withStyles(styles)(CommentThread)
