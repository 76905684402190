import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'

import { Button, Divider, Card, Typography } from '@material-ui/core'

// Icons
import linedBriefcase from './../../../assets/dashboard/my-cases-lined-briefcase.png'
import blackPlus from './../../../assets/dashboard/black-plus.png'
import blackTag from './../../../assets/dashboard/black-tag.png'
import purpleTag from './../../../assets/dashboard/purple-tag.png'
import blueTag from './../../../assets/dashboard/blue-tag.png'
import seagreenTag from './../../../assets/dashboard/seagreen-tag.png'
import gridIcon from './../../../assets/dashboard/grid-view-icon.png'
import sharedIcon from './../../../assets/dashboard/shared-with-me-icon.png'

import SidebarHeader from './common/SidebarHeader'
import SystemUpdates from './common/SystemUpdates'

import { Routes } from '../../../constants'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100vh',
      maxHeight: '100%',
      flex: 1,
      flexDirection: 'column',
      position: 'absolute',
      backgroundColor: theme.palette.common.white,
      zIndex: 1,
    },

    // Sidebar
    sidebarContent: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      flexDirection: 'column',
      //justifyContent: 'space-around',
      alignItems: 'left',
      height: '100%',
      width: '100%',
    },

    // Sidebar Button Area
    buttonArea: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      //alignItems: 'space-around',
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'left',
      paddingTop: theme.spacing(4),
    },

    newButton: {
      display: 'flex',
      flex: 1,
      color: theme.palette.common.white,
      fontSize: 16,
      textTransform: 'none',
      backgroundColor: '#2C3F67', // navy
      borderRadius: 10,
      maxWidth: '10vw',
      width: '100%',
      minWidth: 132,
      maxHeight: 48,
      //whiteSpace: 'nowrap',
      marginTop: theme.spacing(6),
    },

    myButton: {
      display: 'flex',
      flex: 1,
      color: 'black',
      fontSize: 16,
      textTransform: 'none',
      backgroundColor: theme.palette.common.white, // white
      borderRadius: 10,
      width: '18vw',
      //width: '100%',
      maxHeight: 48,
      //minWidth: 132,
      justifyContent: 'left',
      marginTop: theme.spacing(6),
      paddingRight: theme.spacing(1),
      //whiteSpace: 'nowrap',
    },

    sharedButton: {
      display: 'flex',
      flex: 1,
      color: 'black',
      fontSize: 16,
      textTransform: 'none',
      backgroundColor: theme.palette.common.white, // white
      borderRadius: 10,
      width: '18vw',
      //width: '100%',
      maxHeight: 48,
      //minWidth: 132,
      justifyContent: 'left',
      marginTop: theme.spacing(2),
      paddingRight: theme.spacing(1),
      //whiteSpace: 'nowrap',
    },

    // Sidebar Updates Area
    divider: {
      marginTop: theme.spacing(6),
    },

    updatesTextArea: {
      padding: theme.spacing(3),
    },

    logo: {
      width: 'auto',
      maxHeight: 26,
      marginRight: theme.spacing(2),
    },

    tags: {
      marginTop: theme.spacing(4),
    },

    tag: {
      padding: theme.spacing(1),
      fontSize: 13,
    },

    tagIcon: {
      maxHeight: 20,
      paddingRight: theme.spacing(1),
    },

    tagHeaderCard: {
      backgroundColor: '#D5E6E3', //seagreen
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      borderRadius: 10,
      textAlign: 'center',
      textTransform: 'none',
      fontWeight: 600,
    },

    tagsHeaderText: {
      fontWeight: 600,
    },
  })

type Props = WithStyles<typeof styles> & {
  path: string
}

const DefaultSidebar: React.FC<Props> = (props: Props) => {
  const { classes, path } = props

  const [buttonText, setButtonText] = useState('+ New Case')
  const [buttonTo, setButtonTo] = useState(Routes.Create)
  const [MyCasesButton, setMyCasesButton] = useState(Routes.Dashboard)

  // First load effect
  useEffect(() => {
    switch (path) {
      case Routes.Case:
      case Routes.Create:
      case Routes.Upload:
        setButtonText('< Dashboard')
        setButtonTo(Routes.Dashboard)
        break
      default:
        setButtonText('+ New Case')
        setButtonTo(Routes.Create)
    }
  }, [path])

  return (
    <div className={classes.root}>
      {/* <SidebarHeader /> */}
      <div className={classes.sidebarContent}>
        <div className={classes.buttonArea}>
          {/* ADD CASES / Go Back button */}
          <div className={classes.buttonContainer}>
            <Button
              className={classes.newButton}
              variant="contained"
              component={Link}
              to={buttonTo}
            >
              {buttonText}
            </Button>
          </div>

          {/* MY CASES */}
          <div className={classes.buttonContainer}>
            <div>
              <Button
                className={classes.myButton}
                variant="outlined"
                component={Link}
                to={Routes.Dashboard}
              >
                <img className={classes.logo} src={linedBriefcase} alt="logo" />
                My Cases
              </Button>
            </div>
          </div>
          {/* SHARED WITH ME */}
          <div className={classes.buttonContainer}>
            <Button
              className={classes.sharedButton}
              variant="outlined"
              component={Link}
              to={Routes.Dashboard}
            >
              <img className={classes.logo} src={sharedIcon} alt="logo" />
              Shared with Me
            </Button>
          </div>
        </div>
        <Divider className={classes.divider} orientation="horizontal" />
        {/* TAGS */}
        <div className={classes.tags}>
          <Card className={classes.tagHeaderCard}>
            <Typography className={classes.tagsHeaderText}>Tags</Typography>
          </Card>
          <div>
            <Typography className={classes.tag}>
              <img className={classes.tagIcon} src={purpleTag} alt="purple tag" />
              In Litigation
            </Typography>
            <Typography className={classes.tag}>
              <img className={classes.tagIcon} src={blueTag} alt="blue tag" />
              In Review
            </Typography>
            <Typography className={classes.tag}>
              <img className={classes.tagIcon} src={blackTag} alt="black tag" />
              Closed
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(DefaultSidebar)
