import React, { useEffect, useMemo, useState } from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import TitleHeader from '../TitleHeader'
import SearchAndFilterBy from '../SearchAndFilterBy'
import CommentThread from './CommentThread'
import { v4 as uuid } from 'uuid'
import { CommentRefsByLineId, IdToCommentDict } from 'types'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 310,
      minWidth: 310,
    },

    heading: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%,',
    },

    headingTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#2c3f67',
      minHeight: 80,
      height: 80,
      width: '100%',
    },

    exhibitCardsContainer: {
      width: '100%',
      marginTop: 30,
    },
  })

const filterCommentIdsByRegexMatch = (
  commentThreadsByLineId: IdToCommentDict,
  commentIds: string[],
  regex: RegExp
) => commentIds.filter((lineId) => commentThreadsByLineId[lineId].message.match(regex))

type Props = WithStyles<typeof styles> & {
  commentThreadsByLineId: IdToCommentDict
}

const ViewComments = ({ classes, commentThreadsByLineId }: Props) => {
  // memoized so filtering by regex is a bit faster
  const originalCommentIds = useMemo(
    () => Object.keys(commentThreadsByLineId),
    [commentThreadsByLineId]
  )
  const [commentIds, setCommentIds] = useState(originalCommentIds)
  const [searchRegex, setSearchRegex] = useState<RegExp | null>(null)

  const filterAndSetCommentIds = (regex: RegExp | null) => {
    if (regex) {
      const filteredCommentIds = filterCommentIdsByRegexMatch(
        commentThreadsByLineId,
        originalCommentIds,
        regex
      )
      setCommentIds(filteredCommentIds)
    } else {
      setCommentIds(originalCommentIds)
    }
  }

  const handleNewSearchQuery = (query: string) => {
    const regex = query ? new RegExp(query.trim(), 'i') : null
    setSearchRegex(regex)
    filterAndSetCommentIds(regex)
  }

  useEffect(() => {
    filterAndSetCommentIds(searchRegex)
  }, [originalCommentIds])

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <TitleHeader title="View Comments" />
        <SearchAndFilterBy handleNewSearchQuery={handleNewSearchQuery} />
      </div>
      <div className={classes.exhibitCardsContainer}>
        {commentIds.map((lineId) => (
          <CommentThread commentThread={commentThreadsByLineId[lineId]} key={lineId} />
        ))}
      </div>
    </div>
  )
}

export default withStyles(styles)(ViewComments)
