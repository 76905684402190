import { ContentType } from './api/httpHelpers'
import { stripContentTypePrefix } from './utils'

export const SITE_TITLE_POSTFIX = '| Briefcase'

export const API_BASE_URL = process.env.REACT_APP_API

export const VERSION = 'v22.02.2.01'
export const ENV = process.env.REACT_APP_ENV

export enum Routes {
  Home = '/',
  Workspace = '/workspace',
  Dashboard = '/dashboard',
  Features = '/features',
  AboutUs = '/aboutUs',
  Technology = '/howItWorks',
  Case = '/case',
  Create = '/create',
  Upload = '/upload-transcript',
  Profile = '/profile',
  Login = '/login',
  SignUp = '/signup',
  ForgotPassword = '/forgot-password',
  ChangePassword = '/change-password',
}

export const toolbarHeight = 152

// TODO should we accept more than jpeg and png?
export const acceptedExhibitFileTypes = [ContentType.JPEG, ContentType.PNG]

export const readableAllowedExhibitFileTypes = stripContentTypePrefix(
  acceptedExhibitFileTypes,
  'image/'
)

export const badExhibitFilesAlertMessage = `Please only upload at least 1 exhibit file(s) of the type: ${readableAllowedExhibitFileTypes}`

export enum TabPanelType {
  Document,
  ExhibitProfile,
  CharacterProfile,
}

export const CHECK_JOB_STATUS_INTERVAL_MS = 60000
