import React from 'react'

import { ButtonBase, Grow } from '@material-ui/core'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import caseThumbnail from '../../../assets/images/case-thumbnail.png'
import { Link } from 'react-router-dom'
import { Routes } from '../../../constants'
import { CaseCardTestIds } from '../../../testing/test-ids'

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      maxWidth: '78vw',
      minWidth: '15vw',
      maxHeight: '40vh',
      minHeight: '12vh',
      borderWidth: 1,
      borderColor: '#2C3F67',
      borderStyle: 'solid',
      backgroundColor: '#2C3F67',
      marginRight: spacing(3),
      borderRadius: 10,
    },

    caseCardHeader: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: spacing(8),
      color: palette.common.white,
      marginTop: spacing(1),
      marginBottom: spacing(1),
      marginLeft: spacing(2),
      marginRight: spacing(2),
    },

    caseCardBody: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: palette.common.white,
    },

    caseCardFooter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: spacing(4.5),
      backgroundColor: '#2C3F67',
      color: palette.common.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },

    // Case Card Header
    caseName: {
      fontFamily: 'Heebo',
      fontSize: 16,
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '175px',
      maxWidth: '100%',
    },

    caseLastOpened: {
      fontFamily: 'Heebo',
      fontSize: 12,
    },

    caseGoTo: {
      fontFamily: 'Heebo',
      fontSize: 12,
      textTransform: 'uppercase',
      letterSpacing: spacing(0.25),
      padding: spacing(1),
    },

    // Case Card Body
    caseThumbnailContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '25%',
    },

    caseThumbnail: {
      width: '100%',
      height: 'auto',
    },

    clickableCard: {
      textDecoration: 'none',
      '&:hover': {
          cursor: 'pointer',
      },
    },
  })

type Props = WithStyles<typeof styles> & {
  caseName: string
  lastOpened: string
  transcriptId: number
  shouldShowCases: boolean
  index: number
}

const CaseCard: React.FC<Props> = ({
  classes,
  caseName,
  transcriptId,
  lastOpened,
  shouldShowCases,
  index,
}) => {
  return (
    <Grow in={shouldShowCases} appear timeout={100 * index} data-testid={CaseCardTestIds.root}>
      <Link to={`${Routes.Workspace}?id=${transcriptId}`} className={classes.clickableCard}>
        <div className={classes.root}>
          <div className={classes.caseCardHeader}>
            <Typography className={classes.caseName}>{caseName}</Typography>
            <Typography className={classes.caseLastOpened}>Last Opened:</Typography>
            <Typography className={classes.caseLastOpened}>
              {new Date(Date.parse(lastOpened)).toDateString()}
              {/* {new Date(Date.parse(lastOpened)).toLocaleDateString()} */}
            </Typography>
          </div>
          <div className={classes.caseCardBody}>
            <div className={classes.caseThumbnailContainer}>
              <img className={classes.caseThumbnail} src={caseThumbnail} alt="logo" />
            </div>
          </div>
          {/* <ButtonBase
            component={Link}
            to={`${Routes.Workspace}?id=${transcriptId}`}
            className={classes.caseCardFooter}
          >
            <Typography className={classes.caseGoTo}>Go to workspace</Typography>
          </ButtonBase> */}
          <div className={classes.caseCardFooter}>
            <Typography className={classes.caseGoTo}>Go to workspace</Typography>
          </div>
        </div>
      </Link>
    </Grow>
  )
}

export default withStyles(styles)(CaseCard)
