import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { CircularProgress, Grow, Divider, Typography, Popover } from '@material-ui/core'
import { useSuggestionsList, useDocument, getTagsByCaseId } from '../../../api'
import { getCasesByUserId } from '../../../api'
import { get, httpDelete, post } from '../../../api/httpHelpers'
import { SuccessResponse } from '../types'

import { CaseIdContext } from 'utils/context-providers'

const deleteCaseByCaseId = (caseId: number) =>
  httpDelete<SuccessResponse>(`cases/case/${caseId}`)
    .then((response) => response)
    .catch((err) => {
      throw new Error(err)
    })

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      padding: '3%',
      justifyContent: 'center',
      minWidth: '20vw',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
    },
    divider: {
      width: '100%',
    },
    progressContainer: {
      width: '100%',
      minWidth: 270,
      maxWidth: 900,
      minHeight: 80,
      maxHeight: 80,
      textAlign: 'center',
    },
    addIcon: {
      minWidth: 60,
      minHeight: 60,
      margin: 'auto',
    },
  })

type Props = WithStyles<typeof styles> & {
  caseName: string
  lastOpened: string
  transcriptId: number
  docketNumber: string
  courtName: string
  // shouldShowCases: boolean
  // index: number
}
const InfoPopup: React.FC<Props> = ({
  classes,
  caseName,
  lastOpened,
  docketNumber,
  courtName,
  transcriptId,
}: Props) => {
  const { pages, lines, documentMetadata, examinations, isLoadingDocument, error } = useDocument(
    `${transcriptId}`
  )

  const suggestedTags = useSuggestionsList(documentMetadata?.doc_id)
  //const tagsResponse = getTagsByCaseId(transcriptId, params)

  // Add a function to handle file deletion
  const handleDeleteFile = () => {
    console.log('Delete file pressed')
    let case_id = documentMetadata?.case_id
    console.log('case_id ', case_id)
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {caseName} Statistics
      </Typography>
      <Divider className={classes.divider} orientation="horizontal" />
      {!isLoadingDocument && !error ? (
        <Typography>{`${Object.keys(examinations ?? {}).length} examinations`}</Typography>
      ) : (
        ''
      )}
      <Divider className={classes.divider} orientation="horizontal" />

      {suggestedTags.isLoading ? (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.addIcon} />
        </div>
      ) : suggestedTags.error ? (
        <Typography>Oops! Something went wrong.</Typography>
      ) : (
        <div>
          <Typography variant="subtitle2">Tags</Typography>
          {suggestedTags.suggestions?.map((tagType) => (
            <Typography key={tagType.suggested_lines[0]}>
              {`${tagType.tag}: ${tagType.suggested_lines.length}`}
            </Typography>
          ))}
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
        <button
          onClick={() => {
            deleteCaseByCaseId(documentMetadata?.case_id ?? 0)
          }}
        >
          Delete
        </button>
      </div>
    </div>
  )
}
export default withStyles(styles)(InfoPopup)
