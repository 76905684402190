import React, { useEffect, useState } from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { TextField, Theme } from '@material-ui/core'
import TuneIcon from '@material-ui/icons/Tune'

const styles = (theme: Theme) =>
  createStyles({
    userAvatar: {
      width: 30,
      height: 30,
    },
    searchAndFilter: {
      position: 'relative',
    },

    searchBar: {
      width: '60vw',
      maxWidth: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: 'white',
      borderRadius: 10,
    },

    filterButton: {
      position: 'absolute',
      right: '20px',
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
    },
  })

type Props = WithStyles<typeof styles> & {
  openModal: boolean
  setOpenModal: Function
  searchTerm: string
  setSearchTerm: Function
}

export const SEARCH_DEBOUNCE_MS = 250
export const NUM_CASES = 50

const SearchBar: React.FC<Props> = (props: Props) => {
  const { classes, openModal, setOpenModal, searchTerm, setSearchTerm } = props
  const [query, setQuery] = useState<string>('')

  useEffect(() => {
    if (!openModal) {
      setQuery(searchTerm)
    }
  }, [openModal])

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    event.preventDefault()
    setQuery(event.target.value)
    setSearchTerm(event.target.value)
  }

  return (
    <div className={classes.searchAndFilter}>
      <div className={classes.searchBar}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search for Case..."
          size="small"
          value={query}
          onChange={handleSearchChange}
        />
      </div>
      <TuneIcon
        className={classes.filterButton}
        onClick={() => {
          setOpenModal(!openModal)
        }}
      />
    </div>
  )
}

export default withStyles(styles)(SearchBar)
