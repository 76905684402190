import React from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Input } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
    },

    replyField: {
      height: 30,
      border: '1px solid black',
      borderRadius: 17,
    },

    replyFieldPadding: {
      padding: '6px 12px',
    },
  })

type Props = WithStyles<typeof styles> &
  React.ComponentProps<typeof Input> & {
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    inputValue: string
    placeholder: string
    role?: string
  }

const RoundedInputField = ({
  classes,
  handleInputChange,
  inputValue,
  placeholder,
  role,
  ...inputProps
}: Props) => (
  <Input
    className={classes.root}
    classes={{ root: classes.replyField, input: classes.replyFieldPadding }}
    placeholder={placeholder}
    onChange={handleInputChange}
    value={inputValue}
    disableUnderline
    role={role}
    {...inputProps}
  />
)

export default withStyles(styles)(RoundedInputField)
