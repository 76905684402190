import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { CircularProgress, Grow, Divider, Typography, Popover } from '@material-ui/core'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
//import Paper from '@material-ui/core/Paper'
// import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
//import UseIconPopup from './UseIconPopup'

import { Routes } from '../../../constants'
import { CaseButtonTestIds } from '../../../testing/test-ids'

import infoIcon from '../../../assets/images/more-info-icon.png'
import briefcaseIconNavy from '../../../assets/images/navy-case-icon.png'
import { checkForJobId, removeJobId } from '../../../services/firebase/dal'
import { useJobStatus } from '../../../utils'
import InfoPopup from './InfoPopup'
// import Popup from './InfoPopup'
// import usePopup from './UseIconPopup'

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    case: {
      display: 'flex',
      flex: 1,
      //width: '100vw',
      maxWidth: '100%',
      paddingLeft: spacing(1),
      paddingTop: spacing(1),
    },

    root: {
      display: 'flex',
      flex: 1,
      width: '70vw',
      maxWidth: '100%',
      alignItems: 'left',
    },

    popup: {
      display: 'flex',
      flex: 1,
      width: '8vw',
      maxWidth: '100%',
      alignItems: 'left',
    },

    link: {
      textDecoration: 'none',
      color: palette.common.black,
    },

    // Icon:%
    caseIconContainer: {
      alignItems: 'left',
      marginRight: '5px',
    },

    caseIcon: {
      width: 'auto',
      height: 25,
    },

    infoIcon: {
      width: 'auto',
      height: 20,
    },

    titleContainer: {
      display: 'flex',
      alignSelf: 'left',
      width: '42%',
    },
    // Text
    caseName: {
      fontWeight: 500,
      fontSize: '0.8rem',
    },

    tags: {
      display: 'flex',
      padding: spacing(1),
    },

    courtDocketName: {
      display: 'flex',
      fontWeight: 400,
      fontSize: '0.8rem',
      alignSelf: 'left',
      width: '21%',
    },

    dateModified: {
      display: 'flex',
      fontWeight: 400,
      fontSize: '0.8rem',
      alignSelf: 'left',
      width: '16%',
    },

    jobProgressSpinner: {
      marginLeft: 'auto',
    },

    divider: {
      width: '97%',
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
  })

type Props = WithStyles<typeof styles> & {
  children: string
  transcriptId: number
  lastOpened: string
  docketNumber: string
  courtName: string
}

const CaseButton: React.FC<Props> = ({
  children,
  classes,
  transcriptId,
  lastOpened,
  courtName,
  docketNumber,
}: Props) => {
  const [jobId, setJobId] = useState('')
  const isJobDone = useJobStatus(jobId)

  useEffect(() => {
    checkForJobId(`${transcriptId}`, setJobId)
  }, [transcriptId])

  useEffect(() => {
    if (isJobDone) {
      removeJobId(`${transcriptId}`)
    }
  }, [isJobDone])

  return (
    <div>
      <div className={classes.case}>
        <Grow in={true} appear timeout={1000} data-testid={CaseButtonTestIds.root}>
          <Link to={`${Routes.Workspace}?id=${transcriptId}`} className={classes.link}>
            <div className={classes.root}>
              <div className={classes.titleContainer}>
                <div className={classes.caseIconContainer}>
                  <img className={classes.caseIcon} src={briefcaseIconNavy} alt="logo" />
                </div>
                <Typography className={classes.caseName}>{children}</Typography>
                {/* TODO: add tags here & how are we storing these tags? check with tanya about this */}
              </div>
              <Typography className={classes.courtDocketName}>{courtName}</Typography>
              <Typography className={classes.courtDocketName}>{docketNumber}</Typography>
              <Typography className={classes.dateModified}>
                {new Date(Date.parse(lastOpened)).toDateString()}{' '}
              </Typography>
            </div>
          </Link>
        </Grow>
        <div className={classes.popup}>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState: any) => (
              <div>
                <Button variant="text" {...bindTrigger(popupState)}>
                  <img className={classes.caseIcon} src={infoIcon} alt="logo" />
                </Button>
                <Popover
                  {...bindPopover(popupState)}
                  anchorReference="anchorPosition"
                  anchorPosition={{ top: 200, left: 400 }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                >
                  <InfoPopup
                    caseName={children}
                    lastOpened={lastOpened}
                    transcriptId={transcriptId}
                    docketNumber={docketNumber}
                    courtName={courtName}
                  />
                </Popover>
              </div>
            )}
          </PopupState>
        </div>
      </div>
      <Divider className={classes.divider} orientation="horizontal" />
      {/* </div> */}
    </div>
  )
}

export default withStyles(styles)(CaseButton)
