import React, { useEffect, useState } from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Drawer, IconButton, Tab, Tabs } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import MapIcon from '@material-ui/icons/Map'
import GroupIcon from '@material-ui/icons/Group'
import ForumIcon from '@material-ui/icons/Forum'
import AssistantIcon from '@material-ui/icons/Assistant'
import clsx from 'clsx'
import MiniMap from './mini-map'
import ViewComments from './view-comments'
import CharacterProfile from './character-profile'
import ExhibitProfiles from './exhibit-profile/ExhibitProfiles'
import DocumentChat from './document-chat/DocumentChat'
import DrawerPanel from './DrawerPanel'
import {
  IdToCommentDict,
  ExhibitData,
  WorkspaceTab,
  SuggestionsByLineId,
  MinimapData,
} from '../../../types'
import { toolbarHeight } from '../../../constants'
import { getExhibitsByCaseId } from '../../../api'
import ViewTagSuggestions from './tags'
import LabelIcon from '@material-ui/icons/Label'
import { useCaseId } from '../../../utils'

const drawerWidth = 310

const iconToComponentMap = [
  {
    Icon: MapIcon,
    label: 'Mini Map',
  },
  {
    Icon: ForumIcon,
    label: 'Comments',
  },
  { Icon: GroupIcon, label: 'Characters' },
  { Icon: PhotoLibraryIcon, label: 'Exhibits' },
  { Icon: LabelIcon, label: 'Tag Suggestions' },
  { Icon: AssistantIcon, label: 'Document Chat' },

  // TODO
  // { Icon: CollectionsBookmarkIcon, label: 'View Bookmarks' },
  // { Icon: CloudIcon, label: 'View Documents' },
]

const fetchExhibits = async (
  caseId: string,
  setSidebarExhibits: React.Dispatch<React.SetStateAction<ExhibitData[]>>
) => {
  const queryParams = {
    offset: 0,
    limit: 100,
  }

  const exhibits = await getExhibitsByCaseId(caseId, queryParams).catch((e) =>
    console.error(e.message)
  )
  if (exhibits) setSidebarExhibits(exhibits)
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDrawer-paperAnchorRight': {
        right: 16,
      },
    },

    drawerTab: {
      minWidth: 100,
      width: 100,
    },

    drawer: {
      top: toolbarHeight,
      height: `calc(100vh - ${toolbarHeight}px)`,
    },

    tabLabel: {
      fontSize: 'small',
    },

    tabColoring: {
      color: 'white',
    },

    // handles the transition when drawer with sidebar content is open/closed
    tabContainer: {
      display: 'flex',
      flexDirection: 'column',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 100,
      background: '#50718F',
      height: 'inherit',
    },

    tabContainerShifted: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: 425,
    },

    openCloseDrawerButton: {
      marginTop: 'auto',
      marginBottom: 16,
    },

    tabs: {
      height: '100%',
    },

    contentContainer: {
      width: drawerWidth,
    },

    commentsContainer: {
      minWidth: 100,
    },
  })

type Props = WithStyles<typeof styles> & {
  commentThreadsByLineId: IdToCommentDict
  examinationHeaderData: MinimapData
  sidebarExhibits: ExhibitData[]
  setSidebarExhibits: React.Dispatch<React.SetStateAction<ExhibitData[]>>
  viewTab: (tab: WorkspaceTab) => void
}

const Sidebar = ({
  classes,
  commentThreadsByLineId,
  examinationHeaderData,
  sidebarExhibits,
  setSidebarExhibits,
  viewTab,
}: Props) => {
  const caseId = useCaseId()
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const [tabsValue, setTabsValue] = useState(0)

  const handleTabsChange = (event: React.ChangeEvent<{}>, newTabsValue: number) => {
    setTabsValue(newTabsValue)

    if (newTabsValue === tabsValue && isDrawerOpen) {
      setIsDrawerOpen(false)
    } else {
      setIsDrawerOpen(true)
    }
  }

  useEffect(() => {
    if (caseId) {
      fetchExhibits(caseId, setSidebarExhibits)
    }
  }, [caseId])

  return (
    <Drawer
      className={classes.root}
      anchor="right"
      open={true} // should be always open
      variant="persistent"
      PaperProps={{ className: classes.drawer }}
    >
      <div
        className={clsx(classes.tabContainer, {
          [classes.tabContainerShifted]: isDrawerOpen,
        })}
      >
        <Tabs
          orientation="vertical"
          textColor="primary"
          value={tabsValue}
          onChange={handleTabsChange}
        >
          {iconToComponentMap.map(({ Icon, label }, index) => (
            <Tab
              label={<span className={clsx(classes.tabLabel, classes.tabColoring)}>{label}</span>}
              icon={<Icon className={classes.tabColoring} />}
              classes={{ root: classes.drawerTab }}
              key={label}
            />
          ))}
        </Tabs>
        <IconButton
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          classes={{ root: clsx(classes.drawerTab, classes.openCloseDrawerButton) }}
        >
          {isDrawerOpen ? (
            <NavigateNextIcon fontSize="large" className={classes.tabColoring} />
          ) : (
            <NavigateBeforeIcon fontSize="large" className={classes.tabColoring} />
          )}
        </IconButton>
      </div>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        variant="persistent"
        PaperProps={{ className: classes.drawer }}
      >
        <div className={classes.contentContainer}>
          <DrawerPanel value={tabsValue} index={0}>
            <MiniMap examinationHeaderData={examinationHeaderData} />
          </DrawerPanel>
          <DrawerPanel index={tabsValue} value={1}>
            <ViewComments commentThreadsByLineId={commentThreadsByLineId} />
          </DrawerPanel>
          <DrawerPanel index={tabsValue} value={2}>
            <CharacterProfile />
          </DrawerPanel>
          <DrawerPanel index={tabsValue} value={3}>
            <ExhibitProfiles sidebarExhibits={sidebarExhibits} viewTab={viewTab} />
          </DrawerPanel>
          <DrawerPanel index={tabsValue} value={4}>
            <ViewTagSuggestions />
          </DrawerPanel>
          <DrawerPanel index={tabsValue} value={5}>
            <DocumentChat caseId={caseId} />
          </DrawerPanel>
        </div>
      </Drawer>
    </Drawer>
  )
}

export default withStyles(styles)(Sidebar)
