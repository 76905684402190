import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'

import React, { useState, useEffect } from 'react'
import { Filters } from 'types'

const styles = () =>
  createStyles({
    modalContainer: {
      width: '45vw',
      borderRadius: '12px',
      backgroundColor: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      display: 'flex',
      flexDirection: 'column',
      padding: '12px',
    },
    title: {
      display: 'inline-block',
      textAlign: 'center',
      marginTop: '5px',
    },
    titleCloseBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    topRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    btn: {
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: '25px',
      cursor: 'pointer',
      justifySelf: 'flex-end',
    },
    body: {
      flex: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'left',
      fontSize: '1rem',
      textAlign: 'center',
    },
    searchTermRow: {
      display: 'flex',
      padding: '5px',
      justifyContent: 'space-between',
    },
    inputRow: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'baseline',
      fontWeight: 'bold',
      padding: '5px',
      textAlign: 'left',
      width: '100%',

      '& p': {
        fontWeight: 'normal',
        padding: '0 5px 0 5px',
      },

      '& > div': {
        flex: 1,
        display: 'flex',
        // flexDirection: 'column',
      },
    
      '& > .label': {
        width: '100%',
        textAlign: 'left', 
      },
    
      '& > .textfield': {
        width: '100%',
        flexGrow: 2, 
      },

    },
    footer: {
      flex: '20%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    applyBtn: {
      width: '150px',
      height: '45px',
      margin: '10px',
      border: 'none',
      backgroundColor: 'cornflowerblue',
      color: 'white',
      borderRadius: '8px',
      fontSize: '20px',
      cursor: 'pointer',
    },
    cancelBtn: {
      backgroundColor: 'gray',
    },
  })

type Props = WithStyles<typeof styles> & {
  searchTerm: string
  openModal: boolean
  closeModal: Function
  prevFilters: Filters
  updateFilters: Function
}

const FiltersModal: React.FC<Props> = (props: Props) => {
  const { classes, searchTerm, openModal, closeModal, prevFilters, updateFilters } = props

  const [filters, setFilters] = useState<Filters>({
    query: searchTerm,
    docket_number_start: '',
    docket_number_end: '',
  })

  const handleCourtChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    event.preventDefault()
    setFilters({ ...filters, court_name: event.target.value })
  }

  const handleDocketStartChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    event.preventDefault()
    setFilters({ ...filters, docket_number_start: event.target.value })
  }

  const handleDocketEndChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    event.preventDefault()
    setFilters({ ...filters, docket_number_end: event.target.value })
  }

  const handleDateFiledStartChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    event.preventDefault()
    const dateObj = new Date(event.target.value)
    const date_filed_start = dateObj.toUTCString()
    setFilters({ ...filters, date_filed_start: date_filed_start })
  }

  const handleDateFiledEndChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    event.preventDefault()
    const dateObj = new Date(event.target.value)
    const date_filed_end = dateObj.toUTCString()
    setFilters({ ...filters, date_filed_end: date_filed_end })
  }

  const handleLastOpenedStartChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    event.preventDefault()
    const dateObj = new Date(event.target.value)
    const last_opened_start = dateObj.toUTCString()
    setFilters({ ...filters, last_opened_start: last_opened_start })
  }

  const handleLastOpenedEndChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    event.preventDefault()
    const dateObj = new Date(event.target.value)
    const last_opened_end = dateObj.toUTCString()
    setFilters({ ...filters, last_opened_end: last_opened_end })
  }

  useEffect(() => {
    if (openModal) {
      setFilters(prevFilters)
    }
  }, [openModal])

  const handleClear = () => {
    setFilters({ query: '' })
    updateFilters({ query: '' })
    closeModal(false)
  }

  useEffect(() => {
    const handleGlobalKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === 'Escape') {
        closeModal(false)
      }
    };
    document.addEventListener('keydown', handleGlobalKeyPress);

    return
  }, []);

  return (
    <div className={classes.modalContainer}>
      <div className={classes.titleCloseBtn}>
        <button
          className={classes.btn}
          onClick={() => { closeModal(false)}}
        >
            <CancelIcon />
        </button>
      </div>
      <div className={classes.body}>
        <div className={classes.inputRow}>
          <div className={classes.label}>Docket Number:</div>
          <TextField
            value={filters.docket_number_start}
            label="Docket Number Start"
            onChange={handleDocketStartChange}
          />
          <p>to</p>
          <TextField
            value={filters.docket_number_end}
            label="Docket Number End"
            onChange={handleDocketEndChange}
          />
        </div>
        <div className={classes.inputRow}>
        <div className={classes.label}>Court Name:</div>
          <TextField
            value={filters.court_name}
            label="Court Name"
            onChange={handleCourtChange}
          />
        </div>
        <div className={classes.inputRow}>
          <div className={classes.label}>Trial Date:</div>
          <TextField
            type="date"
            value={
              filters.date_filed_start != undefined
                ? new Date(filters.date_filed_start).toISOString().slice(0, 10)
                : undefined
            }
            onChange={handleDateFiledStartChange}
          />
          <p>to</p>
          <TextField
            type="date"
            value={
              filters.date_filed_end != undefined
                ? new Date(filters.date_filed_end).toISOString().slice(0, 10)
                : undefined
            }
            onChange={handleDateFiledEndChange}
          />
        </div>
        <div className={classes.inputRow}>
          <div className={classes.label}>Date Accessed:</div>
          <TextField
            type="date"
            value={
              filters.last_opened_start != undefined
                ? new Date(filters.last_opened_start).toISOString().slice(0, 10)
                : undefined
            }
            onChange={handleLastOpenedStartChange}
          />
          <p>to</p>
          <TextField
            type="date"
            value={
              filters.last_opened_end != undefined
                ? new Date(filters.last_opened_end).toISOString().slice(0, 10)
                : undefined
            }
            onChange={handleLastOpenedEndChange}
          />
        </div>
      </div>
      <div className={classes.footer}>
        <button className={`${classes.applyBtn} ${classes.cancelBtn}`} onClick={handleClear}>
          Reset
        </button>
        <button className={classes.applyBtn} onClick={() => updateFilters({ ...filters, query: searchTerm })}>
          Search
        </button>
      </div>
    </div>
  )
}

export default withStyles(styles)(FiltersModal)
