import React from 'react'
import { WorkspaceTab } from '../../types'
import { TabPanelType } from '../../constants'

type Props = {
  tab: WorkspaceTab
  document: JSX.Element
  exhibitProfile: JSX.Element
  characterProfile: JSX.Element
}

const TabPanelContent = ({ tab, document, exhibitProfile, characterProfile }: Props) => {
  switch (tab.type) {
    case TabPanelType.Document:
      return document
    case TabPanelType.ExhibitProfile:
      return exhibitProfile
    case TabPanelType.CharacterProfile:
      return characterProfile
  }
}

export default TabPanelContent
