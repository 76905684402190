import React from 'react'
import forneyImage from '../../assets/aboutUs/Forney.png'
import tanyaImage from '../../assets/aboutUs/Nobal_Tanya_Headshot.jpg'
import jenniferImage from '../../assets/aboutUs/JenniferSiao.jpg'
import mayaImage from '../../assets/aboutUs/MayaEpps.jpg'
import saadImage from '../../assets/aboutUs/saad.jpg'
import evanImage from '../../assets/aboutUs/evan_square.jpg'
import lucilleImage from '../../assets/aboutUs/LucilleNjoo.jpg'
import mannyImage from '../../assets/aboutUs/MannyBarreto.jpg'
import cooperImage from '../../assets/aboutUs/Cooper.jpg'
import jennaImage from '../../assets/aboutUs/Jenna.jpg'
import tylerImage from '../../assets/aboutUs/tyler.jpg'
import zachImage from '../../assets/aboutUs/zach_irving.jpg'
import amandaImage from '../../assets/aboutUs/amanda_marques.jpg'
import meganImage from '../../assets/aboutUs/Megan.jpg'
import defaultImage from '../../assets/aboutUs/default_profile.jpg'
import masaoImage from '../../assets/aboutUs/masao.jpg'
import HomepageBar from 'pages/homepage bar'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { theme } from 'App'

const styles = (theme: Theme) =>
  createStyles({
    aboutUs: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: 'white',
    },
    teamDiv: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      flexGrow: 1,
      columnGap: '2rem',
      midWidth: 1200,
    },
    imageGrey: {
      width: '200px',
      height: '200px',
      objectFit: 'cover',
      borderRadius: '25px',
      boxShadow: '5px 5px 5px 5px #808080',
    },
    imageNavy: {
      width: '200px',
      height: '200px',
      objectFit: 'cover',
      borderRadius: '25px',
      boxShadow: '5px 5px 5px 5px #151f33',
    },
    label: {
      marginTop: '20px',
      fontSize: 28,
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
    yearLabel: {
      fontSize: '3vmin',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: theme.spacing(4),
      width: '100%',
      marginTop: theme.spacing(4),
    },
    person: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '5px',
      maxWidth: '200px',
    },
    subLabel: {
      fontSize: 16,
      textAlign: 'center',
      marginBottom: theme.spacing(4.5),
    },
    header: {
      fontSize: '7vmin',
      fontWeight: 800,
      textAlign: 'center',
      marginBottom: theme.spacing(1),
      color: 'black',
    },
    headerSubLabel: {
      maxWidth: 540,
      fontSize: '2vmin',
      marginBottom: theme.spacing(2),
      color: 'black',
      justifyContent: 'center',
    },
    wrapper: {
      display: 'grid',
      midWidth: 1200,
      width: '100%',
      marginRight: 'auto',
      marginLeft: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#2C3F67',
      color: 'white',
    },
    center: {
      position: 'relative',
      display: 'flex',
      maxWidth: 1030,
      marginRight: 'auto',
      marginLeft: 'auto',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },

    featuresTextLabel: {
      fontSize: '3vmin',
      lineHeight: '150%',
      fontWeight: 700,
      marginTop: theme.spacing(6),
    },
  })

type Props = WithStyles<typeof styles>

const AboutUs = (props: Props) => {
  const { classes } = props
  // TODO: Should we add different color backgrounds for each year?
  return (
    <div>
      <HomepageBar />
      <div className={classes.aboutUs}>
        <div
          className={classes.wrapper}
          style={{ display: 'flex', backgroundColor: '#D5E6E3', color: 'black' }}
        >
          <div className={classes.center}>
            <div className={classes.featuresTextLabel}>People</div>
            <h1 className={classes.header}>The Briefcase Team</h1>
            <p className={classes.headerSubLabel}>
              Meet the amazing people that have worked on Briefcase since the project started in
              2019
            </p>
          </div>
        </div>
        {/* 2022-2023 Team */}
        <div className={classes.wrapper}>
          <div className={classes.yearLabel}>2022–2023</div>
          <div className={classes.teamDiv}>
            <div className={classes.person}>
              <a href="https://forns.lmu.build/about.html">
                <img src={forneyImage} className={classes.imageNavy} />
              </a>
              <div className={classes.label}>Andrew Forney</div>
              <div className={classes.subLabel}>Faculty Advisor</div>
            </div>

            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/tanya-nobal/">
                <img src={tanyaImage} className={classes.imageNavy} />
              </a>
              <div className={classes.label}>Tanya Nobal</div>
              <div className={classes.subLabel}>Back End</div>
            </div>

            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/jennifersiaoli/">
                <img src={jenniferImage} className={classes.imageNavy} />
              </a>
              <div className={classes.label}>Jennifer Siao </div>
              <div className={classes.subLabel}>Front End</div>
            </div>

            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/maya-epps/">
                <img src={mayaImage} className={classes.imageNavy} />
              </a>
              <div className={classes.label}>Maya Epps</div>
              <div className={classes.subLabel}>AI/NLP</div>
            </div>

            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/the-saad-salman/">
                <img src={saadImage} className={classes.imageNavy} />
              </a>
              <div className={classes.label}>Saad Salman</div>
              <div className={classes.subLabel}>Project Manager</div>
            </div>

            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/evan-sciancalepore-08660a1b4/">
                <img src={evanImage} className={classes.imageNavy} />
              </a>
              <div className={classes.label}>Evan Sciancalepore</div>
              <div className={classes.subLabel}>Front End</div>
            </div>
          </div>
        </div>
        {/* 2021-2022 Team */}
        {/* <hr className={classes.divider}></hr> */}
        <div className={classes.wrapper} style={{ backgroundColor: '#d9d9d9', color: 'black' }}>
          <div className={classes.yearLabel}>2021–2022</div>
          <div className={classes.teamDiv}>
            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/zach-irving/">
                <img src={zachImage} className={classes.imageGrey} />
              </a>
              <div className={classes.label}>Zach Irving</div>
              <div className={classes.subLabel}>Full Stack</div>
            </div>

            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/amandacmarques/">
                <img src={amandaImage} className={classes.imageGrey} />
              </a>
              <div className={classes.label}>Amanda Marques</div>
              <div className={classes.subLabel}>Front End</div>
            </div>

            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/megan-m-richardson/">
                <img src={meganImage} className={classes.imageGrey} />
              </a>
              <div className={classes.label}>Megan Richardson</div>
              <div className={classes.subLabel}>Full Stack</div>
            </div>
          </div>
        </div>
        {/* 2020-2021 Team */}
        {/* <hr className={classes.divider}></hr> */}
        <div className={classes.wrapper}>
          <div className={classes.yearLabel}>2020–2021</div>
          <div className={classes.teamDiv}>
            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/lucille-n-277253123/">
                <img src={lucilleImage} className={classes.imageNavy} />
              </a>
              <div className={classes.label}>Lucille Njoo</div>
              <div className={classes.subLabel}>AI/NLP</div>
            </div>

            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/manny-barreto/">
                <img src={mannyImage} className={classes.imageNavy} />
              </a>
              <div className={classes.label}>Manny Barreto</div>
              <div className={classes.subLabel}>Full Stack</div>
            </div>

            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/larhette/">
                <img src={cooperImage} className={classes.imageNavy} />
              </a>
              <div className={classes.label}>Cooper LaRhette</div>
              <div className={classes.subLabel}>Full Stack</div>
            </div>

            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/jenna-berlinberg/">
                <img src={jennaImage} className={classes.imageNavy} />
              </a>
              <div className={classes.label}>Jenna Berlinberg</div>
              <div className={classes.subLabel}>Back End & AI</div>
            </div>

            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/tylerilunga/">
                <img src={tylerImage} className={classes.imageNavy} />
              </a>
              <div className={classes.label}>Tyler Ilunga</div>
              <div className={classes.subLabel}>Full Stack</div>
            </div>

            <div className={classes.person}>
              <a href="">
                <img src={defaultImage} className={classes.imageNavy} />
              </a>
              <div className={classes.label}>Khalid Al Ghunaim</div>
              <div className={classes.subLabel}>Full Stack</div>
            </div>

            <div className={classes.person}>
              <a href="https://www.linkedin.com/in/masaok/">
                <img src={masaoImage} className={classes.imageNavy} />
              </a>
              <div className={classes.label}>Masao Kitamura</div>
              <div className={classes.subLabel}>Full Stack</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(AboutUs)
