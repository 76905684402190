import React, { useState } from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Card, CardActionArea, Divider, Typography } from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import clsx from 'clsx'
import InfoIcon from '@material-ui/icons/Info'
import { ExaminationData } from 'types'
import { useScrollTo } from '../../../../utils'
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript'
import { isNull } from 'util'
// import IconPopup from 'react-icon-popup'
// import { KeyboardArrowDown } from '@material-ui/icons'

// start height for the header info, used to set trail dot height, margin for info container, etc
const infoStartHeight = 15

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
    },

    cardOverride: {
      boxShadow: 'none',
    },

    sectionTitle: {
      fontWeight: 700,
    },

    trailContainer: {
      display: 'flex',
      justifyContent: 'center',
      minWidth: 80,
      width: 80,
    },

    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: infoStartHeight,
      marginBottom: 35,
      paddingRight: 15,
      width: '100%',
    },

    dot: {
      position: 'absolute',
      transform: `translateY(${infoStartHeight + 1}px)`,
    },

    trail: {
      background: 'grey',
      width: 5,
      height: '100%',
    },

    firstCardTrail: {
      alignSelf: 'flex-end',
      height: '70%',
    },

    lastCardTrail: {
      height: 20,
    },

    openCardButton: {
      height: 35,
    },

    summaryContainer: {
      width: '100%',
    },

    popup: {
      // justifyContent: 'center',
      left: '-230px',
    },
  })

type Props = WithStyles<typeof styles> & {
  headerTitle: string
  examination?: ExaminationData
  startPageNumber?: number
  endPageNumber?: number
  headerRef: React.RefObject<HTMLDivElement>
  isFirstCard?: boolean
  isLastCard?: boolean
  summary?: string
}

const ExaminationHeaderCard = ({
  classes,
  headerTitle,
  examination,
  startPageNumber,
  endPageNumber, // no good way to determine the end of an exmination, current solution is to use the start of the next examinations
  isFirstCard,
  isLastCard,
  summary,
  headerRef,
}: Props) => {
  const { scrollToRow } = useScrollTo()
  const [popupVisible, setPopupVisible] = useState(false)
  const [summaryVisible, setSummaryVisible] = useState(false)

  const handleClick = () => {
    if (examination) scrollToRow(examination.start_line_id)
  }

  const handleSummaryClick = () => {
    setSummaryVisible(!summaryVisible)
  }

  return (
    <div style={{ display: 'flex' }}>
      <Card classes={{ root: classes.cardOverride }}>
        <CardActionArea>
          <div className={classes.root}>
            <div className={classes.trailContainer}>
              <FiberManualRecordIcon
                className={classes.dot}
                style={{ color: isFirstCard ? '#53B89E' : '#7239BE' }}
              />
              <div
                className={clsx(classes.trail, {
                  [classes.firstCardTrail]: isFirstCard,
                  [classes.lastCardTrail]: isLastCard,
                })}
              />
            </div>
            <div className={classes.infoContainer}>
              <Typography
                variant="subtitle1"
                className={classes.sectionTitle}
                onClick={handleClick}
              >
                {headerTitle}
                {examination?.witness_id ? ` OF ${examination.witness_id.toUpperCase()}` : ''}
              </Typography>
              <Divider />
              <Typography variant="caption" onClick={handleClick}>
                {startPageNumber && endPageNumber
                  ? `Pages ${startPageNumber} to ${endPageNumber}`
                  : startPageNumber
                  ? `Starts on page ${startPageNumber}`
                  : 'Page Unknown'}
              </Typography>
              <div
                onClick={handleSummaryClick}
                style={{ paddingTop: '5px', display: 'flex', flexDirection: 'column' }}
              >
                <Typography variant="caption">
                  {summaryVisible
                    ? examination?.summary
                      ? `${examination?.summary}`
                      : `Summary Not Found`
                    : ''}
                </Typography>
                <Typography variant="button" style={{ fontSize: '12px' }}>
                  {summaryVisible ? 'Hide summary' : 'Show summary'}
                </Typography>
              </div>
            </div>
          </div>
        </CardActionArea>
      </Card>
    </div>
  )
}

export default withStyles(styles)(ExaminationHeaderCard)
