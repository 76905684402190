import React, { useEffect, useState } from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Divider, TextField, Typography } from '@material-ui/core'
import {
  loadNotesOnce,
  listenForNotes,
  updateNotes,
} from '../../services/firebase/dal/profiles-notes'
import { Note } from '../../types'
import { NotesTestIds } from '../../testing/test-ids/document'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 32,
    },

    title: {
      marginBottom: 8,
    },

    notes: {
      '& .MuiFormLabel-root.Mui-focused': {
        color: 'grey',
      },
    },

    noBorderInput: {
      border: 'none',
    },
  })

type Props = WithStyles<typeof styles> & {
  id: string
}

const Notes = ({ classes, id }: Props) => {
  const [notes, setNotes] = useState<Note | null>({ content: '' })

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes({ content: event.target.value })
  }

  const persistNotes = () => {
    if (notes) updateNotes(id, notes.content)
  }

  useEffect(() => {
    loadNotesOnce(id, setNotes)
    const notesRef = listenForNotes(id, setNotes)
    return () => {
      notesRef.off()
    }
  }, [])

  return (
    <div className={classes.root} data-testid={NotesTestIds.Root}>
      <Typography variant="h4" className={classes.title}>
        Notes
      </Typography>

      {/*
         TODO maybe have something here that persists the notes when they're typing every 30s
         or so, just to minimize data loss if something goes wrong
      */}
      <TextField
        className={classes.notes}
        onChange={handleNotesChange}
        onBlur={persistNotes}
        label={notes?.content ? '' : 'Enter Exhibit Notes or Description'}
        InputLabelProps={{ shrink: false }}
        multiline
        fullWidth
        rows={4}
        variant="outlined"
        value={notes?.content ?? ''}
        data-testid={NotesTestIds.NotesForm}
      />
    </div>
  )
}

export default withStyles(styles)(Notes)
