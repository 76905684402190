import {
  ApiSignupForm,
  ForgotPasswordForm,
  HttpResponse,
  LoginForm,
  SuccessResponse,
  UserData,
} from '../types'
import { fetcher, get, post } from './httpHelpers'
import { auth } from '../services/firebase'
import useSWR from 'swr'

export const useUserFromId = (userId: string | number) => {
  const { data, error } = useSWR(`users/user/${userId}`, fetcher)

  return { user: data?.parsedBody?.['user'], isLoading: !error && !data, error }
}

export const useUserFromSession = (errorCallback: () => void) => {
  const { data, error } = useSWR<HttpResponse<UserData>, Error>('users/user/session', fetcher)
  if (error?.message === 'UNAUTHORIZED') errorCallback()

  return { user: data?.parsedBody?.['user'], isLoading: !error && !data, error }
}

export const signUpUser = async (form: ApiSignupForm) =>
  post<SuccessResponse>('auth/signup', form).then((response) => response.parsedBody)

export const forgotPassword = async (form: ForgotPasswordForm) =>
  post<SuccessResponse>('auth/forgotpassword', form).then((response) => response.parsedBody)

// client side auth default persisted "forever":
// https://firebase.google.com/docs/auth/web/auth-state-persistence#supported_types_of_auth_state_persistence
export const loginToFirebase = async (form: LoginForm) =>
  auth.signInWithEmailAndPassword(form.email, form.password)

// backend auth persisted for 5 days
export const loginToBackend = async (form: LoginForm) =>
  post<UserData>('auth/login', form).then((response) => response.parsedBody!['user'])

export const logOutOfSession = () =>
  post<SuccessResponse>('auth/logout').then((response) => response.parsedBody)

// must have logged in within cookies expiration date (last 5 days) for this to work
export const loginUserBySessionCookie = () =>
  get<UserData>('users/user/session').then((response) => response.parsedBody!['user'])
