import React from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Button, IconButton, Tooltip, Typography } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import { LineData } from '../../../../types'
import { TagLineInfoTestIds } from '../../../../testing/test-ids/workspace'
import { addTagForRow } from '../../../../services/firebase/dal'
import { useAlert, useScrollTo } from '../../../../utils'
import { removeSuggestedLineId } from '../../../../api'

const styles = () =>
  createStyles({
    root: {},

    divider: {
      margin: '16px 0px',
    },

    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 8,
    },

    lineInfoLineNumber: {
      fontWeight: 500,
      flexGrow: 1,
    },

    viewLineButton: {
      color: 'white',
      background: '#50718F',
      height: 26,
      marginRight: 8,
    },
  })

type Props = WithStyles<typeof styles> & {
  line: LineData
  suggestedTag: string
}

const SuggestionInfoCard = ({ classes, line, suggestedTag }: Props) => {
  const { addAlert } = useAlert()!
  const { scrollToRow } = useScrollTo()

  const handleViewLineClick = () => {
    scrollToRow(line.line_id)
  }

  const handleAddTagClick = () => {
    if (line) {
      const { case_id, quote_id, raw, doc_id, line_id } = line
      removeSuggestedLineId(suggestedTag, doc_id, line_id, addAlert).then(() =>
        addTagForRow(line_id, case_id, quote_id, suggestedTag, raw)
      )
    }
  }

  return (
    <div className={classes.root} data-testid={TagLineInfoTestIds.Root}>
      <hr className={classes.divider} />
      <div className={classes.header}>
        <Typography className={classes.lineInfoLineNumber} variant="h6">
          Line {line.line_number}
        </Typography>
        <Button
          className={classes.viewLineButton}
          variant="contained"
          onClick={() => handleViewLineClick()}
          data-testid={TagLineInfoTestIds.ViewLineButton}
        >
          View Line
        </Button>
        <Tooltip title="Tag Line" placement="top">
          <IconButton
            onClick={() => handleAddTagClick()}
            data-testid={TagLineInfoTestIds.AddTagButton}
          >
            <AddCircle fontSize="medium" />
          </IconButton>
        </Tooltip>
      </div>
      <Typography variant="body1">"{line.raw}"</Typography>
    </div>
  )
}

export default withStyles(styles)(SuggestionInfoCard)
