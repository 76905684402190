import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { Button, Typography, CircularProgress } from '@material-ui/core'

// Constants
import { SITE_TITLE_POSTFIX, Routes } from '../../../constants'
import { useAlert, useAuthState } from '../../../utils'
import UploadTrialTranscript from './UploadTrialTranscript'
import CaseInfoInput from './CaseInfoInput'
import { postNewCase, postTranscript } from '../../../api'
import { CreateNewCaseForm } from '../../../types'
import { updateJobIds } from '../../../services/firebase/dal'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    contentTitleDivider: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(7.5),
    },

    createCaseContainer: {
      display: 'flex',
      marginTop: theme.spacing(5),
    },

    circularProgress: {
      marginTop: theme.spacing(10),
    },

    submitButton: {
      color: theme.palette.common.white,
      backgroundColor: '#50718F', // gray
      whiteSpace: 'nowrap',
      width: '100%',
    },
  })

type Props = WithStyles<typeof styles>

const CreateNewCase: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const { addAlert, dismissCurrentAlert } = useAlert()!
  const history = useHistory()
  const { user } = useAuthState()

  const [userFile, setUserFile] = useState<File | null>(null)
  const [form, setForm] = useState<CreateNewCaseForm>({
    court_name: '',
    date_filed: '',
    docket_number: '',
    title: '',
    dialogue_start_page: 1,
  })
  const [processing, setProcessing] = useState(false)

  const handleSubmitClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (!(Object.values(form).every((value) => value) && userFile)) {
      dismissCurrentAlert()
      addAlert('Missing case details or file upload.')
      return
    }

    setProcessing(true)

    const createNewCase = async () => {
      const { dialogue_start_page, ...caseDetails } = form
      await postNewCase(caseDetails, parseInt(user.firmId))
        .then((newCase) => uploadFile(newCase.id, form.dialogue_start_page))
        .catch((err) => {
          setProcessing(false)
          addAlert(err.message)
        })
    }

    createNewCase()
  }

  const uploadFile = async (caseId: number, dialogue_start_page: number) => {
    const formData = new FormData()
    formData.append('file', userFile!)
    formData.append('case_id', `${caseId}`)
    formData.append('dialogue_start_page', `${dialogue_start_page}`)

    const uploadTranscript = async () => {
      postTranscript(formData)
        .then((jobId) => {
          addAlert('Your file has been uploaded and is processing!')
          updateJobIds({ [caseId]: jobId })
          history.push(Routes.Dashboard)
        })
        .catch((err) => {
          setProcessing(false)
          addAlert(err.message)
        })
    }

    if (userFile && formData.get('case_id') !== 'undefined') {
      uploadTranscript()
    } else {
      setProcessing(false)
      addAlert('File not found')
    }
  }

  return (
    <div className={classes.root}>
      {processing ? (
        <div className="centered-container">
          <Helmet>
            <title>Processing Case {SITE_TITLE_POSTFIX}</title>
          </Helmet>
          <Typography variant="h5">
            Please wait while we process your file. This may take a while.
          </Typography>
          <Typography variant="h6">Do not navigate away from this page.</Typography>
          <CircularProgress className={classes.circularProgress} size={75} />
        </div>
      ) : (
        <div className="centered-container">
          <Helmet>
            <title>Create New Case {SITE_TITLE_POSTFIX}</title>
          </Helmet>
          <div className={classes.createCaseContainer}>
            <CaseInfoInput form={form} setForm={setForm} />
            <UploadTrialTranscript setUserFile={setUserFile} />
          </div>
          <Button
            className={classes.submitButton}
            variant="contained"
            onClick={handleSubmitClick}
            disabled={Object.values(form).some((value) => !value) || !userFile}
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(CreateNewCase)
