import React, { useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { Button, TextField, Typography } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import { Routes } from '../../constants'
import { useAuthState, useAlert } from '../../utils'
import {
  invalidEmailMessage,
  MIN_PASSWORD_LENGTH,
  shortPasswordMessage,
  validateEmail,
} from './index'
import { LoginTestIds } from '../../testing/test-ids'

import horizontalLogo from '../../assets/images/briefcase-logo-horizontal-02.png'
import { loginToBackend, loginToFirebase } from '../../api'
import { LoginForm } from '../../types'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      maxWidth: 320,
    },
    title: {
      color: 'black',
      alignItems: 'left',
      alignSelf: 'left',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      paddingBottom: theme.spacing(4),
    },
    field: {
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    submitButton: {
      color: theme.palette.common.white,
      backgroundColor: '#50718F', // gray
      whiteSpace: 'nowrap',
      width: '100%',
    },
    redirectButton: {
      width: '50%',
      fontSize: 11,
    },
    logo: {
      display: 'inline-block',
      verticalAlign: 'middle',
      paddingTop: theme.spacing(25),
      paddingBottom: theme.spacing(10),
      cursor: 'pointer',
    },
  })

type Props = WithStyles<typeof styles>

const Login: React.FC<Props> = ({ classes }) => {
  const [form, setForm] = useState<LoginForm>({ email: '', password: '' })
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const { addAlert } = useAlert()!
  const { setUser, setIsAuthenticated } = useAuthState()
  const history = useHistory()
  const location = useLocation()

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setIsEmailValid(validateEmail(event.target.value))
    setForm({ ...form, email: event.target.value })
  }

  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const newPassword = event.target.value
    setForm({ ...form, password: newPassword })
    setIsPasswordValid(newPassword.length > MIN_PASSWORD_LENGTH)
  }

  const requestLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const firebaseLoginSuccessful = await loginToFirebase(form).catch((err) =>
      addAlert(err.message)
    )

    if (firebaseLoginSuccessful) {
      let user = await loginToBackend(form).catch((err) => addAlert(err.message))

      if (user) {
        await setUser({
          id: user.id,
          firmId: user.firm_id,
          email: user.email,
          displayName: user.display_name ?? undefined,
          profileImage: user.profile_image ?? undefined,
        })
        await setIsAuthenticated(true)
        // redirect back to where the user came from if possible
        history.push(location.state?.from ?? Routes.Dashboard)
      }
    }
  }

  return (
    <div className={classes.root} data-testid={LoginTestIds.root}>
      <img
        className={classes.logo}
        src={horizontalLogo}
        alt="logo"
        onClick={() => history.push(Routes.Home)}
      />
      <Typography className={classes.title}>Login</Typography>
      <form onSubmit={requestLogin} data-testid={LoginTestIds.form}>
        <TextField
          className={classes.field}
          label="Email"
          data-testid={LoginTestIds.email}
          variant="outlined"
          value={form.email}
          onChange={handleEmailChange}
          error={form.email.length > 0 && !isEmailValid}
          helperText={!isEmailValid && form.email.length > 0 ? invalidEmailMessage : null}
          required
        />
        <TextField
          className={classes.field}
          label="Password"
          data-testid={LoginTestIds.password}
          variant="outlined"
          type="password"
          value={form.password}
          onChange={handlePasswordChange}
          error={form.password.length > 0 && !isPasswordValid}
          helperText={!isPasswordValid && form.password.length > 0 ? shortPasswordMessage : null}
          required
        />
        <Button
          className={classes.submitButton}
          data-testid={LoginTestIds.submit}
          variant="contained"
          type="submit"
          disabled={!isEmailValid || !isPasswordValid}
        >
          Submit
        </Button>
      </form>
      <div>
        <Button className={classes.redirectButton} component={Link} to={Routes.SignUp}>
          Make an Account
        </Button>
        <Button className={classes.redirectButton} component={Link} to={Routes.ForgotPassword}>
          Forgot Password?
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(Login)
