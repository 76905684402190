import React from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import TitleHeader from '../TitleHeader'
import SearchAndFilterBy from '../SearchAndFilterBy'
import { useDocument, useSuggestionsList } from '../../../../api'
import TagSuggestionCard from './TagSuggestionsCard'
import { useCaseId } from '../../../../utils'
import { ViewTagSuggestionsTestIds } from '../../../../testing/test-ids/workspace'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 310,
      minWidth: 310,
    },

    heading: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    headingTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#2c3f67',
      minHeight: 80,
      height: 80,
      width: '100%',
    },

    subheading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#0B2547',
      height: 50,
      padding: 10,
    },

    suggestionCardContainer: {
      width: '100%',
      padding: '0px 16px',
      marginTop: 30,
    },
  })

type Props = WithStyles<typeof styles>

const ViewTagSuggestions = ({ classes }: Props) => {
  const caseId = useCaseId()
  const { documentMetadata } = useDocument(caseId)
  const { suggestions } = useSuggestionsList(documentMetadata?.doc_id)
  const handleNewSearchQuery = (newQuery: string) => {}

  return (
    <div className={classes.root} data-testid={ViewTagSuggestionsTestIds.Root}>
      <div className={classes.heading}>
        <TitleHeader title="Tag Suggestions" />
        <SearchAndFilterBy handleNewSearchQuery={handleNewSearchQuery} />
      </div>
      <div className={classes.suggestionCardContainer}>
        {suggestions?.map((suggestion) => (
          <TagSuggestionCard
            suggestedTag={suggestion.tag}
            lineIds={suggestion.suggested_lines}
            data-testid={ViewTagSuggestionsTestIds.SuggestionCard}
            key={suggestion.tag}
          />
        ))}
      </div>
    </div>
  )
}

export default withStyles(styles)(ViewTagSuggestions)
