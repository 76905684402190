import React, { useEffect, useRef, useState } from 'react'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Button, Card, CardMedia, Typography } from '@material-ui/core'

import { getExhibitMedia, postExhibitMediaFiles } from '../../../api'
import { areFilesValid, stripContentTypePrefix, useAlert, useIsMounted } from '../../../utils'
import { acceptedExhibitFileTypes, badExhibitFilesAlertMessage } from '../../../constants'
import GetAppIcon from '@material-ui/icons/GetApp'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },

    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16,
    },

    addMediaButton: {
      height: 35,
    },

    form: {
      display: 'flex',
    },

    dropArea: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 300,
      border: '1px solid black',
    },

    dropAreaButton: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },

    // Icon width and height must be same value
    // https://stackoverflow.com/a/38840120
    dropAreaIcon: {
      width: 70,
      height: 70,
      color: 'gray',
      marginBottom: theme.spacing(1),
    },

    dropAreaTitle: {
      fontWeight: 'bold',
      fontSize: 16,
    },

    mediaContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },

    media: {
      height: 224,
      width: 224,
    },

    mediaCard: {
      margin: 10,
    },
  })

const fetchImages = async (
  exhibitId: string,
  setImageUrls: React.Dispatch<React.SetStateAction<string[]>>,
  isMounted: React.MutableRefObject<boolean>
) => {
  getExhibitMedia(exhibitId)
    .then((imageUrls) => {
      if (isMounted.current && imageUrls.length) setImageUrls(imageUrls)
    })
    .catch((e) => console.error(e.message))
}

const uploadFiles = async (files: File[], exhibitId: string, addAlert: (alert: string) => void) => {
  const form = new FormData()
  form.append('exhibit_id', exhibitId)
  files.forEach((file) => form.append('file', file, file.name))
  const success = await postExhibitMediaFiles(form).catch((e) => console.error(e.message))
  if (success) {
    addAlert('Media upload successful!')
  } else {
    addAlert('Sorry, media upload was not successful')
  }
  return success
}

const setNewFiles = (
  newFiles: File[],
  setImageUrls: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const newFileUrls = newFiles.map((file) => URL.createObjectURL(file))
  setImageUrls((prevImageUrls) => [...prevImageUrls, ...newFileUrls])
}

const readableAllowedFileTypes = stripContentTypePrefix(acceptedExhibitFileTypes, 'image/')

type Props = WithStyles<typeof styles> & {
  id: string
}

const ExhibitMedia = ({ classes, id }: Props) => {
  const { addAlert } = useAlert()!
  const isMounted = useIsMounted()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [imageUrls, setImageUrls] = useState<string[]>([])

  const handleFileSubmission = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    // must create new copy of files or else they get wiped when setting
    // event.currentTarget.value = ''
    const newFiles = event.currentTarget.files ? [...event.currentTarget.files] : []
    handleNewFiles(newFiles)
    // must set the input to falsy each time so user has "clean slate" each time
    // they open file selection window
    event.currentTarget.value = ''
  }

  const handleFileDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
    const newFiles = event.dataTransfer.files ? [...event.dataTransfer.files] : []
    handleNewFiles(newFiles)
  }

  const handleNewFiles = async (newFiles: File[]) => {
    if (areFilesValid(newFiles, acceptedExhibitFileTypes)) {
      addAlert('Media uploading...')
      const success = await uploadFiles(newFiles, id, addAlert)
      if (success) {
        setNewFiles(newFiles, setImageUrls)
      }
    } else {
      addAlert(badExhibitFilesAlertMessage)
    }
  }

  const preventDefaultDragBehavior = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleButtonClick = () => fileInputRef.current?.click()

  useEffect(() => {
    if (id) fetchImages(id, setImageUrls, isMounted)
  }, [id])

  return (
    <div
      className={classes.root}
      onDrop={preventDefaultDragBehavior}
      onDrag={preventDefaultDragBehavior}
      onDragOver={preventDefaultDragBehavior}
    >
      <div className={classes.header}>
        <Typography variant="h4">Exhibit Media</Typography>
        <Button variant="contained" className={classes.addMediaButton} onClick={handleButtonClick}>
          Add Exhibit Media
        </Button>
      </div>

      <form className={classes.form}>
        <input type="file" multiple onChange={handleFileSubmission} ref={fileInputRef} hidden />
        <label
          htmlFor="file-input"
          onDrop={handleFileDrop}
          style={{
            width: '100%',
          }}
        >
          {imageUrls.length ? (
            <div className={classes.mediaContainer}>
              {imageUrls.map((url) => (
                <Card className={classes.mediaCard} elevation={3} key={url}>
                  <CardMedia className={classes.media} image={url} component="img" />
                </Card>
              ))}
            </div>
          ) : (
            <div className={classes.dropArea}>
              <GetAppIcon className={classes.dropAreaIcon} />
              <Typography className={classes.dropAreaTitle}>Drag File</Typography>
            </div>
          )}
        </label>
      </form>
    </div>
  )
}

export default withStyles(styles)(ExhibitMedia)
