import React from 'react'

import { Link } from 'react-router-dom'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'

import { Button, Divider } from '@material-ui/core'

import SidebarHeader from './common/SidebarHeader'
import SystemUpdates from './common/SystemUpdates'

import { Routes } from '../../../constants'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },

    // Sidebar
    sidebarContent: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },

    // Sidebar Button Area
    buttonArea: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      // marginTop: theme.spacing(4.5),
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
    },

    button: {
      display: 'flex',
      flex: 1,
      color: theme.palette.common.white,
      backgroundColor: '#50718F', // gray
      marginBottom: theme.spacing(2),
      whiteSpace: 'nowrap',
    },

    // Sidebar Updates Area
    divider: {
      marginTop: theme.spacing(4),
    },

    updatesTextArea: {
      marginTop: theme.spacing(2.5),
    },
  })

type Props = WithStyles<typeof styles> & {
  path: string
}

const CaseViewSidebar: React.FC<Props> = (props: Props) => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <SidebarHeader />
      <div className={classes.sidebarContent}>
        <div className={classes.buttonArea}>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              variant="contained"
              component={Link}
              to={Routes.Dashboard}
            >
              &lt; Back to Dashboard
            </Button>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              variant="contained"
              component={Link}
              to={Routes.Create}
            >
              + Create New Case
            </Button>
          </div>
        </div>
        <Divider className={classes.divider} orientation="horizontal" />
        <div className={classes.updatesTextArea}>
          <SystemUpdates />
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(CaseViewSidebar)
