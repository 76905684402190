import React from 'react'
import { Helmet } from 'react-helmet'

import clsx from 'clsx'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'

import { Divider, Paper, Typography } from '@material-ui/core'

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

import briefcaseIconGreen from '../../../assets/images/briefcase-icon-green-35x35.png'
import briefcaseFileGray from '../../../assets/images/briefcase-file-icon-40x40-gray.png'
import briefcaseFilesGreen from '../../../assets/images/briefcase-files-icon-32x32-green.png'
import folderGray from '../../../assets/images/folder-36x36-gray.png'

// Constants
import { SITE_TITLE_POSTFIX } from '../../../constants'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },

    sectionHeader: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },

    subsectionHeader: {
      marginTop: theme.spacing(4.5),
    },

    caseIcon: {
      height: 'auto',
      width: 35,
      marginRight: theme.spacing(1.5),
    },

    contentTitleDivider: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
    },

    arrowIcon: {
      height: 40,
      width: 40,
    },

    // File Items
    fileItem: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      backgroundColor: '#EFEFEF',
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
    },

    fileText: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: theme.spacing(0.5),
    },

    fileName: {
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: '0.9em',
    },

    fileSubText: {
      fontSize: 12,
    },
  })

type Props = WithStyles<typeof styles> & {}

const CaseView: React.FC<Props> = (props: Props) => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <Helmet>
        <title>View Case {SITE_TITLE_POSTFIX}</title>
      </Helmet>
      <div className={classes.sectionHeader}>
        <img className={classes.caseIcon} src={briefcaseIconGreen} alt="logo" />
        <Typography variant="h5">Launch in Briefcase Workspace</Typography>
      </div>
      <Divider className={classes.contentTitleDivider} orientation="horizontal" />

      <Paper className={classes.fileItem} elevation={0}>
        <img className={classes.caseIcon} src={briefcaseFileGray} alt="logo" />
        <div className={classes.fileText}>
          <Typography className={classes.fileName}>File Name</Typography>
          <Typography className={classes.fileSubText}>Last opened 10/01/2020</Typography>
        </div>
        <KeyboardArrowRightIcon className={classes.arrowIcon} />
      </Paper>

      <div className={clsx(classes.sectionHeader, classes.subsectionHeader)}>
        <img className={classes.caseIcon} src={briefcaseFilesGreen} alt="logo" />
        <Typography variant="h5">All Files</Typography>
      </div>
      <Divider className={classes.contentTitleDivider} orientation="horizontal" />

      <Paper className={classes.fileItem} elevation={0}>
        <img className={classes.caseIcon} src={folderGray} alt="logo" />
        <div className={classes.fileText}>
          <Typography className={classes.fileName}>Folder Name</Typography>
          <Typography className={classes.fileSubText}>Last opened 10/01/2020</Typography>
        </div>
      </Paper>

      <Paper className={classes.fileItem} elevation={0}>
        <img className={classes.caseIcon} src={folderGray} alt="logo" />
        <div className={classes.fileText}>
          <Typography className={classes.fileName}>Folder Name</Typography>
          <Typography className={classes.fileSubText}>Last opened 10/01/2020</Typography>
        </div>
      </Paper>

      <Paper className={classes.fileItem} elevation={0}>
        <img className={classes.caseIcon} src={briefcaseFileGray} alt="logo" />
        <div className={classes.fileText}>
          <Typography className={classes.fileName}>File Name</Typography>
          <Typography className={classes.fileSubText}>Last opened 10/01/2020</Typography>
        </div>
      </Paper>

      <Paper className={classes.fileItem} elevation={0}>
        <img className={classes.caseIcon} src={briefcaseFileGray} alt="logo" />
        <div className={classes.fileText}>
          <Typography className={classes.fileName}>File Name</Typography>
          <Typography className={classes.fileSubText}>Last opened 10/01/2020</Typography>
        </div>
      </Paper>
    </div>
  )
}

export default withStyles(styles)(CaseView)
