import React, { useState } from 'react'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Button, IconButton, Paper, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import AddRounded from '@material-ui/icons/AddRounded'

import RoundedInputField from '../../../../components/RoundedInputField'
import { getRandomTagColor, useTagging } from '../../../../utils'
import { deleteTagByTagId, postTagLabel } from '../../../../api'
import { Tag } from '../../../../types'
import { DEFAULT_TAGS } from '../../../../utils/context-providers'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 350,
      minHeight: 70,
      padding: theme.spacing(1),
      marginRight: 8,
      '&:hover': {
        zIndex: 40,
      },
    },

    dialogDescription: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 6,
    },

    tagButtonContainer: {
      whiteSpace: 'nowrap',
      marginRight: 8,
    },

    tagButton: {
      color: 'black',
      padding: '3px 10px 3px 10px',
      borderRadius: 17,
      margin: '2px 2px 2px 0px',
    },

    userAvatar: {
      marginRight: theme.spacing(1),
      width: theme.spacing(4),
      height: theme.spacing(4),
    },

    newTagInputContainer: {
      display: 'flex',
      flex: 1,
      marginTop: theme.spacing(1),
    },

    submitButton: {
      marginLeft: theme.spacing(1),
    },

    closeButton: {
      marginLeft: 'auto',
    },
  })

type Props = WithStyles<typeof styles> & {
  setShouldShowTagDialog: React.Dispatch<React.SetStateAction<boolean>>
  addTagForCurrentRow: (tagLabel: string) => void
  caseId: string
}

const TagDialog = ({ classes, setShouldShowTagDialog, addTagForCurrentRow, caseId }: Props) => {
  const { tags, setTags } = useTagging()
  const [newTagLabel, setNewTagLabel] = useState('')

  const handleCloseClick = () => {
    setShouldShowTagDialog(false)
  }

  const handleTagLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setNewTagLabel(event.currentTarget.value)
  }

  const handleNewTagSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const hexColor = getRandomTagColor()
    const label = newTagLabel
    if (label) {
      postTagLabel(label, hexColor, caseId).then((newTag) => {
        if (newTag) {
          setTags([...tags, { label, hexColor, id: newTag.id }])
        }
      })
      setNewTagLabel('')
    }
  }

  const handleTagDeleteClick = (indexOfTag: number, tagId: Tag['id']) => {
    if (tagId) {
      const newTags = [...tags.slice(0, indexOfTag), ...tags.slice(indexOfTag + 1)]
      setTags(newTags)
      deleteTagByTagId(tagId)
    }
  }

  return (
    <Paper className={classes.root} elevation={2}>
      <div className={classes.dialogDescription}>
        <Typography variant="h6">Choose or Add a Tag</Typography>
        <IconButton className={classes.closeButton} onClick={handleCloseClick}>
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        {tags.map((tag, index) => (
          <span className={classes.tagButtonContainer} key={tag.id}>
            <Button
              className={classes.tagButton}
              style={{ background: tag.hexColor }}
              onClick={() => addTagForCurrentRow(tag.label)}
            >
              {tag.label}
            </Button>
            {index >= DEFAULT_TAGS.length && (
              <IconButton onClick={() => handleTagDeleteClick(index, tag.id)}>
                <CancelRoundedIcon fontSize="small" />
              </IconButton>
            )}
          </span>
        ))}
      </div>
      <form onSubmit={handleNewTagSubmit}>
        <div className={classes.newTagInputContainer}>
          <RoundedInputField
            handleInputChange={handleTagLabelChange}
            inputValue={newTagLabel}
            placeholder="Add a new tag here"
          />
          <IconButton className={classes.submitButton} type="submit">
            <AddRounded />
          </IconButton>
        </div>
      </form>
    </Paper>
  )
}

export default withStyles(styles)(TagDialog)
