import { fetcher, get, post } from './httpHelpers'
import { CaseDump, HttpResponse, LegalCase, RedisJobId } from '../types'
import useSWR from 'swr'

export const useDocument = (caseId: string) => {
  const { data, error } = useSWR<HttpResponse<CaseDump>, Error>(
    () => (caseId ? `cases/document/case/${caseId}` : null),
    fetcher
  )
  const document = data?.parsedBody?.['case_document']

  const documentId = document?.case[caseId].doc_ids[0]

  return {
    documentMetadata: document?.documents[documentId!],
    pages: document?.pages,
    lines: document?.lines,
    examinations: document?.examinations,
    isLoadingDocument: !error && !data,
    error,
  }
}

export const postTranscript = async (form: FormData) =>
  post<RedisJobId>('files/document/trial', form).then((response) => response.parsedBody!['job_id'])

export const getFirstFileNameByCaseId = async (caseId: string) =>
  get<LegalCase>(`cases/case/${caseId}`).then(
    (response) => response.parsedBody!['case'].artifacts[0].file_name
  )

export const retrieveRawPdf = async (caseId: string, filename: string) =>
  post<Uint8Array | null>(`files/cases/case/${caseId}`, { file_name: filename }).then(
    (response) => response.parsedBody!['pdf']
  )
