import React, { useState } from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { IconButton, Paper, Typography } from '@material-ui/core'
import LabelIcon from '@material-ui/icons/Label'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { KeyboardArrowDown } from '@material-ui/icons'
import { addTagForRow } from '../../../../services/firebase/dal'
import SuggestionInfoCard from './SuggestionInfoCard'
import { useAlert, useCaseId, useScrollTo } from '../../../../utils'
import { TagSuggestionCardTestIds } from '../../../../testing/test-ids/workspace'
import { useDocument } from '../../../../api'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: 12,
      margin: '0px 0px 15px 0px',
    },

    metaDataContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },

    infoContainer: {
      flexGrow: 1,
    },

    labelIcon: {
      color: '#572994',
      transform: 'rotate(90deg)',
      alignSelf: 'flex-start',
    },

    openCardButton: {
      height: 35,
    },

    linesContainer: {
      width: '100%',
    },
  })

type Props = WithStyles<typeof styles> & {
  suggestedTag: string
  lineIds: string[]
}

const TagSuggestionCard = ({ classes, suggestedTag, lineIds }: Props) => {
  const caseId = useCaseId()
  const { lines } = useDocument(caseId)
  const [isCardOpen, setIsCardOpen] = useState(false)

  return (
    <Paper className={classes.root} elevation={3} data-testid={TagSuggestionCardTestIds.Root}>
      <div className={classes.metaDataContainer}>
        <LabelIcon fontSize="large" className={classes.labelIcon} />
        <div className={classes.infoContainer}>
          <Typography variant="h6">{suggestedTag}</Typography>
          <Typography>No Active Petition</Typography>
          <Typography>Suggesting {lineIds?.length ?? 0} tags</Typography>
        </div>
        <IconButton className={classes.openCardButton} onClick={() => setIsCardOpen(!isCardOpen)}>
          {isCardOpen ? (
            <KeyboardArrowDown fontSize="large" />
          ) : (
            <NavigateBeforeIcon fontSize="large" className={classes.openCardButton} />
          )}
        </IconButton>
      </div>
      <div className={classes.linesContainer}>
        {isCardOpen &&
          lines &&
          lineIds.map((lineId) => (
            <SuggestionInfoCard line={lines[lineId]} suggestedTag={suggestedTag} key={lineId} />
          ))}
      </div>
    </Paper>
  )
}

export default withStyles(styles)(TagSuggestionCard)
