import React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'

import { ButtonBase, Divider, Paper, Typography } from '@material-ui/core'

import GetAppIcon from '@material-ui/icons/GetApp'

// Constants
import { useAlert } from '../../../utils'
import { ContentType } from '../../../api/httpHelpers'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: '100%',
    },

    contentTitleDivider: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(7.5),
    },

    // The actual form
    form: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      maxWidth: 268,
    },

    backButton: {
      marginBottom: theme.spacing(4),
      backgroundColor: '#55B89F',
      color: theme.palette.common.white,
    },

    dropArea: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 380,
      border: '1px solid gray',
      backgroundColor: theme.palette.background.default,
    },

    dropAreaButton: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },

    // Icon width and height must be same value
    // https://stackoverflow.com/a/38840120
    dropAreaIcon: {
      width: 70,
      height: 70,
      color: 'gray',
      marginBottom: theme.spacing(1),
    },

    dropAreaTitle: {
      fontWeight: 'bold',
      fontSize: 16,
    },
  })

const acceptedFileTypes: Set<string> = new Set([ContentType.PDF])

type Props = WithStyles<typeof styles> & {
  setUserFile: React.Dispatch<React.SetStateAction<File | null>>
}

const CreateNewCase: React.FC<Props> = ({ classes, setUserFile }) => {
  const { addAlert, dismissCurrentAlert } = useAlert()!

  const setFile = (file: File | undefined) => {
    if (file) {
      if (acceptedFileTypes.has(file.type)) {
        setUserFile(file)
        addAlert(`${file.name} received`)
      } else {
        addAlert(`File type "${file.type}" not accepted, please upload a PDF.`)
      }
    } else {
      addAlert('Sorry, file was not found')
    }
  }

  const handleFileSubmission = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setFile(event.currentTarget.files?.[0])
    // must set the input to falsy each time so user has "clean slate" each time they open
    // file selection window
    event.currentTarget.value = ''
  }

  const handleFileDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
    dismissCurrentAlert()
    setFile(event.dataTransfer.files[0])
  }

  const handleFileDrag = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
    addAlert('Drop Files to Upload Them')
  }

  const handleFileDragLeave = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
    dismissCurrentAlert()
  }

  const preventDefaultDragBehavior = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  return (
    <div
      className={classes.root}
      onDrop={preventDefaultDragBehavior}
      onDrag={preventDefaultDragBehavior}
      onDragOver={preventDefaultDragBehavior}
    >
      <Typography variant="h5">Upload Trial Transcript</Typography>
      <Divider className={classes.contentTitleDivider} orientation="horizontal" />
      <div className={classes.form}>
        <form>
          <input type="file" onChange={handleFileSubmission} id="file-input" hidden />
          <label
            htmlFor="file-input"
            onDrop={handleFileDrop}
            onDragEnter={handleFileDrag}
            onDragLeave={handleFileDragLeave}
          >
            <ButtonBase className={classes.dropAreaButton} component="span">
              <Paper className={classes.dropArea}>
                <GetAppIcon className={classes.dropAreaIcon} />
                <Typography className={classes.dropAreaTitle}>
                  Drag File or Click to Upload
                </Typography>
              </Paper>
            </ButtonBase>
          </label>
        </form>
      </div>
    </div>
  )
}

export default withStyles(styles)(CreateNewCase)
