import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Routes } from '../../constants'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import managementImage from '../../assets/features/NewManagement(1).png'
import collabImage from '../../assets/features/NewCollaboration.png'
import HomepageBar from 'pages/homepage bar'
import insightImage from '../../assets/features/NewInsights2.png'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: 'white',
    },

    //splashTextHighlight: {
    //   color: '#50718F', // this is the light blue teal
    //   // color: '#2C3F67', // this is dark navy blue
    //   // color: '#572994', // this is the purple color
    // },

    wrapper: {
      display: 'flex',
      midWidth: 1200,
      width: '100%',
      marginRight: 'auto',
      marginLeft: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#2C3F67',
    },

    center: {
      position: 'relative',
      display: 'flex',
      maxWidth: 1030,
      marginTop: theme.spacing(4),
      marginRight: 'auto',
      marginLeft: 'auto',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },

    featuresTextLabel: {
      fontSize: '3vmin',
      lineHeight: '150%',
      fontWeight: 700,
      marginTop: theme.spacing(6),
      color: 'black',
    },

    titleText: {
      fontSize: '7vmin',
      fontWeight: 800,
      marginBottom: theme.spacing(1),
      //color: '#50718F',
      color: 'black',
    },

    subTitleText: {
      maxWidth: 540,
      fontSize: '2vmin',
      marginBottom: theme.spacing(2),
      color: 'black',
    },

    menuButtonLabel: {
      textTransform: 'capitalize',
      fontSize: 16,
    },

    signInButton: {
      // background: 'linear-gradient(90deg, #369A98 0%, #487292 100%)',
      background: 'black',
      // display: 'flex',
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      // justifyContent: 'flex-end',
      alignSelf: 'center',
    },

    // Text on the left, image on the right
    leftGrid: {
      display: 'grid',
      maxWidth: 1200,
      minWidth: 600,
      gridTemplateRows: 'auto',
      gridTemplateColumns: '0.75fr 1fr',
    },

    // Text on the right, image on the left
    rightGrid: {
      display: 'grid',
      maxWidth: 1200,
      minWidth: 600,
      gridTemplateRows: 'auto',
      gridTemplateColumns: '1fr 0.75fr ',
    },

    leftTextGrid: {
      maxWidth: 600,
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(2),
      alignSelf: 'center',
      textAlign: 'left',
      color: 'white',
    },

    rightTextGrid: {
      maxWidth: 400,
      alignSelf: 'center',
      textAlign: 'left',
      color: 'black',
      paddingLeft: '128px',
    },

    leftImageGrid: {
      position: 'relative',
      maxWidth: 1000,
      display: 'flex',
      // width: '100%',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(4),
    },

    gridContainer: {
      midWidth: 1200,
      // width: '100%',
      display: 'grid',
      gap: '2rem',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },

    bulletText: {
      marginTop: theme.spacing(2),
      fontSize: '1.8vmin',
      color: 'white',
    },

    // TODO: not responsive --> make responsive
    trialHeader: {
      marginBottom: theme.spacing(2),
      fontSize: '4.5vmin',
      lineHeight: '110%',
      fontWeight: 800,
      color: 'white',
    },

    greyImage: {
      marginTop: theme.spacing(4),
      maxWidth: 1000,
      width: '100%',
      objectFit: 'cover',
      borderRadius: '25px',
      boxShadow: '-5px 5px 5px 5px #808080',
    },

    navyImage: {
      marginTop: theme.spacing(4),
      maxWidth: 1000,
      width: '100%',
      objectFit: 'cover',
      borderRadius: '25px',
      boxShadow: '5px 5px 5px 5px #151f33',
    },

    category: {
      fontSize: '2vmin',
      color: 'white',
    },

    paragraphSub: {
      fontSize: '2vmin',
    },
  })

type Props = WithStyles<typeof styles>

const Features = (props: Props) => {
  const history = useHistory()
  const { classes } = props
  return (
    <div style={{ color: 'white' }}>
      <HomepageBar />
      <div className={classes.root}>
        {/* Splash Row 1: Features Header */}
        <div className={classes.wrapper} style={{ backgroundColor: '#D5E6E3' }}>
          <div className={classes.center}>
            <div className={classes.featuresTextLabel}>Features</div>
            <h1 className={classes.titleText}>Work through Cases with Briefcase's Features</h1>
            <p className={classes.subTitleText}>
              Analyze court transcripts for key moments, identify areas of dispute and
              inconsistency, and bookmark areas for review
            </p>
            {/* TODO: Sign in button looks weird?  */}
            <div>
              <Button
                color="primary"
                variant="contained"
                className={classes.signInButton}
                classes={{
                  label: classes.menuButtonLabel,
                }}
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  history.push(Routes.Login)
                }}
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
        {/* Splash Row 2: Manage and Process your Cases */}
        <div className={classes.wrapper}>
          <div className={classes.gridContainer}>
            <div className={classes.leftGrid}>
              <div className={classes.leftTextGrid}>
                {/* color: '#572994' */}
                <h4 className={classes.category}>Organization</h4>
                <h2 className={classes.trialHeader}>Manage and Process your Cases Faster</h2>
                <p className={classes.paragraphSub}>
                  Clean and user-intuitive workspace layout makes case organization easier
                </p>
                <div className={classes.bulletText}>
                  <span>&#10003;</span>
                  <strong> Readable Transciprt:</strong> Upload your court transcript PDF and
                  Briefcase will turn it into an easy to read, plaintext document
                </div>
                <div className={classes.bulletText}>
                  <span>&#10003;</span>
                  <strong> Intuitive Dashboard:</strong> View your cases in a file or grid format,
                  easily see your recently opened cases, and search for any case
                </div>
                <div className={classes.bulletText}>
                  <span>&#10003;</span>
                  <strong> Collaborate with Peers:</strong> Communicating with peers has never been
                  easier! Leave comments, tag peers, and share cases with others in your firm
                </div>
              </div>
              <div className={classes.leftImageGrid}>
                <img
                  className={classes.navyImage}
                  src={managementImage}
                  alt="commenting on trial transcript"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Splash Row 3: Collaborate with Coworkers */}
        <div className={classes.wrapper} style={{ backgroundColor: '#d9d9d9', color: 'black' }}>
          <div className={classes.gridContainer}>
            <div className={classes.rightGrid}>
              <div className={classes.leftImageGrid}>
                <img
                  className={classes.greyImage}
                  src={collabImage}
                  alt="commenting on trial transcript"
                />
              </div>
              <div className={classes.rightTextGrid}>
                <h4 className={classes.category} style={{ color: 'black' }}>
                  Communication
                </h4>
                <h2 className={classes.trialHeader} style={{ color: 'black' }}>
                  Collaborate with your coworkers on cases
                </h2>
                <p className={classes.paragraphSub}>No more sticky notes on printed pages!</p>
                <div className={classes.bulletText} style={{ color: 'black' }}>
                  <span>&#10003;</span>
                  <strong> Write Comments:</strong> Make notations on lines and respond to peers
                </div>
                <div className={classes.bulletText} style={{ color: 'black' }}>
                  <span>&#10003;</span>
                  <strong> Track Exhibits and Character Profiles:</strong> Create exhibits for each
                  case with any added details to streamline communication and track who is
                  speakinger and where
                </div>
                <div className={classes.bulletText} style={{ color: 'black' }}>
                  <span>&#10003;</span>
                  <strong> Custom Tags:</strong> Lawyers can create and assign custom tags to
                  highlilght information throughout the case
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Splash Row 4: Intelligent Insights */}
        <div className={classes.wrapper}>
          <div className={classes.gridContainer}>
            <div className={classes.leftGrid}>
              <div className={classes.leftTextGrid}>
                <h4 className={classes.category}>Insights</h4>
                <h2 className={classes.trialHeader}>
                  Utilize Intelligent Insights to increase productivity
                </h2>
                <p className={classes.paragraphSub}>Let Briefcase do the work!</p>
                <div className={classes.bulletText}>
                  <span>&#10003;</span>
                  <strong> AI Tags:</strong> Our Tags highlight relevant information and provide tag
                  suggestions as you move through the case
                </div>
                <div className={classes.bulletText}>
                  <span>&#10003;</span>
                  <strong> Case Minimap:</strong> Easily navigate your court transcript by tagging
                  key sections like cross-examinations so users can easily find them
                </div>
                <div className={classes.bulletText}>
                  <span>&#10003;</span>
                  <strong> Examination Summaries:</strong> AI generated summary of each examination
                  and cross-examinations
                </div>
              </div>
              <div className={classes.leftImageGrid}>
                <img
                  className={classes.navyImage}
                  src={insightImage}
                  alt="commenting on trial transcript"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(Features)
