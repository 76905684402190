import React, { ReactNode } from 'react'

type TabPanelProps = {
  children?: ReactNode
  index: number
  value: number
}

const DrawerPanel = (props: TabPanelProps) => {
  const { children, value, index } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}>
      {value === index && children}
    </div>
  )
}

export default DrawerPanel
