import React from 'react'

import { BrowserRouter, Route, RouteComponentProps, Switch } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'

import { ENV, Routes, VERSION } from '../../constants'
import { OnboardingTestIds } from '../../testing/test-ids'

// Various subcomponents for Onboarding page
import Login from './Login'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'

import onboardingBackground from '../../assets/images/briefcase-onboarding-background.png'

const styles = (theme: Theme) =>
  createStyles({
    background: {
      backgroundImage: `url(${onboardingBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh',
      width: '100vw',
    },

    sideBar: {
      float: 'right',
      width: '25vw',
      height: '100vh',
      minWidth: 350,
      maxWidth: 430,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    sideBarFooter: {
      fontSize: 12,
      color: theme.palette.grey[500],
    },
  })

export const validateEmail = (input: string): boolean => {
  // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript/50816739.
  const emailValidationRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailValidationRegex.test(input)
}
export const invalidEmailMessage = 'Please enter a valid email address.'

export const MIN_PASSWORD_LENGTH = 6
export const shortPasswordMessage = `Password must be longer than ${MIN_PASSWORD_LENGTH} characters`

type Props = WithStyles<typeof styles> & RouteComponentProps<{}>

const Onboarding: React.FC<Props> = ({ classes }: Props) => {
  return (
    <Paper className={classes.background} data-testid={OnboardingTestIds.root}>
      <Paper className={classes.sideBar} elevation={5}>
        <Switch>
          <Route exact path={Routes.Login} component={Login} />
          <Route exact path={Routes.SignUp} component={SignUp} />
          <Route exact path={Routes.ForgotPassword} component={ForgotPassword} />
        </Switch>
        <div className={classes.sideBarFooter} data-testid={OnboardingTestIds.version}>
          {VERSION}-{ENV?.toUpperCase()}
        </div>
      </Paper>
    </Paper>
  )
}

export default withStyles(styles)(Onboarding)
