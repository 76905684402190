import React, { createContext, FC } from 'react'
import { ScrollToContextProvider } from '../../types'

export const ScrollToContext = createContext<ScrollToContextProvider>({} as ScrollToContextProvider)

type Props = {
  children: React.ReactNode
  scrollToRow: (lineId: string) => Promise<void>
  scrollToPage: (pageNumber: number) => Promise<void>
}

export const ScrollToProvider: FC<Props> = ({ children, scrollToRow, scrollToPage }) => (
  <ScrollToContext.Provider value={{ scrollToRow, scrollToPage }}>
    {children}
  </ScrollToContext.Provider>
)
