import React, { createContext, FC } from 'react'
import { CaseIdContextProvider } from '../../types'

export const CaseIdContext = createContext<CaseIdContextProvider>('')

type Props = {
  children: React.ReactNode
  caseId: string
}

export const CaseIdProvider: FC<Props> = ({ children, caseId }) => (
  <CaseIdContext.Provider value={caseId}>{children}</CaseIdContext.Provider>
)
