import React from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import Notes from '../Notes'
import Mentions from '../Mentions'
import { LinesMap, PagesMap } from '../../../types'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      width: 1300,
      background: 'white',
      padding: '16px 20px 16px 20px',
    },

    leftSide: {
      width: '40%',
      marginRight: 40,
    },
  })

type Props = WithStyles<typeof styles> & {
  id: string
}

const CharacterProfile = ({ classes, id }: Props) => {
  return (
    <Paper elevation={3} className={classes.root}>
      <div className={classes.leftSide}>
        <Notes id={id} />
      </div>
      <Mentions id={id} />
    </Paper>
  )
}

export default withStyles(styles)(CharacterProfile)
