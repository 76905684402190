import React from 'react'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import {
  createTheme,
  createStyles,
  MuiThemeProvider,
  WithStyles,
  withStyles,
} from '@material-ui/core/styles'

import CssBaseline from '@material-ui/core/CssBaseline'

import Homepage from './pages/homepage'
import Onboarding from './pages/onboarding'
import Dashboard from './pages/dashboard'
import Workspace from './pages/workspace/Workspace'
import ProtectedRoute from './components/ProtectedRoute'
import AboutUs from './pages/aboutUs'
import Features from './pages/features'

import { Routes } from './constants'
import { AlertProvider, AuthProvider } from './utils/context-providers'

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      // main: '#005b82',
      // light: '#66a4ba',
      // dark: '#213a45'
      main: '#175A6B', // dark turqoise
      light: '#3DA1B5', // blue turqoise
      dark: '#002d52', // dark blue
    },
    secondary: {
      // main: '#00364a'
      // main: '#E0552A', // burnt orange
      main: '#55B89F', // green turqoise
      light: '#E8CC8D', // beige
    },
    background: {
      default: '#F8F8F8', // gray off-white
    },
  },
  typography: {
    fontFamily: [
      'Heebo',
      'Open Sans',
      'Lato',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),

    // Main Content Titles
    h5: {
      fontWeight: 'bold',
    },
  },

  // Override MUI Defaults: https://material-ui.com/customization/globals/#css
  overrides: {
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    // MUI Table Cell: https://material-ui.com/api/table-cell/#css
    MuiTableCell: {
      root: {
        borderBottom: 'none',
      },
    },
  },
})

export const defaultErrorText = 'Sorry, but something went wrong.'

const styles = () =>
  createStyles({
    root: {
      width: '100vw',
      height: '100vh',
      maxWidth: '100%', // https://stackoverflow.com/a/23367686/11903320
      padding: 0,
      margin: 0,
      // background: '#dfe9ed',
    },
  })

// Don't repeat CSS classnames in the type because it's not DRY
// https://material-ui.com/guides/typescript/#augmenting-your-props-using-withstyles
type Props = WithStyles<typeof styles>

const App = ({ classes }: Props) => {

  return (
    <div className={classes.root}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <MuiThemeProvider theme={theme}>
        {/* Global CSS reset: https://material-ui.com/components/css-baseline/ */}
        <CssBaseline />
        <BrowserRouter>
          <AlertProvider>
            <Switch>
              {/*
              homepage route needs to be outside AuthStateProvider so that AuthStateProvider
              does not redirect to /login if user is not logged in
              */}
              <Route exact path={Routes.Home} component={Homepage} />
              <Route exact path={Routes.AboutUs} component={AboutUs} />
              <Route exact path={Routes.Features} component={Features} />
              <AuthProvider>
                <ProtectedRoute exact path={Routes.Workspace} component={Workspace} />
                <ProtectedRoute
                  exact
                  path={[
                    Routes.Dashboard,
                    Routes.Case,
                    Routes.Create,
                    Routes.Upload,
                    Routes.Profile,
                  ]}
                  component={Dashboard}
                />
                <Route
                  exact
                  path={[Routes.Login, Routes.SignUp, Routes.ForgotPassword]}
                  component={Onboarding}
                />
              </AuthProvider>
            </Switch>
          </AlertProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    </div>
  )
}

export default withStyles(styles)(App)
