import React, { FC } from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Divider, TextField, Typography } from '@material-ui/core'
import { CreateNewCaseForm } from '../../../types'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 170,
    },

    contentTitleDivider: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(7.5),
    },

    // The actual form
    form: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      maxWidth: 380,
    },

    field: {
      marginBottom: theme.spacing(4),
      minWidth: 280,
    },

    continueButton: {
      color: theme.palette.common.white,
      backgroundColor: '#50718F', // gray
      whiteSpace: 'nowrap',
    },
  })

type Props = WithStyles<typeof styles> & {
  form: CreateNewCaseForm
  setForm: React.Dispatch<React.SetStateAction<CreateNewCaseForm>>
}

const CaseInfoInput: FC<Props> = (props) => {
  const { classes, form, setForm } = props
  return (
    <div className={classes.root}>
      <Typography variant="h5">Enter Case Details</Typography>
      <Divider className={classes.contentTitleDivider} orientation="horizontal" />
      <form className={classes.form}>
        <TextField
          className={classes.field}
          label="Case Name"
          variant="outlined"
          value={form.title}
          onChange={(e) => setForm({ ...form, title: e.target.value })}
        />
        <TextField
          className={classes.field}
          label="Case Docket Number"
          variant="outlined"
          value={form.docket_number}
          onChange={(e) => setForm({ ...form, docket_number: e.target.value })}
        />
        <TextField
          className={classes.field}
          label="Court Name"
          variant="outlined"
          value={form.court_name}
          onChange={(e) => setForm({ ...form, court_name: e.target.value })}
        />
        <TextField
          label="Trial Date"
          type="date"
          className={classes.field}
          variant="outlined"
          value={form.date_filed}
          onChange={(e) => setForm({ ...form, date_filed: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          label="Dialogue Start Page"
          type="number"
          className={classes.field}
          variant="outlined"
          value={form.dialogue_start_page}
          onChange={(e) => setForm({ ...form, dialogue_start_page: +e.target.value })}
          inputProps={{ min: 1 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </form>
    </div>
  )
}

export default withStyles(styles)(CaseInfoInput)
