import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, RouteComponentProps } from 'react-router-dom'

import clsx from 'clsx'
import { Divider, Typography } from '@material-ui/core'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

// Default Content Area
import CaseCardList from './case-card-list'

// Sidebars
import DefaultSidebar from './sidebars/DefaultSidebar'
import CaseViewSidebar from './sidebars/CaseViewSidebar'
import ProfileSidebar from './sidebars/ProfileSidebar'

// Subcontent
import CreateNewCase from './create-new-case'

// Case View (proactive refactoring)
import CaseView from './case-view'

// Profile (proactive refactoring)
import Profile from './profile'

// Images
import briefcaseLogoWhite from '../../assets/images/briefcase-logo-horizontal-white.png'

// Constants
import { SITE_TITLE_POSTFIX, Routes } from '../../constants'
import UserProfileAvatar from '../../components/UserProfileAvatar'
import SearchBar from '../../components/SearchBar'
import FiltersModal from '../../components/FiltersModal'

import { useAuthState } from '../../utils'

import { Filters } from '../../types'

// createStyles is for TS-compatibility
// https://material-ui.com/styles/api/#createstyles-styles-styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100vh',
      maxWidth: '100%',
      maxHeight: '100%',
      width: '100vw',
      padding: 0,
      margin: 0,
      overflowY: 'scroll',
      backgroundColor: theme.palette.common.white,
      gridTemplateColumns: '1fr 4fr',
      gridTemplateRows: '2fr 4fr',
      gridTemplateAreas: `
      "header header" 
      "sidebar content"
      `,
    },

    // All Grid Items
    gridItem: {
      backgroundColor: theme.palette.common.white,
      // padding: spacing(1),
    },

    // Each Grid Item below
    sliver: {
      gridArea: 'sliver',
      backgroundColor: '#0B2547', // darker blue
    },

    headerLeft: {
      gridArea: 'headerLeft',
      backgroundColor: '#263F67', // dark blue
      display: 'flex',
      paddingBottom: theme.spacing(0.5),
    },

    headerRight: {
      gridArea: 'headerRight',
      backgroundColor: '#263F67', // dark blue
      display: 'flex',
      paddingBottom: theme.spacing(0.5),
    },

    // sidebar: {
    //   gridArea: 'sidebar',
    //   // padding: 0,
    //   // minWidth: 240,
    //   // maxWidth: 250,
    //   display: 'flex',
    //   width: '20vw',
    //   maxWidth: '100%',
    // },

    // content: {
    //   //gridArea: 'content',
    //   //marginTop: theme.spacing(10.75),
    //   //marginBottom: theme.spacing(10.75),
    //   //marginLeft: theme.spacing(11.75),
    //   //marginRight: theme.spacing(11.75),
    //   //backgroundColor: '#F8F8F8',
    //   marginTop: theme.spacing(6),
    //   gridArea: 'content',
    //   display: 'flex',
    //   width: '80vw',
    //   maxWidth: '100%',
    //   marginLeft: theme.spacing(1),
    // },

    // Header Contents
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 160,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(20),
    },

    logo: {
      width: '100%',
      height: 'auto',
    },

    // Header Buttons
    headerButtonsContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: theme.spacing(1),
    },

    searchContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    filterContainer: {
      zIndex: 1000,
      position: 'absolute',
      top: '60px',
      minWidth: '800px',
      maxWidth: '1000px',
    },

    // TODO: the length isn't flexing
    header: {
      gridArea: 'header',
      display: 'flex',
      flex: 1,
      width: '100vw',
      maxWidth: '100%',
      height: '7vh',
      maxHeight: '100%',
      position: 'absolute',
      backgroundColor: '#2C3F67',
      zIndex: 2,
    },

    sideBar: {
      gridArea: 'sidebar',
      display: 'flex',
      width: '20vw',
      maxWidth: '100%',
    },

    button: {
      padding: theme.spacing(2),
    },

    content: {
      marginTop: theme.spacing(6),
      gridArea: 'content',
      display: 'flex',
      width: '80vw',
      maxWidth: '100%',
      marginLeft: theme.spacing(1),
      //position: 'relative',
    },

    content2: {
      marginTop: theme.spacing(4),
      gridArea: 'content',
      display: 'flex',
      width: '80vw',
      maxWidth: '100%',
      marginLeft: theme.spacing(1),
    },

    root2: {
      display: 'flex',
      height: '100vh',
      width: '100vw',
      maxWidth: '100%',
      //overflowY: 'scroll',
      flexDirection: 'column',
      // backgroundColor: '#F8F8F8',
    },

    headerText: {
      fontWeight: 700,
      fontSize: '1.3rem',
      alignSelf: 'left',
    },

    buttonContainer: {
      padding: theme.spacing(2),
    },

    tags: {},
    title: {
      display: 'flex',
      padding: theme.spacing(1),
    },

    contentTitleDivider: {
      //marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      //marginTop: theme.spacing(1),
      width: '95%',
    },
  })

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    path: string
  }>

const Dashboard: React.FC<Props> = (props: Props) => {
  const { classes, match } = props

  // Using dynamic components to fill in the Dashboard
  const [Content, setContent] = useState<React.ComponentType<ContentProps & any>>(CaseCardList)

  // this time here is kinda long lol but basically what it is doing is create a react ComponentType that takes in
  // props that are the typeof DefaultSideBar's Props but with the types of the classes that should be injected by
  // MUI taken out
  // TODO might need to do something like this at some point
  //  React.ComponentType<Omit<DefaultSideBarProps | CaseViewSidebarProps | ProfileSidebarProps, 'classes'>>
  const [LeftSidebar, setLeftSidebar] =
    useState<React.ComponentType<{ path: string }>>(DefaultSidebar)
  const [buttonTo, setButtonTo] = useState(Routes.Create)

  const [openModal, setOpenModal] = useState(false)

  // First load effect
  useEffect(() => {
    console.log('DASHBOARD > match.path: ' + match.path)
    switch (match.path) {
      case Routes.Create:
        setContent(CreateNewCase)
        break
      case Routes.Case:
        setContent(CaseView)
        setLeftSidebar(CaseViewSidebar)
        break
      case Routes.Profile:
        setContent(Profile)
        setLeftSidebar(ProfileSidebar)
        break
      default:
        setContent(CaseCardList)
        setLeftSidebar(DefaultSidebar)
    }
  }, [match])

  const { user } = useAuthState()

  type ContentProps = {
    filters?: Filters
  }

  const [searchTerm, setSearchTerm] = useState('')
  const [filters, setFilters] = useState<Filters>({
    query: '',
  })

  useEffect(() => {
    const handleGlobalKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        setFilters({ ...filters, query: searchTerm })
        setSearchTerm('')
      }
    }
    document.addEventListener('keydown', handleGlobalKeyPress)

    return () => {
      document.removeEventListener('keydown', handleGlobalKeyPress)
    }
  }, [searchTerm])

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Dashboard {SITE_TITLE_POSTFIX}</title>
      </Helmet>

      {/* Header with logo and profile*/}
      {/* TODO: add search bar header from Evan */}
      <div className={classes.header}>
        <div className={classes.logoContainer}>
          <Button component={Link} to={Routes.Dashboard}>
            <img className={classes.logo} src={briefcaseLogoWhite} alt="logo" />
          </Button>
        </div>
        <div className={classes.headerButtonsContainer}>
          <div className={classes.searchContainer}>
            <SearchBar
              openModal={openModal}
              setOpenModal={setOpenModal}
              searchTerm={filters.query}
              setSearchTerm={setSearchTerm}
            />
            <div className={classes.filterContainer}>
              {openModal && (
                <FiltersModal
                  searchTerm={searchTerm}
                  openModal={openModal}
                  closeModal={setOpenModal}
                  prevFilters={filters}
                  updateFilters={setFilters}
                />
              )}
            </div>
          </div>
          <UserProfileAvatar />
        </div>
      </div>
      {/* SIDEBAR */}
      <div className={clsx(classes.gridItem, classes.sideBar)}>
        <LeftSidebar {...props.match} />
      </div>
      {/* DISPLAY RECENT CASES & MY CASES */}
      <div className={classes.content}>
        <div className={classes.root2}>
          {/* <div className={classes.title}>
            <Typography className={classes.headerText}>My Cases</Typography>
          </div>
          <Divider className={classes.contentTitleDivider} orientation="horizontal" /> */}
          <div className={clsx(classes.gridItem, classes.content2)}>
            <Content filters={filters} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(Dashboard)
