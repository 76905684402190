import React from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import WhiteTextTypography from './WhiteTextTypography'
import { Typography } from '@material-ui/core'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#2c3f67',
      minHeight: 80,
      height: 80,
      width: '100%',
    },
  })

type Props = WithStyles<typeof styles> & {
  title: string
}

const TitleHeader = ({ classes, title }: Props) => (
  <div className={classes.root}>
    <WhiteTextTypography variant="h5" align="center">
      {title}
    </WhiteTextTypography>
    {title.match(/Character Profiles/g)?.[0] && (
      <Typography style={{ color: '#19e253' }} variant={'h6'}>
        (Coming Soon!)
      </Typography>
    )}
  </div>
)

export default withStyles(styles)(TitleHeader)
