import React from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import Heading from './Heading'
import SpeakerDialogue from './SpeakerDialogue'
import Description from './Description'
import { LineData } from '../../../types'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import DocumentDisplay from './DocumentDisplay'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
    },

    lineNumberBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 16,
      marginLeft: 8,
      width: 20,
      color: 'rgba(0, 0, 0, 0.5)',
    },

    rowText: {
      // applies below font styles to all text within a Typography component
      // that is child of RowContent
      '& p.MuiTypography-root': {
        fontFamily: 'Courier',
        fontSize: '1.06rem',
      },
    },
  })

type Props = WithStyles<typeof styles> & {
  quote: { text: string; speaker: string }
  line: LineData
}

const RowContent = ({ classes, quote, line }: Props) => {
  return (
    <div className={clsx(classes.root, classes.rowText)}>
      <Typography className={classes.lineNumberBox}>{line.line_number}</Typography>
      <DocumentDisplay quote={quote} line={line} />
    </div>
  )
}

export default withStyles(styles)(RowContent)
