import React, { useRef, useState } from 'react'

import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Box, ButtonBase, IconButton, Paper, Tooltip, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import GetAppIcon from '@material-ui/icons/GetApp'
import DoneIcon from '@material-ui/icons/Done'

import { areFilesValid, useAlert, useAuthState } from '../../../utils'
import { acceptedExhibitFileTypes, badExhibitFilesAlertMessage } from '../../../constants'
import { postNewExhibit, postExhibitMediaFiles, postExhibitMention } from '../../../api'
import RoundedInputField from '../../../components/RoundedInputField'
import { ExhibitData, RowRefsByLineId } from '../../../types'
import { updateNotes } from '../../../services/firebase/dal/profiles-notes'
import { AddNewExhibitTestIds } from '../../../testing/test-ids/document'

const postExhibitDetailsAndFiles = async (
  exhibitName: string,
  firmId: string,
  caseId: string,
  files: File[],
  lineId: string
) => {
  try {
    const exhibit = await postNewExhibit(exhibitName, firmId, caseId)

    if (files.length) {
      // files should be already validated here
      const form = new FormData()
      form.append('exhibit_id', exhibit.id)
      files.forEach((file) => form.append('file', file, file.name))
      await postExhibitMediaFiles(form)
    }

    if (lineId) {
      await postExhibitMention(exhibit.id, lineId)
    }

    return exhibit.id
  } catch (e) {
    console.error(e.message)
    return undefined
  }
}

const styles = () =>
  createStyles({
    root: {},

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 10,
      paddingRight: 10,
      marginBottom: 5,
    },

    // hacky way to get text centered perfectly and close button
    // all the way to the right, feel free to improve later
    invisibleFlexItem: {
      // width of close button
      width: 24,
    },

    closeButton: {
      width: 24,
    },

    inputFormsContainer: {
      paddingLeft: 30,
      paddingRight: 30,
      marginBottom: 8,
    },

    nameInputField: {
      marginBottom: 8,
    },

    notesInputField: {
      marginBottom: 16,
    },

    fileUploadBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: '2px solid #828282',
      height: 100,
      width: '100%',
      borderRadius: 6,
    },

    // Icon width and height must be same value
    // https://stackoverflow.com/a/38840120
    dropAreaIcon: {
      width: 50,
      height: 50,
      color: 'gray',
      marginBottom: 8,
    },

    dropAreaTitle: {
      fontSize: 16,
    },

    submitArea: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: 10,
      marginBottom: 10,
    },

    submitButton: {
      backgroundColor: '#53B89E',
      height: 30,
      width: 30,
    },
  })

type Props = WithStyles<typeof styles> & {
  lineId: string
  caseId: string
  setShouldShowAddExhibitDialog: React.Dispatch<React.SetStateAction<boolean>>
  setSidebarExhibits: React.Dispatch<React.SetStateAction<ExhibitData[]>>
}

const AddNewExhibit = ({
  classes,
  lineId,
  caseId,
  setShouldShowAddExhibitDialog,
  setSidebarExhibits,
}: Props) => {
  const { addAlert } = useAlert()!
  const { user } = useAuthState()
  const [exhibitName, setExhibitName] = useState('')
  const [exhibitNotes, setExhibitNotes] = useState('')
  const [files, setFiles] = useState<File[]>([])

  const fileInputRef = useRef<HTMLInputElement>(null)

  const validateAndSetFiles = (newFiles: File[]) => {
    if (areFilesValid(newFiles, acceptedExhibitFileTypes)) {
      setFiles(newFiles)
    } else {
      addAlert(badExhibitFilesAlertMessage)
    }
  }

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const fileList = event.currentTarget.files
    if (fileList) {
      validateAndSetFiles([...fileList])
      // must set the input to falsy each time so user has "clean slate" each time they open
      // file selection window
      event.currentTarget.value = ''
    }
  }

  const handleFileDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
    const fileList = event.dataTransfer.files
    validateAndSetFiles([...fileList])
  }

  const handleExhibitNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setExhibitName(event.target.value)

  const handleExhibitNotesChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setExhibitNotes(event.target.value)

  const handleCloseClick = () => {
    setShouldShowAddExhibitDialog(false)
  }

  const handleExhibitSubmit = async () => {
    if (exhibitName) {
      const exhibitId = await postExhibitDetailsAndFiles(
        exhibitName,
        user.firmId,
        caseId,
        files,
        lineId
      )

      if (exhibitId) {
        if (exhibitNotes) updateNotes(exhibitId, exhibitNotes)

        setSidebarExhibits((prevExhibits) => [
          ...prevExhibits,
          {
            case_id: parseInt(caseId),
            firm_id: parseInt(user.firmId),
            id: exhibitId,
            title: exhibitName,
          },
        ])

        setShouldShowAddExhibitDialog(false)

        addAlert('Exhibit successfully created!')
      } else {
        addAlert('Exhibit creation failed')
      }
    }
  }

  return (
    <div className={classes.root} data-testid={AddNewExhibitTestIds.root}>
      <div className={classes.header}>
        <div className={classes.invisibleFlexItem} />
        <Box fontWeight="fontWeightBold" fontSize={16}>
          Add an Exhibit
        </Box>
        <IconButton
          className={classes.closeButton}
          onClick={handleCloseClick}
          data-testid={AddNewExhibitTestIds.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form onSubmit={handleExhibitSubmit}>
        <div className={classes.inputFormsContainer}>
          <RoundedInputField
            classes={{ root: classes.nameInputField }}
            inputValue={exhibitName}
            handleInputChange={handleExhibitNameChange}
            placeholder="Add Exhibit Name"
            data-testid={AddNewExhibitTestIds.nameForm}
          />
          <RoundedInputField
            classes={{ root: classes.notesInputField }}
            inputValue={exhibitNotes}
            handleInputChange={handleExhibitNotesChange}
            placeholder="Add Exhibit Notes"
            data-testid={AddNewExhibitTestIds.notesForm}
          />
          <input
            type="file"
            onChange={handleFileSelection}
            ref={fileInputRef}
            multiple
            hidden
            data-testid={AddNewExhibitTestIds.fileInput}
          />
          <label htmlFor="file-input" onDrop={handleFileDrop}>
            <ButtonBase
              className={classes.fileUploadBox}
              onClick={() => fileInputRef.current?.click()}
              data-testid={AddNewExhibitTestIds.fileSelectButton}
            >
              <GetAppIcon className={classes.dropAreaIcon} />
              <Typography className={classes.dropAreaTitle}>
                Drag File or Click to Upload
              </Typography>
            </ButtonBase>
          </label>
        </div>

        <div className={classes.submitArea}>
          <Tooltip title="Add New Exhibit">
            <span>
              <IconButton
                className={classes.submitButton}
                disabled={!exhibitName}
                onClick={handleExhibitSubmit}
                data-testid={AddNewExhibitTestIds.submitButton}
              >
                <DoneIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </form>
    </div>
  )
}

export default withStyles(styles)(AddNewExhibit)
