import React, { FC } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles'

import { Routes } from '../constants'
import { useAuthState } from '../utils'

const styles = () =>
  createStyles({
    loadingSpinner: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

type Props = WithStyles<typeof styles> &
  Pick<React.ComponentProps<typeof Route>, 'path' | 'component' | 'exact'>

export const ProtectedRoute: FC<Props> = ({ classes, component, path, exact }: Props) => {
  const { isAuthenticated, isLoading } = useAuthState()

  return (
    // need two routes unfortunately, 1st one determines if we should call the func
    // given to render prop, second properly renders the component as a Route (such
    // that nested switches, etc can be used)
    <Route
      exact={exact}
      path={path}
      render={(location) =>
        isAuthenticated ? (
          <Route component={component} path={path} />
        ) : isLoading ? (
          <div className={classes.loadingSpinner}>
            <CircularProgress />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: Routes.Login,
              state: { from: location.location },
            }}
          />
        )
      }
    />
  )
}

export default withStyles(styles)(ProtectedRoute)
