import React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'

import { Avatar, Paper } from '@material-ui/core'

import briefcaseTrans from '../../../../assets/images/briefcase-trans.png'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 165,
      height: 165,
    },
  })

type Props = WithStyles<typeof styles> & {}

const SidebarAvatar: React.FC<Props> = (props: Props) => {
  const { classes } = props

  return (
    <Avatar
      className={classes.root}
      component={Paper}
      elevation={5}
      alt="Briefcase Logo"
      src={briefcaseTrans}
      // TODO: this shrinks a cropped version -- must shrink/crop in photo editor first
      imgProps={
        {
          // style: {
          //   height: 100,
          //   width: 100,
          // },
        }
      }
    />
  )
}

export default withStyles(styles)(SidebarAvatar)
