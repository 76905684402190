import React, { useEffect, useRef, useState } from 'react'
import { setInterval } from 'timers'
import { getJobStatus } from '../api'
import { CHECK_JOB_STATUS_INTERVAL_MS } from '../constants'

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

export function useIsMounted() {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return isMounted
}

export const useInViewport = (ref: React.RefObject<Element>) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

  useEffect(() => {
    if (ref.current) observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return isIntersecting
}

export const useJobStatus = (jobId: string) => {
  const [isJobDone, setIsJobDone] = useState<boolean | null>(null)
  const fetchJobStatus = async (jobId: string) => {
    return await getJobStatus(jobId).catch((error) => {
      console.error(error.message)
      return undefined
    })
  }
  const intervalCallback = async () => {
    console.log('checking job status')
    // job data is only stored for 500s after job is completed
    // check if job status is still stored in redis, if not, mark job as done
    const jobData = await fetchJobStatus(jobId)
    if (jobData) {
      setIsJobDone(jobData.job_status === 'finished')
    } else {
      setIsJobDone(true)
    }
  }

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (jobId) {
      intervalCallback()
      interval = setInterval(intervalCallback, CHECK_JOB_STATUS_INTERVAL_MS)
    } else {
      setIsJobDone(true)
    }

    return () => {
      clearInterval(interval)
    }
  }, [jobId])

  return isJobDone
}
