import { HttpResponse, SuccessResponse, SuggestionsByLineId, TagSuggestionData } from '../types'
import { fetcher, httpDelete } from './httpHelpers'
import useSWR, { mutate } from 'swr'

export const useSuggestionsByLineId = (documentId: string | undefined) => {
  const { data, error } = useSWR<HttpResponse<SuggestionsByLineId>>(
    () => (documentId ? `suggestions/${documentId}` : null),
    fetcher
  )

  return {
    suggestionsByLineId: data?.parsedBody?.['suggestions'],
    error,
    isLoading: !data && !error,
  }
}

export const useSuggestionsList = (documentId: string | undefined) => {
  const { data, error } = useSWR<HttpResponse<TagSuggestionData[]>>(
    () => (documentId ? `suggestions/${documentId}/list` : null),
    fetcher
  )

  return {
    suggestions: data?.parsedBody?.['suggestions'],
    error,
    isLoading: !data && !error,
  }
}

export const deleteSuggestionLineId = (tag: string, documentId: string, lineId: string) =>
  httpDelete<SuccessResponse>(`suggestions/suggestion/lines/${lineId}`, {
    tag,
    document_id: documentId,
  }).then((response) => response.parsedBody!['success'])

export const removeSuggestedLineId = async (
  tag: string,
  documentId: string,
  lineId: string,
  addAlert: (message: string) => void
) => {
  try {
    await deleteSuggestionLineId(tag, documentId, lineId)

    // trigger revalidation of both document suggestions and sidebar suggestions aggregation
    mutate(`suggestions/${documentId}`)
    mutate(`suggestions/${documentId}/list`)
  } catch (e) {
    console.error(e.message)
    addAlert('Sorry, there was an error')
  }
}
