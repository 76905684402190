import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { Avatar, Button, Divider, Paper, Typography } from '@material-ui/core'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import briefcaseTrans from '../../../assets/images/briefcase-trans.png'

// Constants
import { SITE_TITLE_POSTFIX } from '../../../constants'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },

    sectionHeader: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },

    subsectionHeader: {
      marginTop: theme.spacing(4.5),
    },

    contentTitleDivider: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
    },

    teamMembers: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      columnGap: '50px',
      rowGap: '50px',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(9),
    },

    memberItem: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
    },

    logoAvatar: {
      width: 118,
      height: 118,
      marginBottom: theme.spacing(1.5),
    },

    personAddIcon: {
      height: 100,
      width: 100,
      color: '#53B89E', // aqua green
      marginTop: theme.spacing(2),
    },

    inviteMemberButton: {
      color: theme.palette.common.white,
      backgroundColor: '#50718F',
      whiteSpace: 'nowrap',
    },

    // Personal Info
    personalInfoArea: {},

    table: {},

    tableCell: {
      borderBottom: 'none',
    },

    fieldName: {
      fontSize: 20,
      fontWeight: 'bold',
    },
  })

type Props = WithStyles<typeof styles> & {}

const Profile: React.FC<Props> = (props: Props) => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Profile {SITE_TITLE_POSTFIX}</title>
      </Helmet>
      <div className={classes.sectionHeader}>
        <Typography variant="h5">My Team</Typography>
      </div>
      <Divider className={classes.contentTitleDivider} orientation="horizontal" />

      <div className={classes.teamMembers}>
        <div className={classes.memberItem}>
          <Avatar
            className={classes.logoAvatar}
            component={Paper}
            elevation={1}
            alt="Briefcase Logo"
            src={briefcaseTrans}
          />
          <Typography>Team Member</Typography>
        </div>
        <div className={classes.memberItem}>
          <Avatar
            className={classes.logoAvatar}
            component={Paper}
            elevation={1}
            alt="Briefcase Logo"
            src={briefcaseTrans}
          />
          <Typography>Team Member</Typography>
        </div>
        <div className={classes.memberItem}>
          <Avatar
            className={classes.logoAvatar}
            component={Paper}
            elevation={1}
            alt="Briefcase Logo"
            src={briefcaseTrans}
          />
          <Typography>Team Member</Typography>
        </div>
        <div className={classes.memberItem}>
          <PersonAddIcon className={classes.personAddIcon} />
          <Button
            className={classes.inviteMemberButton}
            variant="contained"
            component={Link}
            to="/invite-new-member"
            disabled
          >
            Invite New Member
          </Button>
        </div>
      </div>

      <div className={classes.sectionHeader}>
        <Typography variant="h5">Personal Information</Typography>
      </div>
      <Divider className={classes.contentTitleDivider} orientation="horizontal" />
      <div className={classes.personalInfoArea}>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    display: 'flex',
                  }}
                >
                  <Typography className={classes.fieldName}>Name</Typography>
                  &nbsp;
                  <EditIcon />
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography className={classes.fieldName}>Email</Typography>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography className={classes.fieldName}>Company I.D.</Typography>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default withStyles(styles)(Profile)
