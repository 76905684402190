import React, { FC } from 'react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import TitleHeader from '../TitleHeader'
import SearchAndFilterBy from '../SearchAndFilterBy'
import CharacterProfileCard from './CharacterProfileCard'

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 310,
      minWidth: 310,
    },

    heading: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%,',
    },

    headingTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#2c3f67',
      minHeight: 80,
      height: 80,
      width: '100%',
    },

    witnessCardsContainer: {
      marginTop: 30,
    },
  })

type Props = WithStyles<typeof styles>

const CharacterProfile: FC<Props> = ({ classes }) => {
  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <TitleHeader title={'Character Profiles'} />
        <SearchAndFilterBy />
      </div>
      <div className={classes.witnessCardsContainer}>
        <CharacterProfileCard name="Character Name" title="Title" />
        <CharacterProfileCard name="Character Name" title="Title" />
        <CharacterProfileCard name="Character Name" title="Title" />
        <CharacterProfileCard name="Character Name" title="Title" />
        <CharacterProfileCard name="Character Name" title="Title" />
        <CharacterProfileCard name="Character Name" title="Title" />
        <CharacterProfileCard name="Character Name" title="Title" />
      </div>
    </div>
  )
}

export default withStyles(styles)(CharacterProfile)
