import React, { useState } from 'react'
import { postChatQuery } from 'api/chatService'

const DocumentChat: React.FC<{ caseId: string }> = ({ caseId }) => {
  const [questions, setQuestions] = useState<{ question: string; answer: string }[]>([])
  const [currentQuestion, setCurrentQuestion] = useState('')

  const handleQuestionChange = (e: any) => {
    setCurrentQuestion(e.target.value)
  }

  const handleAskQuestion = () => {
    if (currentQuestion.trim() !== '') {
      setQuestions((prevQuestions) => [
        ...prevQuestions,
        { question: currentQuestion, answer: '...' },
      ])
      setCurrentQuestion('')
      postChatQuery(caseId, currentQuestion).then((answer) => {
        console.log(answer)
        setQuestions((prevQuestions) => [
          ...prevQuestions.slice(0, prevQuestions.length - 1),
          { question: currentQuestion, answer },
        ])
      })
    }
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      handleAskQuestion()
    }
  }

  const styles = {
    container: {
      marginTop: 'auto',
      marginBottom: '20px',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '65vh',
    },
    questionCard: {
      border: '1px solid #ccc',
      padding: '10px',
      marginBottom: '10px',
    },
    inputBox: {
      width: '100%',
      padding: '10px',
      marginBottom: '10px',
    },
    button: {
      padding: '10px',
      backgroundColor: '#4CAF50',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  }

  return (
    <div>
      <div style={styles.container}>
        {questions.map((query, index) => (
          <div key={index} style={styles.questionCard}>
            <strong>Question:</strong> {query.question}
            <div>
              <strong>Answer:</strong> {query.answer}
            </div>
          </div>
        ))}
      </div>
      <div>
        <input
          type="text"
          placeholder="Enter a question..."
          value={currentQuestion}
          onChange={handleQuestionChange}
          onKeyPress={handleKeyPress}
          style={styles.inputBox}
        />
        <button style={styles.button} onClick={handleAskQuestion}>
          Ask Question
        </button>
      </div>
    </div>
  )
}

export default DocumentChat
