import { HttpResponse, UrlParams } from '../types'

const ContentTypeHeaderKey = 'Content-Type'

const isResponsePdf = (response: Response) =>
  response.headers.get(ContentTypeHeaderKey) === ContentType.PDF

export const ContentType = {
  JSON: 'application/json',
  PDF: 'application/pdf',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
}

// anything that is retrieving or storing files should probably be added here
// along with maybe any big elastic queries, ie the document
const urlRegexesForLongLivedRequests = [
  '/cases/document/case/',
  '/files/cases/case/',
  '/files/media/exhibit/',
  '/files/document/trial/',
]

const api = process.env.REACT_APP_API

const BASE_API_REQUEST_HEADER = {
  [ContentTypeHeaderKey]: ContentType.JSON,
}

export const appendParams = (baseUrl: string, params?: UrlParams) => {
  const url = new URL(baseUrl)
  if (params) Object.keys(params).forEach((key) => url.searchParams.append(key, `${params[key]}`))
  return url
}

const buildUrl = (resource: String, params?: UrlParams) => {
  const baseUrl = `${api}/${resource}`
  return appendParams(baseUrl, params)
}

async function http<T>(request: Request): Promise<HttpResponse<T>> {
  // const controller = new AbortController()
  // const isLongLivedRequest = urlRegexesForLongLivedRequests.some((url) => request.url.match(url))
  // const fetchTimeoutLength = isLongLivedRequest ? 120 * 1000 : 8000
  // const fetchTimeout = setTimeout(() => controller.abort(), fetchTimeoutLength)

  const response: HttpResponse<T> = await fetch(request)
  // clearTimeout(fetchTimeout)

  try {
    response.parsedBody = isResponsePdf(response)
      ? { pdf: response.arrayBuffer() }
      : await response.json()
  } catch (err) {
    throw new Error(err)
  }

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  if (response.parsedBody!.error) {
    throw new Error(response.parsedBody!.error)
  }

  return response
}

export async function post<T>(
  path: string,
  body?: any,
  options: RequestInit = {
    method: 'post',
    body: (body instanceof FormData ? body : JSON.stringify(body)) as string | FormData,
    headers: body instanceof FormData ? {} : { ...BASE_API_REQUEST_HEADER },
    credentials: 'include' as 'include',
  }
): Promise<HttpResponse<T>> {
  const url = buildUrl(path)
  return await http<T>(new Request(url.toString(), options))
}

export async function get<T>(
  path: string,
  params?: UrlParams,
  options: RequestInit = {
    method: 'get',
    headers: { ...BASE_API_REQUEST_HEADER },
    credentials: 'include' as 'include',
  }
): Promise<HttpResponse<T>> {
  const url = buildUrl(path, params)
  return await http<T>(new Request(url.toString(), options))
}

export async function put<T>(
  path: string,
  body?: any,
  options: RequestInit = {
    method: 'put',
    body: (body instanceof FormData ? body : JSON.stringify(body)) as string | FormData,
    headers: body instanceof FormData ? {} : { ...BASE_API_REQUEST_HEADER },
    credentials: 'include' as 'include',
  }
): Promise<HttpResponse<T>> {
  const url = buildUrl(path)
  return await http<T>(new Request(url.toString(), options))
}

export async function httpDelete<T>(
  path: string,
  body?: any,
  params?: UrlParams,
  options: RequestInit = {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { ...BASE_API_REQUEST_HEADER },
    credentials: 'include' as 'include',
  }
) {
  const url = buildUrl(path, params)
  return await http<T>(new Request(url.toString(), options))
}

export async function fetcher<T>(
  path: string,
  params?: UrlParams,
  options: RequestInit = {
    method: 'get',
    headers: { ...BASE_API_REQUEST_HEADER },
    credentials: 'include' as 'include',
  }
): Promise<HttpResponse<T>> {
  const url = buildUrl(path, params)
  return await http<T>(new Request(url.toString(), options)).then((response) => response)
}
