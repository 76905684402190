import { ExhibitData, GetParams, SuccessResponse } from '../types'
import { get, post } from './httpHelpers'

export const postNewExhibit = (title: string, firmId: string, caseId: string) =>
  post<ExhibitData>('exhibits/exhibit', {
    title,
    firm_id: firmId,
    case_id: parseInt(caseId),
  }).then((response) => response.parsedBody!['exhibit'])

export const postExhibitMediaFiles = (form: FormData) =>
  post<SuccessResponse>('files/media/exhibit', form).then(
    (response) => response.parsedBody!['success']
  )

export const getExhibitsByCaseId = (caseId: string, params: GetParams) =>
  get<ExhibitData[]>(`exhibits/list/${caseId}`, params).then(
    (response) => response.parsedBody!['exhibits']
  )

export const getExhibitMentions = (exhibitId: string) =>
  get<string[]>(`exhibits/exhibit/${exhibitId}/mentions`).then(
    (response) => response.parsedBody!['mentions']
  )

export const postExhibitMention = (exhibitId: string, mentionLineId: string) =>
  post<SuccessResponse>(`exhibits/exhibit/${exhibitId}/mentions`, { line_id: mentionLineId }).then(
    (response) => response.parsedBody!['success']
  )

export const getExhibitMedia = (exhibitId: string) =>
  get<string[]>(`files/media/exhibit/${exhibitId}/list`).then(
    (response) => response.parsedBody!['image_urls']
  )
