import React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },

    updatesTitleText: {
      fontFamily: 'Heebo',
      fontWeight: 800,
    },

    updateItems: {
      fontFamily: 'Heebo',
      marginTop: theme.spacing(1),
    },

    updateItemText: {
      fontFamily: 'Heebo',
    },

    updateList: {
      marginLeft: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  })

type Props = WithStyles<typeof styles> & {}

const SystemUpdates: React.FC<Props> = (props: Props) => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <Typography className={classes.updatesTitleText} variant="body1">
        System Updates
      </Typography>
      <div className={classes.updateItems}>
        <Typography variant="subtitle2">Workspace</Typography>
        <ul className={classes.updateList}>
          <li>Comments are now searchable!</li>
          <li>Exhibit profiles are live!.</li>
          <li>Line and page numbers are live as well!.</li>
        </ul>

        <Typography variant="subtitle2">Dashboard</Typography>
        <ul className={classes.updateList}>
          <li>Recent Cases section is now interactive and ordered by recency.</li>
          <li>All Cases section is now expandable.</li>
        </ul>
      </div>
    </div>
  )
}

export default withStyles(styles)(SystemUpdates)
