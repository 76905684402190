import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton, TextField, Button, Divider, Menu, MenuItem } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { Routes } from '../../constants'

import transcript from '../../assets/homepage/RoundedTranscript.png'
import workspace from '../../assets/homepage/RoundedWorkspace.png'
import HomepageBar from 'pages/homepage bar'
import { invalidEmailMessage, validateEmail } from '../onboarding'
import { postWaitlistMember } from '../../api'
import { useAlert } from '../../utils'
import { HeadsetMicRounded, Repeat } from '@material-ui/icons'
import LMULogo from '../../assets/homepage/LMUBackgroundLogo.png'
import LPILogo from '../../assets/homepage/LPI.png'

const addWaitlistMember = async (email: string, addAlert: (alert: string) => void) => {
  try {
    const success = await postWaitlistMember(email)
    addAlert('Your email was successfully added!')
    return success
  } catch (error: any) {
    console.error(error.message)
    addAlert('Sorry, something went wrong (you may already be on the waitlist)')
    return false
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: 'white',
    },

    splashButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      color: '#2C3F67',
      marginBottom: theme.spacing(4),
    },

    // Splash Buttons
    primaryButton: {
      color: 'white',
      backgroundColor: '#572994',
      width: 40,
      height: 40,
      '&:hover': {
        backgroundColor: 'white',
        color: '#572994',
        borderColor: '#572994',
        border: '1px solid',
      },
    },

    textButton: {
      backgroundColor: 'rgb(0,0,0,0)',
      // color: '#572994',
      color: 'white',
      border: '2px solid white',
      fontWeight: 'bold',
      fontSize: 14,
      transitionDuration: '0.4s',
      '&:hover': {
        backgroundColor: 'white',
        color: '#572994',
      },
    },

    waitListInput: {
      width: 300,
    },

    wrapper: {
      display: 'flex',
      midWidth: 1200,
      width: '100%',
      marginRight: 'auto',
      marginLeft: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
    },

    center: {
      position: 'relative',
      display: 'flex',
      maxWidth: 1030,
      marginTop: theme.spacing(4),
      marginRight: 'auto',
      marginLeft: 'auto',
      marginBottom: theme.spacing(4),
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },

    headline: {
      color: 'black',
      fontSize: '7vmin',
      fontWeight: 'bold',
    },

    headlineData: {
      maxWidth: 540,
      fontSize: '4vmin',
      marginBottom: theme.spacing(2),
      color: 'black',
    },

    enterEmail: {
      marginBottom: theme.spacing(2),
    },

    firstImage: {
      marginTop: theme.spacing(4),
      maxWidth: 1000,
      width: '100%',
      objectFit: 'cover',
      borderRadius: '25px',
      boxShadow: '5px 5px 5px 5px #808080',
    },

    introFirstImage: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      midWidth: 1700,
      width: '100%',
      objectFit: 'cover',
      borderRadius: '25px',
      boxShadow: '5px 5px 5px 5px #a1adab',
    },

    leftGrid: {
      display: 'grid',
      maxWidth: 1600,
      minWidth: 600,
      gridTemplateRows: 'auto',
      gridTemplateColumns: '0.75fr 1fr',
    },

    leftTextGrid: {
      maxWidth: 600,
      alignSelf: 'center',
      textAlign: 'left',
      color: 'black',
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(2),
    },

    leftImageGrid: {
      position: 'relative',
      maxWidth: 1000,
      display: 'flex',
      // width: '100%',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(4),
    },

    gridContainer: {
      midWidth: 1200,
      width: '100%',
      display: 'grid',
      gap: '2rem',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },

    bulletText: {
      marginTop: theme.spacing(2),
      fontSize: '1.8vmin',
      color: 'black',
    },

    // TODO: not responsive --> make responsive
    trialHeader: {
      marginBottom: theme.spacing(2),
      fontSize: '4.5vmin',
      lineHeight: '110%',
      fontWeight: 800,
      color: 'black',
    },

    category: {
      fontSize: '2vmin',
      color: 'black',
    },

    paragraphSub: {
      fontSize: '2vmin',
    },

    logoImage: {
      // marginTop: theme.spacing(4),
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      // maxWidth: 600,
      // maxWidth: '300',
      // height: '200px',
      // width: '100%',
      // maxHeight: '200px',
      // maxWidth: '200px',
      // maxWidth: '100%',
      // height: 'auto',
      // objectFit: 'contain',
      // borderRadius: '25px',
      // boxShadow: '5px 5px #808080, -1em 0 .4em #808080',
    },

    partnerDiv: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      flexGrow: 1,
      columnGap: '6rem',
      midWidth: 1200,
    },

    imageNavy: {
      width: '400px',
      height: '250px',
      objectFit: 'cover',
      borderRadius: '25px',
      boxShadow: '5px 5px 5px 5px #151f33',
    },
  })

type Props = WithStyles<typeof styles>

const Index = (props: Props) => {
  const { classes } = props
  const history = useHistory()
  const { addAlert } = useAlert()!

  const [menuOpen, setMenuOpen] = useState(null)
  const [waitlistEmail, setWaitlistEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setIsEmailValid(validateEmail(event.target.value))
    setWaitlistEmail(event.target.value)
  }

  const handleEmailSubmit = async () => {
    const success = await addWaitlistMember(waitlistEmail, addAlert)
    if (success) {
      setWaitlistEmail('')
    }
  }

  const handleClick = (event: any) => {
    setMenuOpen(event.currentTarget)
  }

  const handleClose = () => {
    setMenuOpen(null)
  }
  // Bonus TODO: add demo and pricing button
  // BONUS TODO: animate when the site is loaded
  // BONUS TODO: add CSS to change the color of the header bar once we start scrolling
  return (
    <div style={{ color: 'white' }}>
      <HomepageBar />
      <div className={classes.root}>
        {/* Splash section 1: Briefcase catchphrase and pic of briefcase dashboard */}
        <div className={classes.wrapper} style={{ backgroundColor: '#D5E6E3' }}>
          <div className={classes.center}>
            <h1 className={classes.headline}>
              Close the Case with the Power of Artificial Intelligence
            </h1>
            <div className={classes.headlineData}>
              Briefcase provides attorneys with AI driven insights and document navigation in a
              collaborative environment.
            </div>
            <div className={classes.enterEmail}>
              <TextField
                className={classes.waitListInput}
                variant="outlined"
                label="Enter your email to join the waitlist"
                size="small"
                value={waitlistEmail}
                onChange={handleEmailChange}
                error={waitlistEmail.length > 0 && !isEmailValid}
                helperText={!isEmailValid && waitlistEmail.length > 0 ? invalidEmailMessage : null}
              />
              <IconButton
                className={classes.primaryButton}
                disabled={!isEmailValid}
                onClick={handleEmailSubmit}
              >
                <CheckIcon />
              </IconButton>
            </div>
            {/* TODO: Make responsive */}
            {/*  */}
            <img
              className={classes.introFirstImage}
              src={workspace}
              alt="commenting on trial transcript"
            />
          </div>
        </div>
        {/* Splash Section 2: How does it work w/link to Features page */}
        {/* TODO: Make this responsive so when the screen becomes 480 or less 
                  the writing will end up on top and the picture right below*/}
        <div className={classes.wrapper} style={{ backgroundColor: '#2C3F67' }}>
          <div className={classes.center}>
            <h2 className={classes.trialHeader} style={{ color: 'white' }}>
              How does it work?
            </h2>
            <p className={classes.bulletText} style={{ color: 'white' }}>
              Briefcase applies natural language processing to extract meaningful insights from
              court transcripts which improves both team efficiency and cases outcomes.
            </p>
            <div className={classes.splashButtons}>
              <Button className={classes.textButton} onClick={() => history.push(Routes.Features)}>
                Learn more about Briefcase's features
              </Button>
            </div>
          </div>
        </div>
        {/* Splash Section 3: Immersive trial, how to use Briefcase */}
        <div className={classes.wrapper} style={{ backgroundColor: '#d9d9d9' }}>
          <div className={classes.gridContainer}>
            <div className={classes.leftGrid}>
              <div className={classes.leftTextGrid}>
                <h4 className={classes.category}>Join Briefcase</h4>
                <h2 className={classes.trialHeader}>
                  Immersive Trial Documents at your Fingertips
                </h2>
                <p className={classes.paragraphSub}>
                  With the ability to link exhibit and witness profiles within the document,
                  transcripts become a multimedia experience.
                </p>
                <div className={classes.bulletText}>
                  <span>&#10003;</span>
                  <strong> Get Briefcase:</strong> Join the waitlist and one of our team members
                  should reach out
                </div>
                <div className={classes.bulletText}>
                  <span>&#10003;</span>
                  <strong> Upload your trial transcript:</strong> Once on the Briefcase dashboard,
                  create a new case and upload your transcript as a PDF
                </div>
                <div className={classes.bulletText}>
                  <span>&#10003;</span>
                  <strong> Use Briefcase's smart features:</strong> Utilize Briefcase’s workspace to
                  better analyze, navigate, and collaborate on court transcripts
                </div>
              </div>
              <div className={classes.leftImageGrid}>
                <img className={classes.firstImage} src={transcript} alt="trial transcript" />
              </div>
            </div>
          </div>
        </div>
        {/* Splash Section 4: Created in partnership with*/}
        <div className={classes.wrapper} style={{ backgroundColor: '#2C3F67', display: 'grid' }}>
          <div>
            <h2 className={classes.trialHeader} style={{ color: 'white' }}>
              Created in Partnership with
            </h2>
          </div>
          <div className={classes.partnerDiv}>
            <div className={classes.logoImage}>
              <a href="https://www.lmu.edu">
                <img src={LMULogo} className={classes.imageNavy} alt="LMU Logo" />
              </a>
            </div>
            <div className={classes.logoImage}>
              <a href="https://www.lls.edu/academics/experientiallearning/clinics/projectfortheinnocent/">
                <img src={LPILogo} className={classes.imageNavy} alt="LPI Logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(Index)
