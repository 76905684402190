import { database } from '../index'
import { FirebaseValues } from '../../../types'

export function getRef(path: string) {
  return database.ref(path)
}

export function push(path: string, value?: FirebaseValues) {
  return getRef(path).push(value)
}

export function set(path: string, value: FirebaseValues) {
  return getRef(path).set(value)
}

export function update(updates: Record<string, any>, onComplete?: (a: Error | null) => any) {
  return database.ref().update(updates, onComplete)
}

export function removeNode(path: string) {
  const ref = getRef(path)
  ref.off()
  ref.remove()
}
