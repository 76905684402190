import React, { FC } from 'react'
import { createStyles, WithStyles } from '@material-ui/core/styles'
import { Grid, Typography, withStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    root: {
      padding: '2px 16px',
    },
  })

type Props = WithStyles<typeof styles> & {
  text: string
}

const Description: FC<Props> = ({ classes, text }) => {
  return (
    <Grid item xs={12} className={classes.root}>
      <Typography align="left">{text}</Typography>
    </Grid>
  )
}

export default withStyles(styles)(Description)
