import React, { memo } from 'react'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Box, Grid, Switch, Tab, Tabs, Toolbar, Typography, TextField } from '@material-ui/core'

import caseIconGrey from '../../../assets/images/workspace-case-icon.png'
import { WorkspaceTab, DocumentMetadata, PagesMap } from '../../../types'
import { useDocument } from '../../../api/'
import { useCaseId, useScrollTo } from '../../../utils'
import { ToolbarTestIds } from '../../../testing/test-ids/toolbar'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
      backgroundColor: '#F2F2F2',
      color: 'black',
      height: 100,
      border: '1px solid grey',
      padding: 0,
    },

    toolsTabsContainer: {
      height: '100%',
      width: 300,
    },

    toolRow: {
      alignItems: 'center',
      height: '60%',
      paddingLeft: 16,
      paddingRight: 16,
      display: 'grid',
      gridTemplateColumns: '40px 120px 200px',
    },

    tabRow: {
      alignItems: 'center',
      height: '40%',
      borderTop: '1px solid grey',
    },

    pageNumberInput: {
      width: 30,
      margin: 5,
    },

    caseInfoContainer: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: '1px solid grey',
    },

    caseInfo: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },

    caseTitle: {
      fontWeight: 'bold',
      lineHeight: '12px',
    },

    pdfSwitchContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '100%',
    },

    tabsContainer: {
      height: 38,
      minHeight: 38,
    },
  })

type Props = WithStyles<typeof styles> & {
  caseTitle: string
  docketNumber: string
  shouldShowPdfs: boolean
  setShouldShowPdfs: React.Dispatch<React.SetStateAction<boolean>>
  handleTabChange: (newValue: number) => void
  openTabsValue: number
  openTabs: WorkspaceTab[]
}

const ToolBar = memo(
  ({
    classes,
    caseTitle,
    docketNumber,
    shouldShowPdfs,
    setShouldShowPdfs,
    handleTabChange,
    openTabsValue,
    openTabs,
  }: Props) => {
    const caseId = useCaseId()
    const { pages, documentMetadata } = useDocument(caseId)
    const { scrollToPage } = useScrollTo()
    const handlePdfSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setShouldShowPdfs(event.target.checked)
    }
    const handlePageScrolling = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (documentMetadata && event.key == 'Enter') {
        let pageNumber = parseInt((event.target as HTMLInputElement).value, 10)
        scrollToPage(pageNumber)
      }
    }
    const pageIds = documentMetadata?.page_ids
    const pageCount = pages && pageIds ? pages[pageIds[pageIds.length - 1]].page_number : 0

    return (
      <Toolbar className={classes.root} data-testid={ToolbarTestIds.Root}>
        <Grid item xs={4} md={3} xl={3} className={classes.caseInfoContainer}>
          <Box>
            <img src={caseIconGrey} alt="case icon" />
          </Box>
          <Grid item className={classes.caseInfo}>
            <Typography className={classes.caseTitle} data-testid={ToolbarTestIds.CaseTitle}>
              {caseTitle}
            </Typography>
            <Typography data-testid={ToolbarTestIds.DocketNumber}>
              {docketNumber ? `No. ${docketNumber}` : ''}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={8} md={9} xl={10} className={classes.toolsTabsContainer}>
          <Grid className={classes.toolRow}>
            <div>
              <TextField
                className={classes.pageNumberInput}
                defaultValue={1}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlePageScrolling(e)}
              />
            </div>
            <div>
              <Typography>of {pageCount} pages</Typography>
            </div>
            <div className={classes.pdfSwitchContainer}>
              <Typography>Hide PDF</Typography>
              <Grid item>
                <Switch
                  checked={shouldShowPdfs}
                  onChange={handlePdfSwitchChange}
                  data-testid={ToolbarTestIds.PdfSwitch}
                />
              </Grid>
              <Typography>Show PDF</Typography>
            </div>
          </Grid>
          <Grid item className={classes.tabRow}>
            <Tabs
              className={classes.tabsContainer}
              value={openTabsValue}
              onChange={(e, newValue) => handleTabChange(newValue)}
              variant="scrollable"
              scrollButtons="auto"
            >
              {openTabs.map(({ name, id }) => (
                <Tab label={name} key={id} data-testid={ToolbarTestIds.Tab} />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Toolbar>
    )
  }
)

export default withStyles(styles)(ToolBar)
