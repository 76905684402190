import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { IconButton, Tooltip } from '@material-ui/core'
import AddCommentIcon from '@material-ui/icons/AddComment'
import LabelIcon from '@material-ui/icons/Label'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'

import CommentDialog from './comments/CommentDialog'
import CommentThread from './comments/CommentThread'
import { ExhibitData, FirebaseThread } from '../../../types'
import TagDialog from './tagging/TagDialog'
import { addTagForRow } from '../../../services/firebase/dal'
import { useAlert, useTagging } from '../../../utils'
import ExhibitDialog from '../exhibits/ExhibitsDialog'
import SuggestionDialog from './suggestions/SuggestionDialog'
import { useSuggestionsByLineId } from '../../../api'

enum DialogType {
  Comment,
  Tag,
  Exhibit,
}

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },

    rowContainer: {
      background: 'white',
      boxShadow: '2px 10px 4px 0px #9aa2b3',
      padding: 5,
      width: '95%',
    },

    rowAnnotatingMode: {
      backgroundColor: palette.primary.light,
    },

    rowCommentSubmittedMode: {
      backgroundColor: palette.secondary.light,
    },

    iconButton: {
      marginTop: spacing(-1),
      alignSelf: 'start',
    },

    taggingAndCommentsContainer: {
      display: 'flex',
      position: 'absolute',
      marginLeft: -135,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    marginForDialogsWithButtons: {
      marginLeft: -465,
    },

    marginForDialogWithComment: {
      marginLeft: -720,
    },

    marginForDialogWithCommentAndButton: {
      marginLeft: -760,
    },

    marginForDialogWithButtons: {
      marginLeft: -450,
    },

    marginForDialogExhibitButton: {
      marginLeft: -430,
    },

    marginForDialogsOnly: {
      marginLeft: -360,
    },

    marginForTagButton: {
      marginLeft: -42,
    },

    marginForTagAndExhibitButton: {
      marginLeft: -92,
    },

    marginForDialogAndOneButton: {
      marginLeft: -405,
    },

    button: {
      zIndex: 10,
      marginRight: 10,
    },

    tagIndicatorIcon: {
      marginLeft: 10,
    },
  })

type Props = WithStyles<typeof styles> & {
  documentAreaRef: React.RefObject<HTMLDivElement>
  quoteId: string
  caseId: string
  lineId: string
  commentThread: FirebaseThread
  rowContent: JSX.Element
  rawLine: string
  setSidebarExhibits: React.Dispatch<React.SetStateAction<ExhibitData[]>>
  documentId: string | undefined
}

const Row = ({
  classes,
  documentAreaRef,
  quoteId,
  caseId,
  lineId,
  commentThread,
  rowContent,
  rawLine,
  setSidebarExhibits,
  documentId,
}: Props) => {
  const { taggedQuotes } = useTagging()
  const { addAlert } = useAlert()!
  const { suggestionsByLineId } = useSuggestionsByLineId(documentId)
  const [shouldShowCommentDialog, setShouldShowCommentDialog] = useState(false)
  const [shouldShowOpenDialogButtons, setShouldShowOpenDialogButtons] = useState(false)
  const [shouldShowTagDialog, setShouldShowTagDialog] = useState(false)
  const [shouldShowAddExhibitDialog, setShouldShowAddExhibitDialog] = useState(false)
  const [isRowTagged, setIsRowTagged] = useState(taggedQuotes[lineId] !== undefined)
  const [shouldShowSuggestionDialog, setShouldShowSuggestionDialog] = useState(
    suggestionsByLineId?.[lineId] !== undefined
  )
  const ref = useRef<HTMLDivElement>(null)

  const handleRowClick = () => {
    if (shouldShowSuggestionDialog) {
      addAlert('Please handle the Tag Suggestion dialog first')
    } else if (
      (!commentThread && !shouldShowCommentDialog) ||
      (!isRowTagged && !shouldShowTagDialog) ||
      !shouldShowAddExhibitDialog
    ) {
      setShouldShowOpenDialogButtons(!shouldShowOpenDialogButtons)
    }
  }

  const handleOpenDialogButtonClick = (newDialogType: DialogType) => {
    setShouldShowOpenDialogButtons(false)
    setShouldShowCommentDialog(newDialogType === DialogType.Comment)
    setShouldShowTagDialog(newDialogType === DialogType.Tag)
    setShouldShowAddExhibitDialog(newDialogType === DialogType.Exhibit)
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (!(ref as MutableRefObject<HTMLDivElement>).current.contains(event.target as Node)) {
      setShouldShowOpenDialogButtons(false)
      setShouldShowCommentDialog(false)
      setShouldShowTagDialog(false)
      setShouldShowAddExhibitDialog(false)
    }
  }

  const getRowStyleClassFromCommentState = (): string => {
    if (commentThread) {
      return clsx(classes.rowContainer, classes.rowCommentSubmittedMode)
    }

    return shouldShowOpenDialogButtons
      ? clsx(classes.rowContainer, classes.rowAnnotatingMode)
      : classes.rowContainer
  }

  // ugh this is among the grossest code/css I've had to write for briefcase but a refactor of the comments
  // architecture is not in the works yet so this will have to do until that happens...

  // this sets the negative margin of the container that holds all tag and comment dialogs, open buttons for
  // those dialogs and also saved comment threads. it sets the negative margin left based upon which components
  // are showing. sorry, it is really not that maintainable but there are other priorities atm. default margin
  // is for when it just has the open comment/tag dialog buttons showing
  const getStylesForTaggingAndCommentsContainer = () => {
    const styles = [classes.taggingAndCommentsContainer]

    // I wanna throw up
    if (
      commentThread &&
      shouldShowOpenDialogButtons &&
      (shouldShowTagDialog || shouldShowAddExhibitDialog)
    ) {
      styles.push(classes.marginForDialogWithCommentAndButton)
    } else if (
      (isRowTagged &&
        shouldShowOpenDialogButtons &&
        (shouldShowCommentDialog || shouldShowAddExhibitDialog)) ||
      (isRowTagged && commentThread && shouldShowOpenDialogButtons)
    ) {
      styles.push(classes.marginForDialogAndOneButton)
    } else if (
      shouldShowOpenDialogButtons &&
      (shouldShowTagDialog ||
        shouldShowAddExhibitDialog ||
        shouldShowCommentDialog ||
        commentThread)
    ) {
      styles.push(classes.marginForDialogWithButtons)
    } else if (
      commentThread &&
      (shouldShowTagDialog || shouldShowAddExhibitDialog || shouldShowSuggestionDialog)
    ) {
      styles.push(classes.marginForDialogWithComment)
    } else if (shouldShowOpenDialogButtons && isRowTagged && commentThread) {
      styles.push(classes.marginForDialogExhibitButton)
    } else if (shouldShowOpenDialogButtons && shouldShowAddExhibitDialog && isRowTagged) {
      styles.push(classes.marginForDialogAndOneButton)
    } else if (
      commentThread ||
      shouldShowTagDialog ||
      shouldShowCommentDialog ||
      shouldShowAddExhibitDialog ||
      shouldShowSuggestionDialog
    ) {
      styles.push(classes.marginForDialogsOnly)
    } else if (isRowTagged) {
      styles.push(classes.marginForTagAndExhibitButton)
    }

    return clsx(styles)
  }

  const addTagForCurrentRow = (tagLabel: string) => {
    setShouldShowTagDialog(false)
    if (!quoteId) {
      addAlert('Sorry this quote is not taggable')
    } else {
      addTagForRow(lineId, caseId, quoteId, tagLabel, rawLine)
    }
  }

  useEffect(() => {
    setIsRowTagged(taggedQuotes[lineId] !== undefined)
  }, [taggedQuotes[lineId]])

  useEffect(() => {
    setShouldShowSuggestionDialog(suggestionsByLineId?.[lineId] !== undefined)
  }, [suggestionsByLineId?.[lineId]])

  useEffect(() => {
    // TODO: I feel like this effect should be separated out a bit, but not sure so leaving for now

    // when using useRef(), need to save location of where we add event listeners so we
    // can delete them later
    const currRef = documentAreaRef.current
    if (
      shouldShowCommentDialog ||
      shouldShowOpenDialogButtons ||
      shouldShowTagDialog ||
      shouldShowAddExhibitDialog
    ) {
      currRef?.addEventListener('mousedown', handleOutsideClick)
    } else {
      currRef?.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      currRef?.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [
    shouldShowCommentDialog,
    shouldShowOpenDialogButtons,
    shouldShowTagDialog,
    shouldShowAddExhibitDialog,
    documentAreaRef.current,
    handleOutsideClick,
  ])

  return (
    <div className={classes.root} ref={ref}>
      <div className={getStylesForTaggingAndCommentsContainer()}>
        {shouldShowSuggestionDialog && (
          <SuggestionDialog
            setShouldShowSuggestionDialog={setShouldShowSuggestionDialog}
            suggestionTag={suggestionsByLineId?.[lineId]}
            addTagForCurrentRow={addTagForCurrentRow}
            lineId={lineId}
            documentId={documentId}
          />
        )}

        {commentThread && <CommentThread commentThread={commentThread} caseId={caseId} />}

        {shouldShowTagDialog && (
          <TagDialog
            setShouldShowTagDialog={setShouldShowTagDialog}
            addTagForCurrentRow={addTagForCurrentRow}
            caseId={caseId}
          />
        )}

        {shouldShowAddExhibitDialog && (
          <ExhibitDialog
            caseId={caseId}
            lineId={lineId}
            setShouldShowAddExhibitDialog={setShouldShowAddExhibitDialog}
            setSidebarExhibits={setSidebarExhibits}
          />
        )}

        {shouldShowCommentDialog && (
          <CommentDialog
            setShouldShowCommentDialog={setShouldShowCommentDialog}
            caseId={caseId}
            lineId={lineId}
          />
        )}

        {shouldShowOpenDialogButtons && !shouldShowAddExhibitDialog && (
          <IconButton
            onClick={() => handleOpenDialogButtonClick(DialogType.Exhibit)}
            color="primary"
            className={classes.button}
          >
            <AddPhotoAlternateIcon fontSize="large" />
          </IconButton>
        )}

        {shouldShowOpenDialogButtons && !isRowTagged && !shouldShowTagDialog && (
          <IconButton
            onClick={() => handleOpenDialogButtonClick(DialogType.Tag)}
            color="primary"
            className={classes.button}
          >
            <LabelIcon fontSize="large" />
          </IconButton>
        )}

        {shouldShowOpenDialogButtons && !commentThread && !shouldShowCommentDialog && (
          <IconButton
            onClick={() => handleOpenDialogButtonClick(DialogType.Comment)}
            color="primary"
            className={classes.button}
          >
            <AddCommentIcon fontSize="large" />
          </IconButton>
        )}
      </div>

      <div className={getRowStyleClassFromCommentState()} onClick={handleRowClick}>
        {rowContent}
      </div>

      {isRowTagged && (
        <Tooltip title={taggedQuotes[lineId].tagLabel} placement="right">
          <LocalOfferOutlinedIcon className={classes.tagIndicatorIcon} />
        </Tooltip>
      )}
    </div>
  )
}

export default withStyles(styles)(Row)
